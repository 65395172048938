import React, { useState, useEffect } from "react";
import "../Styles/donationForm.css";
import { useTranslation } from "react-i18next";
import Grace from ".././Images/Grace.png";

const DonationForm = () => {
  const storedUserName = localStorage.getItem("userName");
  const storedFormData = JSON.parse(localStorage.getItem("formData"));

  const [donorName, setDonorName] = useState(
    storedFormData?.donorName == null
      ? storedUserName
      : storedFormData?.donorName
  );
  const [recipientName, setRecipientName] = useState(
    storedFormData?.recipientName || ""
  );
  const [recipientPhone, setRecipientPhone] = useState(
    storedFormData?.recipientPhone || ""
  );
  console.log(storedFormData);
  const [phoneError, setPhoneError] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const formData = { donorName, recipientName, recipientPhone };
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [donorName, recipientName, recipientPhone]);

  const textDirectionClass =
    i18n.language === "ar" ? "text-right" : "text-left";

  const validateSaudiPhoneNumber = (phoneNumber) => {
    const saudiPhoneRegex =
      /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
    return saudiPhoneRegex.test(phoneNumber);
  };

  const labelAlignStyle = {
    textAlign: i18n.language === "ar" ? "right" : "left",
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setRecipientPhone(newPhone);

    if (validateSaudiPhoneNumber(newPhone) || newPhone === "") {
      setPhoneError("");
    } else {
      setPhoneError("يرجى إدخال رقم سعودي صحيح");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className={`donation-form-container ${textDirectionClass}`}>
            <form className="donation-form">
              <div className="input-group">
                <label htmlFor="donorName" style={labelAlignStyle}>
                  {t("home.donerName")}
                </label>
                <input
                  type="text"
                  id="donorName"
                  value={donorName}
                  onChange={(e) => setDonorName(e.target.value)}
                  required
                />
              </div>
              <div className="input-group">
                <label style={labelAlignStyle} htmlFor="recipientName">
                  {t("home.doner-name")} ({t("home.optional")})
                </label>
                <input
                  type="text"
                  id="recipientName"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label style={labelAlignStyle} htmlFor="recipientPhone">
                  {t("home.donor-phone")} ({t("home.optional")})
                </label>
                <input
                  type="text"
                  id="recipientPhone"
                  value={recipientPhone}
                  onChange={handlePhoneChange}
                />
                {phoneError && (
                  <span className="error-message">{phoneError}</span>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div
            style={{
              ...(i18n.language === "en" && { marginLeft: "105px" }),
              ...(i18n.language === "ar" && { marginRight: "105px" }),
            }}
            className={`text-container ${textDirectionClass}`}
          >
            <img
              width={550}
              src={Grace}
              alt="Image"
              className="responsive-image"
            />
            <p
              style={{
                fontFamily: "cairo",
                marginTop: "5vh",
                ...(i18n.language === "en" && { marginLeft: "8vw" }),
                ...(i18n.language === "ar" && { marginRight: "8vw" }),
              }}
            >
              {t("home.inCase")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
