
import "../Styles/UserProfile.css";
import { useTranslation } from 'react-i18next';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";



function AddressCard(props) {
  const { t, i18n } = useTranslation();

    return (
      
  
    <div className="col-sm-6">
      <div style={{height:"100%"}} className="p-3 upAdressCard">
          <div className="upAdressCard-div">
          <p className="upAdressCard-p1">{props.title}</p>
        
          
          </div>
   
          <p style={ props.apartmentNo? {marginBottom:"0"}:null}>{props.address}</p>
          {props.apartmentNo?
          <p >{t("address.apartmentNo")+":"+ " "+ props.apartmentNo+","+" "+t("address.buildingNo")+":"+ " "+ props.buildingNo+","+" "+t("address.streetNo")+":"+ " "+ props.streetNo+","+" "+t("address.compoundNo")+":"+ " "+ props.compoundNo}</p>:null}
          <div style={i18n.language=="ar"?{left:"0"}:{right:"0"}} className="upAdressCard-btns">
       <button onClick={()=>{
       props.setDelId(props.id)
       props.setDelModal(true)
       }
      }  className="upAdressCard-btn1">{t("profile.delete")}<span><RiDeleteBin5Line size={20}/></span></button> 
 

    </div>
          </div>
    </div>
   
 
    );
  }
  
  export default AddressCard;