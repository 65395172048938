 
import "../Styles/CompanySales.css";
import { useRef ,useState} from "react";
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import countryList from 'react-select-country-list';
import {awafi_register,points,awafi_code,awafi_verify} from "../api";
import { BsCalendar4Week } from "react-icons/bs";
import OtpInput from 'react-otp-input';
import { BeatLoader } from "react-spinners";
import countriesList from "./Countries";
function AwafiSignUp(props) {
 

    const { t, i18n } = useTranslation();
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [ selectedOption,setSelectedOption]=useState("male");
      //this component either displays sign-up form or otp form depending on displayVerify value
    const [ displayVerify,setDisplayVerify]=useState(false);
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    const [otp,setOtp]=useState(null);
    const [loading,setLoading]=useState(false);
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy =  parseInt(today.getFullYear())-16


    if (dd < 10) {
       dd = '0' + dd;
    }
    
    if (mm < 10) {
       mm = '0' + mm;
    } 
        
    today = yyyy + '-' + mm + '-' + dd;
   
    const arabCountry = [
       {
        value:"sa",
        label:countryList().getLabel("sa"),
    },
   {
      value:"bh",
      label:countryList().getLabel("bh"),
  },
 {
      value:"eg",
      label:countryList().getLabel("eg"),
  },
 {
      value:"ae",
      label:countryList().getLabel("ae"),
  },
 {
      value:"kw",
      label:countryList().getLabel("kw"),
  },
   {
      value:"qa",
      label:countryList().getLabel("qa"),
  },
 {
      value:"om",
      label:countryList().getLabel("om"),
  },
  {
      value:"sy",
      label:countryList().getLabel("sy"),
  },
  {
      value:"iq",
      label:countryList().getLabel("iq"),
  },
   {
      value:"ye",
      label:countryList().getLabel("ye"),
  }

]

let otherCountries = countryList().getData();
otherCountries.map((obj) => {
    return(
        arabCountry.push(obj)
    )
})

    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
    let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const Schema= yup.object().shape({
    phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,{message:("validation.phone_not_valid")}),
    name: yup.string()
    .required(("validation.name_required")),
    birthday: yup.string()
    .required(("validation.birthday_required")),
    nationality: yup.string()
    .required(("validation.nationality_required")),
    email: yup.string()
    .required(("validation.email_required")).matches(emailRegx,{message:("validation.invalid_email")}),
    terms: yup.boolean().oneOf([true], ("validation.terms_must_checked")),
      
    })
    
    
    const formik = useFormik({
    initialValues:{
      name: '',
     phone:  !localStorage.getItem("token")? '': localStorage.getItem("userPhone"),
    email: '',
    nationality: '',
    birthday: '',
    terms: false
    },
    validationSchema: Schema,
    onSubmit
    });
    const emailRef=useRef();
        const phoneRef=useRef();
        const nationalityRef=useRef();
        const birthdayRef=useRef();
    const nameRef=useRef();
    const termsRef=useRef();
      
 


//for setting otp value
    const handleChange = (otp) =>{ setOtp(otp);
     

   }  


   //on submitting signup form =>takes phone value => awafi_code api call sends verification code to user and otp form is displayed
    async function onSubmit(event){

 setLoading(true);
 const token= localStorage.getItem("token");

         
          let values = {
            phone: !localStorage.getItem("token")? phoneRef.current.value : localStorage.getItem("userPhone")
          };

          awafi_code( values).then((response) => {
            if(token){
              props.setPage(5)
              props.setLoyalty(true)
              setLoading(false)}
            else{
              setDisplayVerify(true)
              setLoading(false);
            }
          }).catch(function (error) {
            setMsgAr( error.response.data.error)
            setMsgEn( error.response.data.error)
                  setErrorMsgModel(true);
                  setLoading(false);
          })
      
  
        
        
        }

        //sets selceted gender value
        const handleOptionChange = (changeEvent) => {
          setSelectedOption( changeEvent.target.value);
        
          }


          //on submitting ot form => takes code,nationality,email,birthDate,gender,phone from previous sign-up form
          //on success register tab is  hidden
     const  handelVerificationSubmit = (e) => {
      setLoading(true);
            e.preventDefault();
    
             if (otp === null) {
               
                  setMsgAr("من فضلك ادخل كود التفعيل");
                 setMsgEn("Please enter verification code")
                    setErrorMsgModel(true)
                    setLoading(false);
            }
  
        else {
                
                      let values = {
              code: +otp,
              nationality:  formik.values.nationality,
              email:  formik.values.email,
              birthDate:  formik.values.birthday,
              gender: selectedOption,
              name: formik.values.name,
              phone: !localStorage.getItem("token")? formik.values.phone: localStorage.getItem("userPhone")
          };
      
                awafi_verify(values).then((response) => {
                  //  this.setState({
                  //      loading: false
                  //  })
                    if(response.data.message === "error"){
                    
                            setMsgAr(" كود التفعيل غير صالح ")
                            setMsgEn("Verification code not valid")
                            setErrorMsgModel(true)
                            setLoading(false);
                    }else{
                       // this.displaySuccess() 
                       props.setPage(5)
                      props.setLoyalty(true)
                      setLoading(false)
                    }
                }).catch(function (error) {
                  
                      setMsgAr(error.response.data.error)
                        setMsgEn(error.response.data.error)
                          setErrorMsgModel(true)
                          setLoading(false);
                })
            }
        };


    return (
<div className="awafiForm">

<div className="awafi-fromBody">
{loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

  <>
<p> {t("Login.registeration")}</p>
{!displayVerify?
<form  onSubmit={formik.handleSubmit}>
    <div>
<div className="inputDiv">
<label> {t("Login.yourName")}</label>
<input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name? t(`${formik.errors.name}`):null}</span>
</div></div>
{!localStorage.getItem("token")?
<div>
<div className="inputDiv">
<label>  {t("contact.byPhone")}</label>
<input type="text" placeholder={t("contact.byPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone? t(`${formik.errors.phone}`):null}</span>
</div>


</div>:null}

<div>
<div className="inputDiv">
<label>  {t("contact.email")}</label>
<input type="text" placeholder={t("contact.yourEmail")} value={formik.values.email} id="email" name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"invalid":null}/>

<span>{formik.errors.email && formik.touched.email? t(`${formik.errors.email}`):null}</span>
</div>
</div> 

<div>
<div className="inputDiv">
<label>  {t("Login.birthday")}</label>
<div style={{position:"relative"}}>
<input type="date"   max={today}  value={formik.values.birthday} id="birthday" name="birthday"  ref={birthdayRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.birthday && formik.touched.birthday?"invalid":null}/>
<BsCalendar4Week size={25} className="calendarIcon"/></div>
<span>{formik.errors.birthday && formik.touched.birthday? t(`${formik.errors.birthday}`):null}</span>
</div>
</div>
<div>
<div className="inputDiv">
<label>  {t("Login.nationality")}</label>
<select defaultValue="" value={formik.values.nationality} id="nationality" name="nationality"  ref={nationalityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nationality && formik.touched.nationality?"invalid":null}>

<option value="" disabled> {t("Login.nationality")}</option>
{countriesList.map((data,index)=>
  <option key={index} value={data.code}>{i18n.language=="ar"? data.nameAr:data.name}</option>


)}

</select>
<span>{formik.errors.nationality && formik.touched.nationality? t(`${formik.errors.nationality}`):null}</span>
</div>

</div>
<div>
<div className="inputDiv"> 
<label>  {t("Login.gender")}</label>
<div className="gender row">
<div className="col-6">
<input type="radio" name="gender" defaultChecked   value="male" id="male" onChange={handleOptionChange}/>
<label>  {t("Login.male")}</label>

</div>
<div className="col-6">
<input type="radio" name="gender"  value="female" id="female" onChange={handleOptionChange}/>
<label>  {t("Login.female")}</label>

</div>
 
</div>
</div>

 

</div>
<div>
<div  className="inputDiv">

<div style={{marginLeft:"0"}} className="gender awafiTerms">

 
<div>
<input type="checkbox" placeholder= {t("Login.yourName")} value={formik.values.terms} id="terms" name="terms"  ref={termsRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.terms && formik.touched.terms?"invalid":null}/>

<label onClick={()=>props.setPage(3)} className='terms'>{t("validation.terms")}</label></div>
<span>{formik.errors.terms && formik.touched.terms?t(`${formik.errors.terms}`):null}</span>


</div></div>
{i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr :null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}
</div>
<button type="submit">{t("Login.register")}</button>
</form>:null}
{ displayVerify?
<div className="container">
<p style={{display:"block"}} className='forgot'>      {t("Login.checkPhone")}</p>

<form className='otpForm' onSubmit={(e)=>handelVerificationSubmit(e)}>
    <div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
  <label>  {t("Login.EnterVerify")} </label>
  <div id="otp" className={i18n.language=="ar"?null:"otpEn"}>
 
    <OtpInput
     
     value={otp}
     onChange={(otp)=>handleChange(otp)}
        numInputs={6} 
       
      />
  </div></div> 
  <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
 {i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr:null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}

 </div>
<button type="submit">{t("Login.confirm")}</button>
</form>

</div>:null}

</>}

</div>


</div>
  
    );
  }
  
  export default AwafiSignUp;