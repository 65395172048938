import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../Styles/CartProcess.css";
import { add_address_car } from "../api";
import Toast from "react-bootstrap/Toast"; 


function CarDialog({ handleCancel, orderId, onSucess}) {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showDetailsInput, setShowDetailsInput] = useState(false);
  const [parkingNotes, setParkingNotes] = useState(""); 
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false); 

  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          console.log(position.coords.latitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const AddCarAddress = () => {
    setLoading(true);
    const body = {
      latitude: userLocation.latitude,
      longitude: userLocation.longitude,

      parking:
        selectedLocation == "anotherStation"
          ? "other"
          : selectedLocation == "Station1"
          ? 1
          : 2,
      parking_notes: parkingNotes == null ? "" : parkingNotes,
    };
    if (parkingNotes == null) {
      delete body.parking_notes;
    }
    add_address_car(orderId, body, localStorage.getItem("token"))
      .then((res) => {
        handleCancel();
        setLoading(false);
        onSucess()
      })
      .catch((e) => {

       
        setErrorMessage("فشل تعديل الطلب لعدم توافر معلومات الموقع."); 
        setShowErrorToast(true); 
        setLoading(false);
      });
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    setSelectedLocation(value);
    if (value === "anotherStation") {
      setShowDetailsInput(true);
    } else {
      setShowDetailsInput(false);
    }
    console.log(event.target.value);
  };

  const CircleLoader = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div
          className="spinner-border"
          role="status"
          style={{ width: "1.5rem", height: "1.5rem" }}
        >
          
        </div>
      </div>
    );
  };

  return (
    <div style={styles.overlay}>
       <Toast
          onClose={() => setShowErrorToast(false)}
          show={showErrorToast}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 9999,
            fontFamily: 'Cairo'
          }}
        >
          <Toast.Body>{errorMessage}</Toast.Body>
        </Toast>
      <div style={styles.container}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontFamily: "Cairo", fontWeight: "bolder" }}>
            {t("chooseTheDeliv")}
          </p>
        </div>
        {["Station1", "Station2", "anotherStation"].map((station) => (
          <div
            key={station}
            style={{
              ...styles.optionContainer,
              marginTop: station !== "Station1" ? "2vh" : 0,
            }}
          >
            <p style={styles.optionText}>{t(station)}</p>
            <input
              style={styles.radioButton}
              type="radio"
              value={station}
              checked={selectedLocation === station}
              onChange={handleLocationChange}
            />
          </div>
        ))}

        {showDetailsInput && (
          <div style={styles.detailsInputContainer}>
            <style>{`
            input::placeholder {
              font-family: Cairo;
              font-size: 1em;
              font-weight: lighter
            }
            `}</style>
            <input
              className="ot-top"
              style={{ ...styles.detailsInput, border: "1px solid #A9A9A9" }}
              type="text"
              placeholder={t("enterDetails")}
              value={parkingNotes} 
              onChange={(e) => setParkingNotes(e.target.value)} // Update parkingNotes state

            
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2vh",
          }}
        >
          <button style={styles.btn1} onClick={AddCarAddress}>
            {loading == true ? <CircleLoader /> : t("profile.confirm")}
          </button>

          <button style={styles.btn2} onClick={() => handleCancel()}>
            {t("profile.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  container: {
    backgroundColor: "white",
    padding: "20px",
    maxWidth: "400px",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    position: "relative",
  },
  btn1: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "-0.3px",
    border: "1px solid #E47100",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#E47100",
    width: "25%",
    height: "5vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "1vw",
  },

  btn2: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "15px",
    letterSpacing: "-0.3px",
    border: "1px solid #E47100",
    borderRadius: "10px",
    color: "#E47100",
    backgroundColor: "white",
    width: "25%",
    height: "5vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "1vw",
  },
  optionContainer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1vw",
    borderStyle: "solid",
    borderRadius: "10px",
    height: "8vh",
    borderColor: "#A9A9A9",
  },
  optionText: {
    margin: 0,
    padding: 0,
    fontFamily: "Cairo",
    color: "#A9A9A9",
    fontWeight: "600",
    
  },
  radioButton: {
    margin: 0,
    padding: 0,
  },
  detailsInputContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2vh",
  },
  detailsInput: {
    width: "100%",
    padding: "1vh",
    borderRadius: "3px",
    borderColor: "#A9A9A9 !important",
    fontFamily: "Cairo",
    fontSize: "1em",
  },
  confirmButton: {
    padding: "1vh 2vh",
    borderRadius: "10px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    fontFamily: "Cairo",
    cursor: "pointer",
  },
};

export default CarDialog;
