
import '../Styles/Login.css';
import food from "../Images/Login/Food.svg"
import logo from "../Images/Login/logo.svg"
import brown from "../Images/Login/background.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../Firebase/firebaseConfig";
import { useTranslation } from 'react-i18next';
import { BsCalendar4Week } from "react-icons/bs";

import { BeatLoader } from 'react-spinners';
import Footer from "../Components/Footer";
 
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import {account,terms,countries} from "../api";
import TermsModal from '../Components/TermsModal';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";

function Register() {
    const [hidden,setHidden]=useState(false);
    const [hidden2,setHidden2]=useState(false);
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false);
    const analytics = getAnalytics(app);
const [country,setCountry]=useState();
const[startDate,setStartDate]=useState();

const[date,setDate]=useState("");

const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [verified, setVerified]=useState(false);
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [msgArp, setMsgArp]=useState();
const [msgEnp, setMsgEnp]=useState();
const [errorMsgModelp, setErrorMsgModelp]=useState(false);
const [arDesc, setArDesc]=useState();
const [enDesc, SetenDesc]=useState();
const [modal,setModal]=useState(false);

const [dateErr,setDateErr]=useState(false);

      const history=useNavigate();

      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

      const Schema= yup.object().shape({
      phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
      password: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
      name: yup.string()
      .required(("validation.name_required")).matches(nameRegEx,{message:("validation.name_not_valid")}).min(6, ("validation.name_atleast")),
      countryId: yup.string()
      .required(("validation.country_required")),
      confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], ("validation.password_must_match"))
      .required(("validation.confirm_required")),
      terms: yup.boolean().oneOf([true], ("validation.terms_must_checked")),
      
      })
      


      const formik = useFormik({
      initialValues:{
        name: '',
       phone: '',
      countryId: '',
        password: '',
        confirmPassword: '',
        terms: false
      },
      validationSchema: Schema,
      onSubmit
      });
      console.log(formik.errors);
      const passRef=useRef();
          const phoneRef=useRef();
          const passConfirmRef=useRef();
          const countryRef=useRef();
          const nameRef=useRef();
          const termsRef=useRef();
      
          useEffect(() => {
            localStorage.setItem("prevScreen","redirect"); //for use in loggin (if prevScreen = redirect logging in redirects to home screen instead of previous screen)
      //for getting list of countries
      countries().then(response => {
        const data=response.data.data;
        setCountry(data);
  })
      
          //for getting terms and conditions
        terms().then(response => {
          setArDesc(response.data.data.arDescription);
          SetenDesc(response.data.data.enDescription);
     })
             },[]);




//for hiding and showing password
             function show() {
        setHidden(!hidden);
        var x = document.getElementById("pass1");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }
//for hiding and showing confirm password
      function show2() {

        setHidden2(!hidden2);
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }





//On submitting for registration (takes:name,phone,countryId,password,password confirmation and agreement to terms (bool))
//user is given a token that is stored to be used in /verify where the user is redirected

      async function onSubmit(event){

        if(date==""){
          setDateErr(true)
        }
        else{
        setLoading(true);
        setDateErr(false)

        const data={
     
         name: nameRef.current.value,
         phone: phoneRef.current.value,
        countryId:parseInt(countryRef.current.value),
          password: passRef.current.value,
          confirmPassword: passConfirmRef.current.value,
          terms: termsRef.current.value,
          birthDate: date

        }


           
        account(data).then((response) => {
             
          localStorage.setItem("token", response.data.data.access_token);
localStorage.setItem("verified",JSON.stringify(false))
localStorage.setItem("userPhone", data.phone)

logEvent(analytics, 'sign_up', {
  phone: data.phone,
  name:data.name
});

          history("/verify");
         var frm = document.getElementsByName('regForm')[0];
   
          frm.reset(); 

 

}).catch(function (error) {
                console.log(error.response.data)  ;  
  if(error.response.data.error === "phone_exist"){
                                    
                                        setMsgArp("هذا الرقم مسجل من قبل");
                                        setMsgEnp("This phone already exists");
                                        setErrorMsgModelp(true);
                                      
                                        setLoading(false);
                                    }
                                    if(error.response.data.error === "phone_regex"){
                                     
                                      setMsgArp("تأكد من رقمك");
                                      setMsgEnp("Phone number is not valid");
                                      setErrorMsgModelp(true);
                                    
                                      setLoading(false);
                                  }   
                                  else{
                                    setMsgAr("حدث خطأ   ");
                                    setMsgEn("Something went wrong");
                                    setErrorMsgModel(true);
                                  
                                    setLoading(false);
                                  }
                                  }
                                 
                                    )}}



                                   const   handleChangeDate = (date) => {

                                      if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
                                        
                                          setStartDate(date);
                                       
                                      } else { 
                                         
                                              setStartDate(date);
                                  
                                       
                                      }
                                  
                                      let d = date.getDate();
                                      let m = date.getMonth() + 1;
                                      let y = date.getFullYear();
                                    
                                    
                                      if(d<10){
                                          d = '0'+d;
                                      }
                                      if(m<10){
                                          m = '0'+m;
                                      }
                                      let selectedDate = y + '-' + m + '-' + d;
                                  
                                  
                                  
                                     
                                          setDate(selectedDate);
                                  
                                  };

                                  
 
   return (
        <div>
        
              
{modal? (
        <TermsModal setModal={setModal} describe={i18n.language=="ar"?arDesc: enDesc}/>
      ):null}
        <div className='loginContainer container-fluid fluid'>
            
<div className='container-fluid login'>
{loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  className='row'>
<div className='col'>
    <div className='loginTitle'>
<p >{t("Login.registeration")} </p></div>
<form className='loginForm'  name='regForm' onSubmit={formik.handleSubmit}>
<label> {t("Login.yourName")} </label>


<input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>

 

<label>  {t("contact.byPhone")}</label>

<input type="text" placeholder={t("contact.byPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModelp?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
{errorMsgModelp?
  <span>{i18n.language=="ar"? msgArp:msgEnp}</span>:null
}

<label> {t("Login.country")}</label>
 
<select value={formik.values.countryId} id="countryId" name="countryId"  ref={countryRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.countryId && formik.touched.countryId?"invalid":null}>
<option selected disabled value="">{t("Login.chooseCountry")}</option>
{country && country.map((data,index)=>
  <option key={index} value={data.id}>{i18n.language=="ar"?data.arName:data.enName}</option>)
}

</select>
<span>{formik.errors.countryId && formik.touched.countryId?t(`${formik.errors.countryId}`):null}</span>


<label> {t("Login.birthday")}</label>
<div className={dateErr?'invalid-date':null} style={{position:"relative",width:"80%",display:"inline-block"}} >

<DatePicker
    required
    selected={startDate}
    placeholderText={"mm/dd/yyy"}
   onChange={handleChangeDate}

   maxDate={new Date(new Date().getFullYear() - 16, 11, 31)} // 16 years ago
   minDate={new Date(new Date().getFullYear() - 80, 0, 1)}
      showYearDropdown
   yearDropdownItemNumber={15}
   scrollableYearDropdown
   />
<BsCalendar4Week size={25} style={i18n.language=="ar"?{left:"10px",right:"auto"}:{right:"10px",left:"auto"}} className="calendarIcon"/></div>
{dateErr?<span>{t("validation.birthday_required")}</span>:null}


<label>   {t("Login.pass")}</label>
<div className='loginPass'>

<input id="pass1" style={{width:"100%"}} type="password" value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
{hidden?
<AiOutlineEyeInvisible onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'}style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>

</div>
<label>     {t("Login.confirmPass")}</label>
<div className='loginPass' >


<input id="confirmPassword"  style={{width:"100%"}}  type="password" value={formik.values.confirmPassword}  name="confirmPassword"  ref={passConfirmRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword?"invalid":null}/>
{hidden2?
<AiOutlineEyeInvisible onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<span>{formik.errors.confirmPassword && formik.touched.confirmPassword?t(`${formik.errors.confirmPassword}`):null}</span>

</div>

<div>
<input type="checkbox" placeholder= {t("Login.yourName")} value={formik.values.terms} id="terms" name="terms"  ref={termsRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.terms && formik.touched.terms?"invalid":null}/>

<label onClick={()=>setModal(true)} className='terms'>{t("validation.terms")}</label></div>
<span>{formik.errors.terms && formik.touched.terms?t(`${formik.errors.terms}`):null}</span>



{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<button type="submit" style={{marginTop:"2em"}}> {t("Login.register")}</button>


</form>
<div className='register'>
<p>       {t("Login.haveAccount")}</p>
<Link to="/Login"> <button>  {t("Login.start")} </button></Link>
</div>
</div>
<div className={i18n.language=="ar"?'col loginImagesAr':'col loginImages'}>
  
<img className='img1' src={brown} />
<img className='img2' src={food} />
<img className='img3' src={logo} />

</div>
</div>
</div>
</div>
<Footer/>
</div>
    );
}

export default Register;