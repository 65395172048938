
import "../Styles/Branches.css";
import Navbar from "../Components/Navbar";
import { useEffect,useState } from "react";
import Footer from "../Components/Footer";
import brnch from "../Images/branches.svg"
import branch from "../Images/BranchImage.svg"
import { FaPhone } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Branch from "../Components/Branch";
import { branchByCountry,countries,branchesByCity } from '../api'
import { useTranslation } from 'react-i18next';
import { BeatLoader } from "react-spinners";
import { BackgroundImage } from 'react-image-and-background-image-fade'
 
function Branches(props) {
  const { t, i18n } = useTranslation();
  const[branch,setBranch]=useState([]);
  const[allBranches,setAllBranches]=useState([]);

const [city,setCity]=useState([]);
const [loading,setLoading]=useState(true);
const [cityLoad,setCityLoad]=useState(false);


//fetch cities and sets them according to current chosen country index (props.country)
//fetch branches
  useEffect(() => {
   

  countries().then(response => {
  
  
    setCity(response.data.data[props.country].cities)})

    let countryId= props.country==0? 1:2;


    branchByCountry(countryId).then(response => {
      const data=response.data.data;
      setBranch(data);
      setAllBranches(data)
   console.log(response);
   setLoading(false)
   })
  
   },[props.country]);




//on changing city if value chosen is "all" , all branches are fetched
//else branchesByCity api call takes chosen city id and returns branches of that city
   function handleChange(e){
    setCityLoad(true)
   // setCityValue(e.target.value)
 if(e.target.value=="all"){
  setBranch(allBranches);
 setCityLoad(false)


 }

 else{

 
    branchesByCity(e.target.value).then(response => {
    setBranch(response.data.data);
    setCityLoad(false)
      })}
   }
   
    return (
    <div>
   

   {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:


    
    <div className="aboutusBody container-fluid fluid">
  <div >
    <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>

    <BackgroundImage
        src={brnch}
       width="100%"
   
       className="branchesBanner"
      />
{/*<div className="branchesBanner" style={{ backgroundImage: `url(${brnch})` }}>
</div>*/}</div>
<div className="branchesSort">
<div style={{marginTop:"2em"}} className="brnchHeader"> 
{/*  <p className="branchesTitle">{t("branches.our_branches")}</p>*/}
 
  <p className={i18n.language=="ar"?"srtTitle":"srtTitleEn srtTitle"}> {t("branches.sort")}</p>
 
<div className=" sortOptions">

<select defaultValue={"all"} onChange={(e)=>handleChange(e)}>
{/*<option value="" disabled> {t("branches.city")}</option>*/}
<option value="all" > {t("branches.all")}</option>

{city && city.map((data,index)=>

 
<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 
</select>
   
 </div>
 

</div>
<hr/>
<div style={{marginTop:"3em"}} class="container-fluid ">
{cityLoad?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={cityLoad}  size={30} /></div>:
      branch && branch.length==0?
        <div style={{height:"50vh"}} className="container-fluid fluid chooseCity">
        <div style={{transform:"none"}}>
         
        <p className="noNear">{t("branches.noBranch")}</p>
      
        </div></div>:
  <div class="row gy-5">

     
{branch && branch.map((data,index)=>

 

<Branch key={index} name={i18n.language=="ar"?data.arName:data.enName} address={i18n.language=="ar"?data.arAddress:data.enAddress} image={data.image}  id={data.id}
latitude={data.latitude}
longitude={data.longitude}
open={data.open}
phone={data.phone} 
workingFrom ={data.workingFrom}
workingTo={data.workingTo}
/>



 


 
   
     
     )
}

  </div>}
</div>

</div>

</div>
 
</div>}
<Footer/> 
</div>

  
    );
  }
  
  export default Branches;