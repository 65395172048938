
import React, {useState,useEffect} from 'react';
 
import "../Styles/Carousel.css";

import { Banners } from '../api'
import { Swiper, SwiperSlide } from "swiper/react";
import { BackgroundImage } from 'react-image-and-background-image-fade'

// Import Swiper styles
import branches from "../Images/branches.svg"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation ,Autoplay} from "swiper";
import { useTranslation } from 'react-i18next';

//takes images to be displayed as props from parent components
function Slider(props) { 
  const[banners,setBanners]=useState([]);

  const [loading,setLoading]=useState(true);
  const { t, i18n } = useTranslation();
  

    return (
<div className='container-fluid fluid' style={{marginTop:"2em",marginBottom:"1em"}}>
            <div className={i18n.language=='ar'?'crslAr':null}>
              


                <Swiper
                  dir={i18n.language=="ar"?"rtl":"ltr"}
                 style={{
                
                    "--swiper-pagination-color": "#E47100",
                   "borderRadius" :"20px"
                  }}
pagination={{
    clickable: true,

 
}}
key={i18n.language}
navigation={true}
centeredSlides={true}
loop={true}
spaceBetween={30}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}

     
modules={[Pagination, Navigation,Autoplay]}
className={i18n.language=="ar"?"myCarousel myCrsl btnAr":"myCarousel myCrsl btnEn"}
>

{props.banners && props.banners.map((data,index)=>


<SwiperSlide key={index}>  
 { /*<img
      className="d-block w-100"
      src={data.image}
      alt="First slide"
/>*/}
{/*
<div  className='crslBackground' style={{ backgroundImage: `url(${data.image})` }}>

</div> */}
   <BackgroundImage
        src={data.image}
       width="100%"
   
       className="crslBackground"
      />
     </SwiperSlide>


   
     
     )
}


</Swiper>

</div></div>
    );
}

export default Slider;
