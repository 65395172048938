  

import { useRef,useState,useEffect } from "react";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import { create_order  } from '../api'
import gift from "../Images/present.svg"
import party from "../Images/Party.svg"
import { useNavigate } from "react-router-dom";
import { CustomerInfo,Get_Basket } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../Firebase/firebaseConfig";
import { generatePath } from 'react-router-dom';
import prepare from "../Images/prepare.svg"
  
import mc from "../Images/CartMotorcycle.svg"
function FreeMeals(props) {
    const { t, i18n } = useTranslation();
    let history = useNavigate();
   const free= JSON.parse(window.localStorage.getItem('FREEMEALS'));
   const [msgEn, setMsgEn]=useState();
   const [msgAr, setMsgAr]=useState();
   const [errorMsgModel, setErrorMsgModel]=useState(false);
   const[cart,setCArt]=useState();
   const [phone,setPhone]=useState("");
   const analytics = getAnalytics(app);
const [loading,setLoading]=useState(true)
const [estimate,setEstimate]=useState("")
const [recieved,setRecieved]=useState(false)


//fetch user data for use in webengage and analytics
//fetch user cart data

   useEffect(()=>{
 
  
    window.scrollTo(0, 0);
    const token= localStorage.getItem('token');
 
   
    CustomerInfo(token).then((response) => { 
  
      setPhone((response.data.data.phone).toString())
      
      })
 
      let id=localStorage.getItem("orderId")
    Get_Basket(token).then(response => {
      setCArt(response.data.data.cart);
      setLoading(false)
 
    }).catch((err) => {
      //on creating order cart gets cleared so returing to this screen goes into catch, user is redirected to track the order instead
      history(generatePath("/order-track/:id", {id}));
    
     }); 
   
  
  },[ ]) 



  /*create_order api call takes data2 (from CartProcess3 component found in /Pages) if payment method is tap user is redirected to third party service 
  else screen display changes to show sucess*/

    function handleOrder(){
      setLoading(true)
      const token= window.localStorage.getItem('token');
      const cartId= window.localStorage.getItem('CartId');
    
  const data2=window.localStorage.getItem('ORDER');

 
  

      create_order(JSON.parse(data2),token).then(response => {
        logEvent(analytics, 'create_order', JSON.parse(localStorage.getItem("logData")));

    
        localStorage.removeItem("promo") //remove value of promo code
        localStorage.removeItem("paymentMethod") //remove value of chosen paymetn method
      
         //data stored in cartProcess2
         localStorage.removeItem("timeNow");
         localStorage.removeItem("cartMessage");
         localStorage.removeItem("orderDate");
         localStorage.removeItem("deliveryType")
         
        localStorage.setItem("process",0); //set user step inc cart to first step
        props.setCartChange(!props.cartChange) //triggers api call in (App.js) to rerender cart
      
        if(parseInt(localStorage.getItem('typeId')) ===  2){ //home delivery has estimated delivery time

          setEstimate(response.data.data.remainingTime)
       } 
  
       if(JSON.parse(data2).paymentMethod === "wallet" ){
        localStorage.setItem("orderId", response.data.data.orderId); //to be used in PaymentSucess component fount in /Pages
        if (response.data.data.url == null ||  response.data.data.url == undefined) {
          logEvent(analytics, 'purchase', {
            transaction_id:"",
           
          });
          setRecieved(true) //changes screen display
          setLoading(false)
    
        }
        else {
          logEvent(analytics, 'checkout_started', {
            phone: phone,
    amount: cart.totalPrice,
    currency: cart.currencyEn
          });

          logEvent(analytics, 'begin_checkout',null);
    /*
          window.webengage.track("Checkout Started", {
            phone: phone,
            amount: breakdown.totalPrice,
            currency: cart.currencyEn
          })*/
          
       localStorage.setItem("payment","tap")  //to be used in PaymentSucess component fount in /Pages
    
           window.location.href = response.data.data.url
        }
      }
       else if(JSON.parse(data2).paymentMethod  === "tap"){
          localStorage.setItem("orderId", response.data.data.orderId);
         
          if (response.data.data.url === null) {
        console.log("tap");
        //localStorage.setItem("menuRoute", "false");
        
          }
          else { 
            logEvent(analytics, 'checkout_started', {
              phone: phone,
      amount: cart.totalPrice,
      currency: cart.currencyEn
            });
             
            logEvent(analytics, 'begin_checkout',null);
/*
            window.webengage.track("Checkout Started", {
              phone: phone,
              amount: cart.totalPrice,
              currency: cart.currencyEn
            })*/
            localStorage.setItem("payment","tap") //to be used in PaymentSucess component fount in /Pages
             window.location.href = response.data.data.url
          }
        }
        else if(JSON.parse(data2).paymentMethod === "checkout"){
          localStorage.setItem("orderId", response.data.data.orderId);
          localStorage.removeItem("cardToken")
      
          if (response.data.data.url === null || response.data.data.url ==="" || response.data.data.url == undefined) {
           
            setRecieved(true) //changes screen display
            setLoading(false)
          
              }
              else {
                window.location.href = response.data.data.url
              }
      
        }
        else {
        
          logEvent(analytics, 'purchase', {
            transaction_id:"",
           
          });
          localStorage.setItem("orderId", response.data.data.orderId);
       
          setRecieved(true) //changes screen display
          setLoading(false)
        }
        
      }).catch(error => {
        if (error.response.status === 422) {
          
          setErrorMsgModel(true);
          setMsgEn("Please wait, there is a request under process");
          setMsgAr("الرجاء الانتظار ، هناك طلب قيد التنفيذ");
          setLoading(false)
          setTimeout(function() {
           
            setErrorMsgModel(false);
           }, 2000)
      } })
    }



    //on clicking track order user id redirected to /order-track (OrderTracking component found in /Pages) with order id as params

    function handleProceed(){
      const id=localStorage.getItem("orderId");
      history(generatePath("/order-track/:id", { id }));
      
    };

    

    return (
 
        <div>
        <div className='container-fluid fluid ' >
      
        {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

recieved?

<div style={{marginTop:"2em"}} className="cpMiddle-title2"> 
 
 <div className="cpPayment">
     <div className="row">   
     <div className="col-md-6 cpPayment-div2 ">

 {parseInt(localStorage.getItem('typeId')) === 2?
<img src={mc} alt="image"/>

:<img src={prepare} alt="image"/>}
 

  
 </div>
 <div className="col-md-6 cpPaymentDone">
 {parseInt(localStorage.getItem('typeId')) === 2?
 <div>
<p className="paymentDone-p1"> {t("process.success")} &#x1F389;</p>

<p className="paymentDone-p2">{t("process.time_estimate")} <span> {estimate.timeFrom} </span>{t("process.minute")}  {t("process.to")}  <span>  {estimate.timeTo} </span>{t("process.minute")} &#160;</p>
<button onClick={handleProceed}> {t("process.track")} </button>
</div>:

<div>
<p className="paymentDone-p1"> {t("process.waiting1")}</p>

<p className="paymentDone-p2">{t("process.waiting2")}</p>

<button onClick={handleProceed}> {t("process.track")} </button>
</div>}


 </div>
</div>
 </div>
 </div>

:


      <div className='giftContainer'>
          <img className="freeImg" src={gift} />
    
       
    <div >
     <p className="gift-p1">{t("process.gift1")}</p> <span></span> 
     <img src={party}></img>
    <p className='gift-p2'>{t("process.gift2")}</p>
    <div style={{display:"inline-block"}} >
       <ul>
      

    {free && free.map((data, index)=>{
           return ( 
            <li className={i18n.language=="en"?"liEn":null} key={index}>{i18n.language=="ar"? data.arName:data.enName}</li>
       );})}
    
       </ul>
       </div>
    </div>
    
      
  
<div style={{minHeight:"100px",bottom:"70px"}} className={ errorMsgModel?'addAlert ':' addAlert alert-hidden '}  
  >

       <span style={{color:"brown"}}>{i18n.language=="en"? msgEn:msgAr}</span>


</div> 
      <button onClick={handleOrder}>{t("process.confirm")}</button>
          </div>}
        </div>
        
  

<Footer/> 
</div>

  
    );
  } 
  
  
  export default FreeMeals;