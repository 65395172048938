import React, { useEffect, useState } from "react";
import UserNav from "../Components/UserNav";
import { useTranslation } from "react-i18next";
import logo from "../Images/fullLogo.png";
import {
  Get_Subscriptions,
  Subscription_package,
  terms_packages,
} from "../api";
import { BeatLoader } from "react-spinners";
import { Frames } from "frames-react";
import PaymentToggle from "../Components/togglepayment";
import { useNavigate } from "react-router-dom";
import { PackageCard } from "../Components/packageCard";
import { CreditCardPayment } from "../Components/creditCardPayment";
import Logout from "../Components/Logout";
import { SubscripedPackage } from "../Components/SubscribedPackage";
import HTMLContent from "../Components/HtmlContent";
import { Modal, Button } from "react-bootstrap";

const AlromansiahPlus = (props) => {
  const [modal, setModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [imageHeader, setImageHeader] = useState(null);
  const [cardValid, setCardValid] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [wallet, setWallet] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isUserSupscriped, setIsUserSubscribed] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [arDescription, setArDescription] = useState();
  const [enDescription, setEnDescription] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useNavigate();
  let language = i18n.language === "ar" ? "ar" : "en";
  let countryId = props.country == 0 ? 1 : 2;
  const token = localStorage.getItem("token");
  useEffect(() => {
    setLoading(true);
    terms_packages().then((res) => {
      console.log(res.data.data.arDescription);
      setArDescription(res.data.data.arDescription);
      setEnDescription(res.data.data.enDescription);
    });

    Get_Subscriptions(language, countryId, token)
      .then((res) => {
        console.log(res.data.data);
        setPackages(res.data.data.packages);
        setImageHeader(res.data.data.coverImg);
        setWallet(res.data.data.paymentMethods.walletBalance);

        setPaymentMethodType(res.data.data.paymentMethods.paymentOnlineType);
        setIsUserSubscribed(
          res.data.data.userSubscription == null ? false : true
        );
        if (res.data.data.userSubscription != null) {
          setUserSubscription(res.data.data.userSubscription);
        }
        
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [i18n.language, props.country, paymentMethodType]);

  const handlePackageSelect = (packageItem) => {
    setSelectedPackage(packageItem);
    setSelectedPayment("credit");
  };

  const handlePaymentSelection = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const handleTextClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const subscribePackage = async () => {
    setSubmitLoading(true);

    try {
      if (selectedPayment === "credit") {
        await submitCreditCardPayment();
      } else if (selectedPayment === "wallet") {
        await submitWalletPayment();
      }
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  };

  const submitCreditCardPayment = async () => {
    try {
      if (paymentMethodType === "checkout") {
        await Frames.submitCard();
      }
      const token_payment = localStorage.getItem("package_payment_token");

      setLoading(true);
      const data = {
        packageId: selectedPackage.id,
        paymentMethod: paymentMethodType,
        token: token_payment,
      };
      await processSubscription(language, countryId, data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const submitWalletPayment = async () => {
    try {
      setLoading(true);
      const data = {
        packageId: selectedPackage.id,
        paymentMethod: "checkout",
      };
      await processSubscription(language, countryId, data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const processSubscription = async (language, countryId, data) => {
    try {
      await Subscription_package(language, countryId, token, data).then(
        (res) => {
          if (res.status == 201) {
            localStorage.setItem("transactionType", "subscription");

            window.location.href = res.data.data.url;
          }
        }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}
      {error && <p>{error}</p>}
      {loading ? (
        <div className="loader profileLoader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="userProfile">
          <UserNav setModal={setModal} nav={4} />
          <div className="container">
            <div style={{ display: "flex", flexDirection: window.innerWidth < 768 ? "column" : "row" }}>
              <div className="elevated-card">
                <div className="card-body center-content">
                  <img src={logo} alt="logo" />
                  
                  <p style={{ marginBottom: "12px" }} className="card-text">
                    {t("profile.deliveryProgram")}
                  </p>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${imageHeader})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%", 
                  boxShadow:
                    "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                  marginTop: "70px",
                  width: "38vw",
                  marginRight: "10px",
                  marginLeft: "10px",
                  height: "20vw",
                  borderRadius: "15px",
                }}
              ></div>
            </div>

            {isUserSupscriped && (
              <>
                <p style={{ margin: "15px" }} className="upTable-title">
                  {t("profile.subscriptionStatus")}
                </p>

                <SubscripedPackage userSubscription={userSubscription} />
              </>
            )}

            <p style={{ margin: "15px" }} className="upTable-title">
              {t("profile.packages")}
            </p>
            <div className="card-container">
              {packages.length === 0 ? (
                <p
                  style={{
                    fontFamily: "cairo",
                    ...(language === "en"
                      ? { marginRight: "-80px" }
                      : { marginLeft: "-60px" }),
                  }}
                >
                  {t("home.noPackages")}
                </p>
              ) : null}
              {packages.map((packageItem) => (
                <PackageCard
                  packageItem={packageItem}
                  key={packageItem.id}
                  isUserSubscribed={isUserSupscriped}
                  onSelect={() => handlePackageSelect(packageItem)}
                  language={language}
                />
              ))}
            </div>

            {selectedPackage && (
              <h5 style={{ margin: "15px" }} className="card-title">
                {t("profile.payment")}
              </h5>
            )}

            <div style={{ display: "flex", flexDirection: "row" }}>
              {selectedPackage && (
                <PaymentToggle
                  selectedPayment={selectedPayment}
                  setSelectedPayment={handlePaymentSelection}
                  wallet={wallet}
                />
              )}
              <div style={{ margin: "10px", width: "40vw" }}>
                {selectedPackage && paymentMethodType === "checkout" && (
                  <CreditCardPayment setCardValid={setCardValid} />
                )}
              </div>
            </div>

            {!userSubscription && packages.length !==0 && (
              <div
                style={{
                  marginTop: '10px',
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <input
                  type="checkbox"
                  style={{ verticalAlign: "middle" }}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <p
                  onClick={handleTextClick}
                  style={{
                   
                    marginLeft: language === "en" ? "15px" : undefined,
                    marginRight: language === "ar" ? "15px" : undefined,
                    marginBottom: "0",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("profile.acceptTerms")}
                </p>
              </div>
            )}

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton style={{ textAlign: "center" }}>
                <Modal.Title
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontFamily: "cairo",
                  }}
                >
                  {t("profile.Terms&Conditions")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ overflowY: "auto" }}>
                <HTMLContent
                  htmlContent={language == "ar" ? arDescription : enDescription}
                />
              </Modal.Body>
            </Modal>

            {!isUserSupscriped && (
              <button
                className="subscribe-btn"
                onClick={subscribePackage}
                disabled={selectedPayment && !isChecked}
                style={{
                  marginTop: "15px",
                  backgroundColor:
                    selectedPayment && isChecked ? "#e67e22" : "#ccc",
                  color: selectedPayment ? "white" : "#666",
                  cursor: selectedPayment ? "pointer" : "not-allowed",
                }}
              >
                {submitLoading ? (
                  <BeatLoader color={"#E47100"} />
                ) : (
                  t("profile.subscribeNow")
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AlromansiahPlus;
