  /*user is redirected to this screen from either login or 
 registration where an api was used to send user an otp*/
import '../Styles/ChangPass.css';
import mobile from "../Images/Login/mobile.svg"

import changePass from "../Images/Login/changePass.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";
import {CustomerInfo2,otp,SMS} from "../api";
import { verifyPhone } from '../api';
import { BeatLoader } from 'react-spinners';
import Navbar from "../Components/Navbar";
import { useTranslation } from 'react-i18next'; 
import Footer from "../Components/Footer";

function UpdatePhone(props) {

let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [userPhone,setUserPhone]=useState(JSON.stringify( localStorage.getItem("newPhone")));
  const [loggedIn,setLoggedIn]=useState(false);
      const [otpVerify,setOtp]=useState("");
      const [loading,setLoading]=useState(false);
      const [errorMsgModel2, setErrorMsgModel2]=useState(false);
  
      const [seconds,setSeconds]=useState(59);
      const [showSec,setShowSec]=useState(false);

      const [msgAr, setMsgAr]=useState();
      const [msgEn, setMsgEn]=useState();
      
      const [errorMsgModel, setErrorMsgModel]=useState(false);
  

     const handleChange = (otpVerify) =>{ setOtp( otpVerify );
        console.log(otpVerify);

     }  


    
     useEffect(() => {
      localStorage.setItem("prevScreen","redirect");
    
     },[]);

 
/*(takes the otp code and new phone number user entered) and if it is correct user information is 
fetched and then user is redirected to /profile/myData (PersonalData screen found in /Pages)*/
     function handleSubmit(e){
        e.preventDefault();
        setLoading(true)
      const  data = {
          phoneVerificationCode: otpVerify,
          phone: localStorage.getItem("newPhone"),
      } 

      const token =   localStorage.getItem("token");

      verifyPhone(data,token).then((response) => {
        if (response.data.message === "success") {
    localStorage.setItem("userPhone",data.phone);
          console.log(response);
          
            history("/profile/myData");
        
          
            }

            else {

                 
                setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                setErrorMsgModel(true);
                setLoading(false)
                console.log(response);
          }


      }).catch(function (err) {
        if(err.response.status === 401){
          //used to change cart icon's current value to 0
      
          props.setCartItem(0) 
      
      
        localStorage.removeItem("token");
        localStorage.removeItem("userPhone");
        localStorage.removeItem("userName");
        localStorage.removeItem("basket");
        //was used to store user's current step in cart process
        localStorage.removeItem("process");
      
       history("/login")
      
        
        
          }
        setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                
                setErrorMsgModel(true);
                setLoading(false)
            
   
          })

      

      }

//for resending otp code
      function resend(){
        setLoading(true)
        const token =   localStorage.getItem("token");
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json'
      };

        SMS(headers).then((response) => {
         
                setMsgAr("تم إرسال الكود");
                setMsgEn("Your code has been send");
                setErrorMsgModel(false);
                setErrorMsgModel2(true);
               
                setShowSec(true)
                setLoading(false)
                let second= 59;
                setInterval(()=>{
                
                  if(second>0){
                   second=second-1
                   setSeconds(second)
                   setShowSec(true)
               
              
                
               }
                  else{
                   setErrorMsgModel2(false);
                    setShowSec(false)
                 
                  }
             
                },1000)
        }).catch(function (error) {
            // console.error(error);

        });
    };
 
   return (
        <div>
          
        
         <div className='loginContainer container-fluid fluid'>
            
<div style={{padding:"3.5em 2em",position:"relative"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null} className='row'>
<div className='col-md-6 passAlign'>
<img className='otpImage' src={mobile}/>
<p className='forgot'>      {t("Login.checkPhone")}</p>

<p className='otpPhone'>{userPhone}</p>
<form className='otpForm'  onSubmit={(e)=>handleSubmit(e)}>
    <div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
  <label>  {t("Login.EnterVerify")} </label>
  <div id="otp">
      {/*
  <input type="text" maxLength={1} style={{marginRight:"0"}}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
   <input type="text" maxLength={1} />
   */}
    <OtpInput
     
     value={otpVerify}
     onChange={(otpVerify)=>handleChange(otpVerify)}
        numInputs={6} 
       
      />
  </div></div> 
  <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
 {i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr:null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}

 </div>
<button type="submit">{t("Login.confirm")}</button>
</form>

</div>

<div className={i18n.language=="ar"?'col-md-6 passPhoneImage':"col-md-6 passPhoneImageEn"}>
  
<img src={changePass}/>

</div>
</div>
</div>
</div>
<Footer/>
</div>
    );
}

export default UpdatePhone;