import Barcode, { propTypes } from "react-barcode";
import "../Styles/Card.css"
import React,{useRef} from "react";
import logo from "../Images/Login/logo.svg"
import cardDeco from "../Images/cardDeco.svg"
import { useTranslation } from "react-i18next";
function Card(props) {
 
   const phone=localStorage.getItem("userPhone");
   const { t, i18n } = useTranslation();
 
    return (
      <div className="barCode">
        <div className="spaceAround">
<div className="cardTop">
<img style={{width:"40px",height:"40px"}} src={logo}/>
<div></div>
    <div></div>
<p className="dateT">{t("profile.membership")}</p> 
   
    <p className="date">{props.joinDate}</p>
 

</div>
<div className="spacebtn">
<p className="points">{props.pointsBalance}</p>
      <p className="pointsT"> {t("profile.point")}</p>
   

    </div>
    <div className="spacebtn">
    <p className="points">{parseFloat(props.pointsBalance*props.pointPrice).toFixed(2)}</p>
    <p className="pointsT">{props.currency} </p>
    </div>
 <img className="decore" src={cardDeco}/>
        </div>
   <Barcode
      
        value={phone}
        displayValue={true}
        width={4}
        height={50}
        background= "#ffffff"
        font="Avenir Next"
        fontOptions=" "
        textMargin={10}
  marginTop={15}
  marginBottom={15}
    margin={20}
      />
     
         </div>
    );
  }
  
  export default Card;