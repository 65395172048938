import axios from 'axios'


export default axios.create({
    baseURL: `${process.env.REACT_APP_API_PROVIDER}`,
    headers: {
        'platform': "web", 
        'countryId':   localStorage.getItem("country")?localStorage.getItem("country").toString()=='0'?1:2:1,
        'Content-type': 'application/json'
    }
}) 