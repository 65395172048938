
 
import "../Styles/CompanySales.css";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
function AwafiPoints(props) {
    const { t, i18n } = useTranslation();
const [points,setPoints]=useState();
const [currency,setCurrency]=useState();
 


function calculate(e){

    setCurrency(parseFloat(e.target.value*props.pointPrice).toFixed(2));
 
}


    return (
        
        <div className="awafiPoints">
         <div className="container-fluid">
            <div className="row pointsExchange">
<div className="col-md-3 pointsAlign">

    <p className="exchangeTitle"> {t("awafiPoints.calc")}</p>
</div>
<div className="col-md-9 calc ">

<p className="calc-title">
{t("awafiPoints.calc-title")}
</p>

<label className="calcLbl">{t("awafiPoints.enter")}</label>
<div className="calc-body"> 
<label>{t("awafiPoints.enter")}</label>
<input className={i18n.language=="ar"?"calcNumber":"calcNumber calcNumberEn"} type="number" placeholder={t("awafiPoints.enter-PH")} value={points} onChange={(e)=>calculate(e)}/>
<p className="equal">=</p>
<input disabled={true} type="number" value={currency}/>
<p className="pointsCurrency">{t("awafiPoints.SAR")}</p>
</div>


</div>
</div>

            </div>
<div className="awafiMembership">
    <p style={{margin:"1em 0"}}  className="membership-title">
    {t("awafiPoints.t1")} </p>
       
        <div className="membership-list">
<p><span>1-</span>   {t("awafiPoints.p1")}</p>
<p><span>2-</span> {t("awafiPoints.p2")}</p>
<p><span>3-</span> {t("awafiPoints.p3")}</p>
<p><span>4-</span>  {t("awafiPoints.p4")}</p>
<p><span>5-</span>  {t("awafiPoints.p5")}</p>

<p><span>6-</span> {t("awafiPoints.p6")}</p>

<p><span>7-</span>  {t("awafiPoints.p7")}</p>

<p><span>8-</span>   {t("awafiPoints.p8")}</p>

<p><span>9-</span> {t("awafiPoints.p9")}</p>

<p><span>10-</span>  {t("awafiPoints.p10")}</p>
<p><span>11-</span>  {t("awafiPoints.p11")}</p>

<p><span>12-</span>  {t("awafiPoints.p12")}
<br/>
- {t("awafiPoints.p12-1")}
<br/>
- {t("awafiPoints.p12-2")}
<br/>
- {t("awafiPoints.p12-3")}
</p>

        </div></div>

<div className="awafiMembership">
    <p style={{margin:"1em 0"}} className="membership-title">
    {t("awafiPoints.t2")}</p>
       
        <div className="membership-list">
<p><span>1-</span>  {t("awafiPoints.p21")}</p>
<p><span>2-</span>  {t("awafiPoints.p22")}</p>

<p><span>3-</span>  {t("awafiPoints.p23")}</p>
<p><span>4-</span>  {t("awafiPoints.p24")}</p>
<p><span>5-</span> {t("awafiPoints.p25")}</p>

<p><span>6-</span>  {t("awafiPoints.p26")}</p>

<p><span>7-</span>   {t("awafiPoints.p27")}</p>

<p><span>8-</span>   {t("awafiPoints.p28")}</p>

<p><span>9-</span>  {t("awafiPoints.p29")}</p>
<p><span>10-</span> 
{t("awafiPoints.p210")}
</p>


        </div></div>


</div>
  
    );
  }
  
  export default AwafiPoints;