 
 
import "../Styles/CompanySales.css";
import { useTranslation } from 'react-i18next';
function AwafiQuestions() {
    const { t, i18n } = useTranslation();
    return (
<div className="awafiQuestions">
    <p className="question-title">
    {t("awafiQ.t1")}  </p>
    <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-1")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-1")}   <br/>
        {t("awafiQ.a1-1-1")}  
        <br/>
        {t("awafiQ.a1-1-2")}  
        <br/>
        {t("awafiQ.a1-1-3")}  
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-2")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-2")} 
        <br/>
        1-   {t("awafiQ.a1-2-1")} 
        <br/>
        2-   {t("awafiQ.a1-2-2")} 
      
     
        </p>
        </div>

        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-3")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-3")} 
     
        </p>
        </div>

        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-4")} 
       
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-4")} 
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-5")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-5")} 
      
        </p>
        </div>

        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q1-6")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a1-6")} 
        </p>
        </div>
        <p className="question-title">
        {t("awafiQ.t2")} </p>
    <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-1")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-1")} 
       
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-2")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-2")} </p>
        </div>

        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-3")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-3")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-4")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-4")}  </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-5")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-5")}
       
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-6")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-6")} 
        <br/>
      <span style={{color:"#E47100"}}>"   {t("awafiQ.a2-6-2")}".</span>
     
         </p>
      
       </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-7")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-7")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-8")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-8")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-9")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-9")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-10")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-10")}
        <br/>
        1-    {t("awafiQ.a2-10-1")}
        <br/>
        2-   {t("awafiQ.a2-10-2")}
        <br/>
        3-     {t("awafiQ.a2-10-3")}
        <br/>
        4-  {t("awafiQ.a2-10-4")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q2-11")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a2-11")}
       
        </p>
        </div>
       
        <p className="question-title">
        {t("awafiQ.t3")} </p>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q3-1")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a3-1")}
        </p>
        </div>
        <div style={{marginBottom:"2em"}}>
        <p className="question">
        {t("awafiQ.q3-2")} 
        </p>
        <p className="question-answer">
        {t("awafiQ.a3-2")}
       
        </p>
        </div>

</div>

  
    );
  }
  
  export default AwafiQuestions;