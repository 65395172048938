
import "../Styles/PointEx.css";
import arrows from "../Images/Arrows.svg"
import { useState,useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {  pointExchange } from '../api'
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { app } from "../Firebase/firebaseConfig";
function PointExchange(props) {
  const [rotate,setRotate]=useState("rotate");
  const { t, i18n } = useTranslation();
  const [msgEn, setMsgEn]=useState();
  const [msgAr, setMsgAr]=useState();
  const [errorMsgModel, setErrorMsgModel]=useState(false);
const history =useNavigate()
  const [phone,setPhone]=useState("");
 
  const analytics = getAnalytics(app);
 

  //fetch user phone for use in analytics and webengage
  useEffect(() => {
    const token= localStorage.getItem("token");
  
  CustomerInfo(token).then((response) => {
  
  setPhone((response.data.data.phone).toString())
  
  })},[])





  //rotates the middle arrow
  //pointExchange api takes user current point balance and token
  
  function handleRotate(){
   
if(rotate=="rotate"){
setRotate("rotate2");}
else {
    setRotate("rotate");  
} 

const token= window.localStorage.getItem('token');

let points =props.pointsBalance.toString();

  pointExchange(props.pointsBalance,token).then((response) => {

    logEvent(analytics, 'awafi_calculator', {
      phone: phone,
      points: points
    });
    /*
    window.webengage.track("Awafi calculator", {
      phone: phone,
      points: points
    })*/
  
      setMsgAr( "تم استبدال النقاط بنجاح وإضافة المبلغ الى محفظتك ، صلاحية النقاط يومين فقط .");

       setMsgEn( "Points have been successfully redeemed to your wallet, valid for two days")
        setErrorMsgModel(true)

}).catch(function (error) {
  if(error.response.status === 401){
    //used to change cart icon's current value to 0

    props.setCartItem(0) 


  localStorage.removeItem("token");
  localStorage.removeItem("userPhone");
  localStorage.removeItem("userName");
  localStorage.removeItem("basket");
  //was used to store user's current step in cart process
  localStorage.removeItem("process");

 history("/login")

  
  
    }
  setMsgAr(error.response.data.error)
  setMsgEn( error.response.data.error)
        setErrorMsgModel(true)

}
)



  }


    return (
      <div className="pExch">
     
    <p className="exCredit">{t("profile.points_credit")}</p>
    <div className="exMid">
<div className="exBox">
<p className="currencyValue">{props.pointsBalance}</p>
    <p className={i18n.language=="ar"?"exCurrency":"exCurrency exCurrencyEn"}>{t("profile.point")}</p>
 
</div>

<img className={rotate} src={arrows}/>
<div className="exBox">
<p className="currencyValue">{parseFloat(props.pointsBalance*props.pointPrice).toFixed(2)}</p>
    <p className={i18n.language=="ar"?"exCurrency":"exCurrency exCurrencyEn"}> {props.currency} </p>

   
</div>
</div>

{i18n.language=="ar"?
<p style={{textAlign:"center"}}className="errMsg">{errorMsgModel? msgAr:null}</p>:
<p style={{textAlign:"center"}} className="errMsg">{errorMsgModel? msgEn:null}</p>}
<button onClick={()=>handleRotate()}>{t("profile.exchange")}</button>
         </div>
    );
  }
  
  export default PointExchange;