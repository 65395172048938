import { useTranslation } from "react-i18next";

export const SubscripedPackage = ({ userSubscription }) => {
  const { t, i18n } = useTranslation();
  let language = i18n.language === "ar" ? "ar" : "en";

  let currencySymbol = "";
  if (userSubscription.package.countryId === 1) {
    currencySymbol = language === "ar" ? "ريال" : "SAR";
  } else if (userSubscription.package.countryId === 2) {
    currencySymbol = language === "ar" ? "دينار بحريني" : "BHD";
  } else {
  }

  return (
    <div class="card-subscription">
      <div class="card-subscription-content">
        <div class="row-sub">
          <label>{t("profile.package")}</label>

          <span className="value">
            {language === "ar"
              ? userSubscription.package.nameAr
              : userSubscription.package.nameEn}
          </span>
        </div>
        <div class="row-sub">
          <label>{t("profile.expireDate")}</label>
          <span className="value">{userSubscription.endAt}</span>
        </div>
        <div class="row-sub">
          <label>{t("profile.price")}</label>
          <span className="value">
            {userSubscription.package.price} {currencySymbol} /{" "}
            {userSubscription.package.durationName.toLowerCase()}
          </span>
        </div>
      </div>
    </div>
  );
};
