 

import "../Styles/UserProfile.css";
import {GoogleMap,useLoadScript,Marker} from "@react-google-maps/api"
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import AddressCard from "../Components/AddressCard";
import { IoAddCircleOutline } from "react-icons/io5";
import Autocomplete from 'react-google-autocomplete';
import { useState ,useEffect} from "react"; 
import * as yup from "yup"
import {useFormik} from "formik";
 import { useMemo ,useRef} from "react";
 import Select from 'react-select'
 import axios from "axios";
import homeLocation from '../Images/homeLocation.svg'
import countryList from 'react-select-country-list'
import { addOrganization, address,branchesNear, countries } from '../api'
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import SuccessModal from "../Components/SuccessModal";
 import { BsSearch } from "react-icons/bs"; 
function ProfileAddInvoice(props) {

    const [modal,setModal]=useState(false)
    const [cityArr,setCityArr]=useState([])
    const [loading,setLoading]=useState(true)

const history=useNavigate();

useEffect(() => {
  const token= localStorage.getItem("token");

  setLoading(true);


  countries().then(response => {

    setCityArr(response.data.data[props.country].cities)
      setLoading(false)
    })



 },[props.country]);

let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
let numRegEx= /^[0-9]+$/;
let taxReg= /^(3)[0-9]{13}(3)$/;
let postReg= /^[0-9]{5}$/;

const Schema= yup.object().shape({
  fullName: yup.string().required(("validation.name_required")),
  phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,{message:("validation.phone_not_valid")}),

    email: yup.string().required(("validation.email_required")).matches(emailRegx,{message:("validation.invalid_email")}),  
    taxNumber: yup.string().required(("validation.tax_required")).matches(taxReg,{message:("validation.ivalidTax")}),
  
  commericalRegisterNo: yup.string().required(("validation.commerical_required")).matches(numRegEx,{message:("validation.phone_not_valid")}),
  
  organizationName: yup.string().required(("validation.organization_required")),
  cityId: yup.string().required(("validation.city_required")),

  streetName: yup.string().required(("validation.street_required")),

  postalCode: yup.string().matches(postReg,("validation.postalMin")),

  address: yup.string().required(("validation.address_required")),

  })

const formik = useFormik({
  initialValues:{
    fullName:localStorage.getItem("userName"),
    phone:localStorage.getItem("userPhone"),
    email:"",
    taxNumber:"",
    commericalRegisterNo:"",
    organizationName:"",
    cityId:"",

    streetName:"",

    postalCode:"",

    address:"",

  },
  validationSchema: Schema,
  onSubmit,
  
  });



    const [submitLoading,setSubmitLoading]= useState(false)
     const { t, i18n } = useTranslation();



     //submits values (some values depend on selected country)
 //address api call saves the address then SuccessModal is displayed then ProfileAddAddress component is hidden (  props.setAddAddress(false))

  function onSubmit(e){
    setSubmitLoading(true)
const token= localStorage.getItem("token");
/*if(!value){
    setEmpty(true)
    setSubmitLoading(false)
  }*/

  let values = {
    fullName: formik.values.fullName,
    phone:  formik.values.phone,
    email:  formik.values.email,
    taxNumber:  formik.values.taxNumber,
    commericalRegisterNo: formik.values.commericalRegisterNo,
    organizationName:formik.values.organizationName,
    cityId: formik.values.cityId,
    streetName: formik.values.streetName,
    postalCode: formik.values.postalCode,
    address: formik.values.address

 };


    addOrganization(token,values).then((response) => {

    setSubmitLoading(false)
    setModal(true);

    setTimeout(function() {
      
      props.setAddInvoice(false)
    }, 2000)
  
  
}).catch((err)=>{
  if(err.response.status === 401){
    //used to change cart icon's current value to 0

    props.setCartItem(0) 


  localStorage.removeItem("token");
  localStorage.removeItem("userPhone");
  localStorage.removeItem("userName");
  localStorage.removeItem("basket");
  //was used to store user's current step in cart process
  localStorage.removeItem("process");

 history("/login")

  
  
    }
  setSubmitLoading(false)
})
   }
  
/*
  function handleChange(e){
    console.log(e.target.value)
    if(e.target.value){
      setEmpty2(false)
    }
    else{
      setEmpty2(true)
    }
  }
*/

 

    return (
      <>
      {modal?<SuccessModal type='invoice' setModal={setModal}/>:null}

   
<div className="aboutusBody profileBody container-fluid fluid">
<div className="upAddress">
    <p  className="upTable-title">  {t("profile.organization")}</p>
   
     
    </div>
<div className="row addressInput userAddAddress">
    <div style={{textAlign:"center"}}>
<p className="addressTitle">  {t("profile.addTax")}</p></div>

        <div className="col-12">
        { submitLoading?
        <div style={{alignItems:"initial", height:"30px"}} className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} />
      
      </div>:
        <form onSubmit={formik.handleSubmit}>
        <div className="row gy-4">
           
        <div className="col-6">
        <label>  {t("profile.name")}</label>
        <input placeholder= {t("profile.name")}  value={formik.values.fullName} id="fullName" name="fullName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fullName && formik.touched.fullName?"invalid":null}/>
        <span>{formik.errors.fullName && formik.touched.fullName?t(`${formik.errors.fullName}`):null}</span>
      
        </div>
      
    

        <div className="col-6">
        <label>{t("profile.phone")}</label>
        <input placeholder= {t("profile.phone")} value={formik.values.phone} id="phone" name="phone"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone?"invalid":null}/>
        <span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>

</div>

<div className="col-6">
        <label>{t("profile.email")}</label>
        <input placeholder= {t("profile.email")} value={formik.values.email} id="email" name="email"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email?"invalid":null}/>
        <span>{formik.errors.email && formik.touched.email?t(`${formik.errors.email}`):null}</span>

</div>

<div className="col-6">
        <label>{t("profile.taxNumber")}</label>
        <input placeholder= {t("profile.taxNumber")}  value={formik.values.taxNumber} id="taxNumber" name="taxNumber"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.taxNumber && formik.touched.taxNumber?"invalid":null}/>
        <span>{formik.errors.taxNumber && formik.touched.taxNumber?t(`${formik.errors.taxNumber}`):null}</span>

</div>

<div className="col-6">
        <label>{t("profile.commercial")}</label>
        <input placeholder= {t("profile.commercial")}   value={formik.values.commericalRegisterNo} id="commericalRegisterNo" name="commericalRegisterNo"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.commericalRegisterNo && formik.touched.commericalRegisterNo?"invalid":null}/>
        <span>{formik.errors.commericalRegisterNo && formik.touched.commericalRegisterNo?t(`${formik.errors.commericalRegisterNo}`):null}</span>

</div>
<div className="col-6">
        <label>{t("profile.city")}</label>
       <select  value={formik.values.cityId} id="cityId" name="cityId"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.cityId && formik.touched.cityId?"invalid selectCity":"selectCity"}>
       <option value="" disabled> {t("branches.city")}</option>

{cityArr && cityArr.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 
</select>       
       <span>{formik.errors.cityId && formik.touched.cityId?t(`${formik.errors.cityId}`):null}</span>

</div>
<div className="col-6">
        <label>{t("profile.orgName")}</label>
        <input placeholder= {t("profile.orgName")}  value={formik.values.organizationName} id="organizationName" name="organizationName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.organizationName && formik.touched.organizationName?"invalid":null}/>
        <span>{formik.errors.organizationName && formik.touched.organizationName?t(`${formik.errors.organizationName}`):null}</span>

</div>
<div className="col-6">
        <label>{t("profile.street")}</label>
        <input placeholder= {t("profile.street")}  value={formik.values.streetName} id="streetName" name="streetName"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.streetName && formik.touched.streetName?"invalid":null}/>
        <span>{formik.errors.streetName && formik.touched.streetName?t(`${formik.errors.streetName}`):null}</span>

</div>
<div className="col-6">
        <label>{t("profile.postal")}</label>
        <input placeholder= {t("profile.postal")}  value={formik.values.postalCode} id="postalCode" name="postalCode"  required={false} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.postalCode && formik.touched.postalCode?"invalid":null}/>
        <span>{formik.errors.postalCode && formik.touched.postalCode?t(`${formik.errors.postalCode}`):null}</span>

</div>

        <div className="col-12">
        <label> {t("profile.organizationAddress")}</label>
        
        <input placeholder= {t("profile.organizationAddress")}  value={formik.values.address} id="address" name="address"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.address && formik.touched.address?"invalid addressNotes":"addressNotes"}/>
        <span>{formik.errors.address && formik.touched.address?t(`${formik.errors.address}`):null}</span>

        </div>
        <div className="col-12">
        { submitLoading?
        <div style={{alignItems:"initial", height:"30px"}} className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} />
      
      </div>:
   <>
  
      <button  type="submit" >{t("profile.addTax")}</button>
      
      
  
     </> 
        
        }</div>

     
        </div>
        
        </form>}
        </div>
         
          
     
        </div> 
</div>
</>
  
    );
  }
  
  export default ProfileAddInvoice;