export const PackageCard = ({ packageItem, isUserSubscribed, onSelect, language }) => {
  let currencySymbol = '';
  if (packageItem.countryId === 1) {
    currencySymbol = language === 'ar' ? 'ريال' : 'SAR';
  } else if (packageItem.countryId === 2) {
    currencySymbol = language === 'ar' ? 'دينار بحريني' : 'BHD';
  } else {
  }

  return (
    <div className="card" key={packageItem.id}>
      {!isUserSubscribed && (
        <input
          type="radio"
          id={`${packageItem.nameEn}-package`}
          name="package"
          className="card-radio"
          onChange={() => onSelect(packageItem)}
        />
      )}
      <label htmlFor={`${packageItem.nameEn}-package`} className="card-content">
        <div style={{ 'display': 'flex' }} >
          {!isUserSubscribed && (<span style={{ 'margin': '5px' }} className="radio-button"></span>)}
          <div className="card-title">{language === "ar" ? packageItem.nameAr : packageItem.nameEn}</div>
        </div>

        <div className="card-body">
          <p>{language === "ar" ? packageItem.descriptionAr : packageItem.descriptionEn}</p>
          {/* Display the price with currency symbol */}
          <span className="price">{packageItem.price} {currencySymbol} / {packageItem.durationName}</span>
        </div>
      </label>
    </div>
  );
};
