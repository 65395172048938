 
import "../Styles/ClientCare.css";

import Footer from "../Components/Footer";
 
import { Link } from "react-router-dom";
import client from "../Images/clientCare/client-care.svg"
import c1 from "../Images/clientCare/c2.svg"
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";

import { AiOutlinePaperClip } from "react-icons/ai";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation ,Autoplay} from "swiper";

function ClientCare() {
  const [nav,setNav]=useState(0);
  const { t, i18n } = useTranslation();

 
    return (
        <div >
        
          <div className="client-care container-fluid fluid">

<p  className="cc-header">{t("clientCare.title")}
    </p>
    <div>

<p className="cc-phone">
{t("clientCare.phone")} <span>0502299281</span>
    </p>


    </div>
 
</div>

<div>
<Swiper
                  dir={i18n.language=="ar"?"rtl":"ltr"}
                 style={{
                
                    "--swiper-pagination-color": "#E47100",
                   "border-radius" :"20px"
                  }}
pagination={{
    clickable: true,

  
}}
key={i18n.language}
navigation={true}

loop={true}
breakpoints={{
 
  0: {
    slidesPerView: 3,
    spaceBetween: 0,
  },
    581: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    695: {
        slidesPerView: 5,
        spaceBetween: 0,
      },
    925: {
      slidesPerView: 6,
      spaceBetween: 0,
    },
    1015: {
      slidesPerView: 7,
      spaceBetween: 0,
    },
    1160: {
      slidesPerView: 8,
      spaceBetween: 0,
    },
  }}  

        
modules={[Pagination, Navigation,Autoplay]}
className={i18n.language=="ar"?"complaintsCrsl CrslAr":"complaintsCrsl CrslEn"}
>



<SwiperSlide >  

<div  className='complaint' >
<img src={c1}/>
<p>تأخير في المحلي</p>
</div>
 
     </SwiperSlide>
  

     <SwiperSlide >  

<div  className='complaint' >
<img src={c1}/>
<p>تأخير في المحلي</p>
</div>
 
     </SwiperSlide>
  

<SwiperSlide >  

<div  className='complaint' >
<img src={c1}/>
<p>تأخير في المحلي</p>
</div>
 
     </SwiperSlide>
  

<SwiperSlide >  
<div>
<div  className='complaint' >
<img src={c1}/>
<p>تأخير في المحلي</p>
</div>
</div>
     </SwiperSlide>
  
    
 

</Swiper>
</div>

<div className="cc-body container-fluid fluid">
<div className="row gy-5 careForm">


   
    <div  className="col-md-6 cc-form">
 
<label>{t("clientCare.name")}</label>
<input placeholder={t("clientCare.enter_name")}/>
<label>{t("clientCare.complaint")}</label>
<div style={{position:"relative"}}>
<textarea className="complaintData"/>
<div style={i18n.language=="ar"?{left:"0",right:"auto"}:null} className="file">
    <div>
 
<label   for="files">{t("clientCare.upload")}</label>
<button> <AiOutlinePaperClip/></button>
</div>
<input id="files" type="file" className="complaintImg" />
</div>
</div>
<div className="cc-alignBTn">
<button>{t("clientCare.send")}</button>

</div>

    </div>
    <div className="col-md-6 order-first order-md-last"> 

<div  className='cc-image' style={{ backgroundImage: `url(${client})` }}>

</div>
</div>
</div>



</div>

<Footer/>
      </div>
       
    );
  }
  
  export default ClientCare;
