 /*user is redirected to this screen from either login or 
 registration where an api was used to send user an otp*/
import '../Styles/ChangPass.css';
import mobile from "../Images/Login/mobile.svg"

import changePass from "../Images/Login/changePass.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";
import {CustomerInfo2,otp,SMS} from "../api";

import { BeatLoader } from 'react-spinners';
import Navbar from "../Components/Navbar";
import { useTranslation } from 'react-i18next'; 
import Footer from "../Components/Footer";
 
function Verify() {

let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [userPhone,setUserPhone]=useState(JSON.stringify(localStorage.getItem("userPhone")));
  const [loggedIn,setLoggedIn]=useState(false);
      const [otpVerify,setOtp]=useState("");
      const [loading,setLoading]=useState(false);
      const [errorMsgModel2, setErrorMsgModel2]=useState(false); //error shows under resend div
  
      const [seconds,setSeconds]=useState(59);
      const [showSec,setShowSec]=useState(false);

      const [msgAr, setMsgAr]=useState();
      const [msgEn, setMsgEn]=useState();
      
      const [errorMsgModel, setErrorMsgModel]=useState(false); //error shows under input field
  

     const handleChange = (otpVerify) =>{ setOtp( otpVerify );
        console.log(otpVerify);

     }  


    
     useEffect(() => {
      localStorage.setItem("prevScreen","redirect");  //for use in loggin (if prevScreen = redirect logging in redirects to home screen instead of previous screen)
    
     },[]);


/*(takes the otp code) and if it is correct user information is 
fetched and then user is redirected to home screen*/
     function handleSubmit(e){
        e.preventDefault();
        setLoading(true)
      const  data = {
          phoneVerificationCode: otpVerify,
      } 

      const token =   localStorage.getItem("token");

otp(data,token).then((response) => {
        if (response.data.message === "success") {
          localStorage.setItem("verified",JSON.stringify(true))
          console.log(response);
            localStorage.setItem("token",token);
            CustomerInfo2(token).then((response) => {
              localStorage.setItem("userName",response.data.data.name)
history("/");
console.log(response);
           })
          
            }

            else {

                 
                setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                setErrorMsgModel(true);
                setErrorMsgModel2(false);
                setLoading(false)
                console.log(response);
          }


      }).catch(function (error) {
        setMsgAr("الكود غير صحيح")
                setMsgEn("Invalid code");
                
                setErrorMsgModel(true);
                setErrorMsgModel2(false);
                setLoading(false)
            
   
          })

      

      }

//for resending otp code
      function resend(){
        setOtp( null )
        setLoading(true)
        const token =   localStorage.getItem("token");
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json'
      };

        SMS(headers).then((response) => {
         
                setMsgAr("تم إرسال الكود");
                setMsgEn("Your code has been send");
                setErrorMsgModel(false);
                setErrorMsgModel2(true);
               
                setShowSec(true)
                setLoading(false)
                let second= 59;
                setInterval(()=>{ //disables resend button and shows timer
                
                  if(second>0){
                   second=second-1
                   setSeconds(second)
                   setShowSec(true)
               
              
                
               }
                  else{
                   setErrorMsgModel2(false);
                    setShowSec(false)
                 
                  }
             
                },1000)
        }).catch(function (error) {
            // console.error(error);

        });
    };
 
   return (
        <div>
          
        
         <div className='loginContainer container-fluid fluid'>
            
<div style={{padding:"3.5em 2em",position:"relative"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null} className='row'>
<div className='col-md-6 passAlign'>
<img className='otpImage' src={mobile}/>
<p className='forgot'>      {t("Login.checkPhone")}</p>

<p className='otpPhone'>{userPhone}</p>
<form className='otpForm'  onSubmit={(e)=>handleSubmit(e)}>
    <div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
  <label>  {t("Login.EnterVerify")} </label>
  <div id="otp" className={i18n.language=="en"? "alignOtp":null}>
      {/*
  <input type="text" maxLength={1} style={{marginRight:"0"}}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
  <input type="text" maxLength={1}/>
   <input type="text" maxLength={1} />
   */}
    <OtpInput
     
     value={otpVerify}
     onChange={(otpVerify)=>handleChange(otpVerify)}
        numInputs={6} 
       
      />
  </div></div> 
  <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
 {i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr:null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}

 </div>
<button type="submit">{t("Login.confirm")}</button>
</form>
<div style={{textAlign:"left"}}> 
<p className='resendChangePass'>{t("Login.notRecive")}  <span className='resendSpan' style={showSec?{cursor:"default",color:"#4F4F4F"}:null} onClick={ !showSec? resend:null}> {t("Login.resend")}</span> <span style={{fontSize:"16px"}}>{showSec? `00:${seconds}`:null} </span></p></div>
{i18n.language=="ar"?
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgAr:null}</span>:
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgEn:null}</span>}
</div>

<div className={i18n.language=="ar"?'col-md-6 passPhoneImage':"col-md-6 passPhoneImageEn"}>
  
<img src={changePass}/>

</div>
</div>
</div>
</div>
<Footer/>
</div>
    );
}

export default Verify;