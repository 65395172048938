import "../Styles/SingleProduct.css";
import sp from "../Images/SingleProduct.svg";
import Footer from "../Components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiShoppingCart } from "react-icons/hi";
import Recommendations from "../Components/Recommendations";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { single_product, Add_to_cart } from "../api";
import { BeatLoader } from "react-spinners";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CustomerInfo, categories } from "../api";
import MealNotAvail from "../Components/MealNotAvail";
import { CategoryContext } from "../Context/CategoryContext";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { app } from "../Firebase/firebaseConfig";
import { BackgroundImage } from "react-image-and-background-image-fade";

function SingleProduct(props) {
  const history = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [meal, setMeal] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { categ, setCateg } = useContext(CategoryContext);
  const [activeCategory, setActiveCategory] = useState(
    localStorage.getItem("activeCateg")
      ? localStorage.getItem("activeCateg")
      : categ
  ); //used uceContext so when user returns from a single prodcut screen the category isn't changed back to first category
  const [category, setCategory] = useState([]);
  const [size, setSize] = useState(0);
  const [noSizes, setNoSizes] = useState(false);
  const [noSizesMsg, setNoSizesMsg] = useState("");
  const [sizeErr, setSizeErr] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalCal, setTotalCal] = useState(0);
  const [extras, setExtras] = useState([]);
  const [additionalsRequired, setAdditionalsRequired] = useState();
  const [additionals, setAdditionals] = useState();
  const [chosenSize, setChosenSize] = useState();
  const [chosenAddition, setChosenAddition] = useState(null);
  const [chosenExtras, setChosenExtras] = useState([]);
  const [reommend, setreommend] = useState([]);
  const [quan, setQuan] = useState(1);
  const [phone, setPhone] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);
  const analytics = getAnalytics(app);
  const [disabled, setDisabled] = useState(false);

  const [additionalIndex, setAdditionalIndex] = useState(0);
  var addressId = "";

  var language = localStorage.getItem("i18nextLng");
  const data = {

  }

  //single_producxt api call takes meal id from url and branchId (set when user chose order type) and gets meal data
  //fetch customer data for analytics and webengage use
  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");

    categories(data,language).then((response) => {
      const data = response.data.data;

      setCategory(data);
    });

   
  }, [location, language,loading]);

  useEffect(()=> {
    const token = localStorage.getItem("token");
    single_product(id, localStorage.getItem("branchId"), token, language)
    .then((response) => {
      const data = response.data.data;

      setDisabled(
        data.sizes[0].isAdditionalRequired
          ? !data.sizes[0].additionals[0].canAddToCart
          : !data.sizes[0].canAddToCart
      );
      setMeal(data);
      setAdditionals(data.sizes[0].additionals);
      setChosenAddition(data.sizes[0].additionals[0]);
     
      // localStorage.setItem("ChosenAddition",JSON.stringify(meal.sizes[0].additionals[0]) )

      setChosenSize(data.sizes[0]);
      setExtras(data.extras);

      setAdditionalsRequired(data.sizes[0].isAdditionalRequired);

      if (data.sizes[0].isAdditionalRequired) {
        setTotal(data.sizes[0].additionals[0].price);
        setTotalCal(data.sizes[0].additionals[0].calories);
        setAdditionalIndex(0);
      } else {
        setTotal(data.sizes[0].price);
        setTotalCal(data.sizes[0].calories);
        setAdditionalIndex(null);
      }

      logEvent(analytics, "view_item", {
        item_id: data.id.toString(),

        item_name: data.enName,
      });

      if (token) {
        CustomerInfo(token).then((response) => {
          setPhone(response.data.data.phone.toString());

          logEvent(analytics, "watch_meal", {
            phone: response.data.data.phone.toString(),

            name: data.enName,
          });
          /*
      window.webengage.track("Watch meal", {
        phone: response.data.data.phone.toString(),
      })*/
        });
      }

      setLoading(false);
    })
    .catch((err) => {
      console.log(err)
      if (err.response.status === 401) {
        history("/login");
      } else {
        setNoSizes(true);
        setNoSizesMsg(err.response.data.error);
      }
    });
  }, [loading, language])


  //on changing additional total price and calories are updatded
  function calc(calories, price, index) {
    var tot = total;
    tot = price;
    setTotal(tot);

    var totCal = totalCal;
    totCal = calories;
    setTotalCal(totCal);
    setAdditionalIndex(index);
    setDisabled(!chosenSize.additionals[index].canAddToCart);
    setChosenAddition(chosenSize.additionals[index]);
  }

  //on changing size (for products that don't have additionals) total price and calories are updatded
  function Totals(calories, price, data, index) {
    setAdditionals(data.additionals);
    setAdditionalsRequired(data.isAdditionalRequired);
    setSize(index);
    setTotal(price);
    setTotalCal(calories);
    setChosenSize(data);
  }

  //on changing size (for products that have additionals) total price, calories, displayed additionals and displayed extras are updatded
  //resets chosen addition to first choice in additionals array

  
  function handleChange(index) {
    
    var elements = document.getElementsByClassName("addition");

    setSize(index);
    setAdditionals(meal.sizes[index].additionals);
   

    setChosenSize(meal.sizes[index]);

    setAdditionalsRequired(meal.sizes[index].isAdditionalRequired);
    setExtras(meal.extras);
    
    if (meal.sizes[index].isAdditionalRequired) {
     
    
      setTotal(meal.sizes[index].additionals[0].price);
      setTotalCal(meal.sizes[index].additionals[0].calories);
      // localStorage.setItem("ChosenAddition",JSON.stringify(meal.sizes[index].additionals[0]) )

      
      if(elements.length ==0) {
        setChosenAddition(meal.sizes[index].additionals[0]);

      }
      for (var i = 0; i < elements.length; i++) {
        
        if (i == 0) {
          elements[i].checked = true;
          setAdditionalIndex(0);
          setChosenAddition(meal.sizes[index].additionals[0]);
        }
      }
      setDisabled(!meal.sizes[index].additionals[0].canAddToCart);
    } else {
      setTotal(meal.sizes[index].price);
      setTotalCal(meal.sizes[index].calories);
      for (var i = 0; i < elements.length; i++) {
        if (i == 0) {
          elements[i].checked = false;
        }
      }
      setAdditionalIndex(null);
      setChosenAddition(null);
      setDisabled(!meal.sizes[index].canAddToCart);
    }
  }

  //on changing extras loop through extras and push checked items only to array
  function handleExtras() {
    var checkboxes = document.getElementsByClassName("extras");
    var arr = [];

    for (var i = 0, max = checkboxes.length; i < max; i += 1) {
      if (checkboxes[i].type === "checkbox" && checkboxes[i].checked) {
        arr.push(parseInt(checkboxes[i].value));
      }
    }
    setChosenExtras(arr);
  }

  //on clicking on item from recommendations id in url is changed and screen is rerendered
  function changePath(id) {
    history(generatePath("/meals/:id", { id }));
  }

  //on clicking aff to cart if user is not logged in they will be redirected to /login
  //  Add_to_cart api call takes data and user token
  //user is then redirected to /menu (MainMenu component found in /Pages)
  function handleAdd() {
    const token = window.localStorage.getItem("token");

    if (token) {
      if (localStorage.getItem("typeId") == 2) {
        addressId = localStorage.getItem("addressId");
      }
      const chosenAdditionLocal =JSON.parse(localStorage.getItem("ChosenAddition"))


      console.log(chosenAddition)
    
      let data = {
        branchId: parseInt(localStorage.getItem("branchId")),
        typeId: parseInt(localStorage.getItem("typeId")),
        addressId: addressId,

        appVersion: "1.0.0",
        productId: meal.id,
        quantity: quan,
        sizeId: chosenSize.id,
        additionalId: chosenAddition ? chosenAddition.id : null,
        extras: chosenExtras,
      };
      if(addressId == null|| addressId == "") {
        delete data.addressId
      }
      if(chosenAddition == null|| chosenAddition== "") {
        delete data.additionalId
      }


      if(addressId == null|| addressId == "") {
        delete data.addressId
      }
      if(chosenAddition == null|| chosenAddition== "") {
        delete data.additionalId
      }



      Add_to_cart(data, token)
        .then((response) => {
          setShowingAlert(true);

          props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart
          localStorage.removeItem("paymentMethod");
          localStorage.removeItem("promo"); //remove value of promo code
          localStorage.removeItem("offer");
          localStorage.removeItem("ChosenAddition")
          logEvent(analytics, "add_to_cart", {
            phone: phone,
            quantity: parseInt(quan),
            name: meal.enName,
          });

          const params = {
            currency: meal.enCurrency,
            item_name: meal.enName,
            item_id: meal.id.toString(),
            price: total.toString(),
            quantity: quan.toString(),
          };

          logEvent(analytics, "add_to_cart", params);
          /*

      window.webengage.track("Add to cart", {
        phone: phone,
        quantity:parseInt(quan),
        name: meal.enName
      })
  */
          setTimeout(function () {
            history("/menu");
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else {
            setNoSizesMsg(
              i18n.language == "ar"
                ? "هذه الوجبة غير متاحة"
                : "This meal is not available"
            );
            setNoSizes(true);
            /*  setTimeout(function() {
      window.location.reload();
     
    }, 1200)*/
          }
        });
    } else {
      history("/login");
    }
  }

  function changeCateg(id) {
    setCateg(id);
    localStorage.setItem("activeCateg", id);

    history("/menu");
  }

  function handleUncheck(index) {
    let radio = document.getElementsByName("mealAdditional");

    if (additionalIndex == index && radio[index].checked) {
      radio[index].checked = false;
      setAdditionalIndex(null);

      setTotal(meal.sizes[size].price);
      setTotalCal(meal.sizes[size].calories);
      setChosenAddition(null);

      /*
      setSelectedAdditionalIndex(null)
    setPrice(props.data.sizes[size].price)
    setCalories(props.data.sizes[size].calories)
      setAvailableQuan( props.data.sizes[size].quantity)
      setQuantity(1)
      setSelectedAdditional(null)
      setDisableButton(!(props.data.sizes[size].quantity>=1))
       */
    }
  }

  return (
    <div>
      {noSizes ? <MealNotAvail noSizesMsg={noSizesMsg} /> : null}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          <div
            style={{ marginTop: "2em" }}
            className="spNav container-fluid fluid"
          >
            <p className="spNav">
              <Link to="/menu">{t("meals.menu")}</Link> <span> /</span>{" "}
              {meal.category.name && meal.category.name} <span> /</span>{" "}
              <span> {meal.name && meal.name} </span>
            </p>
          </div>
          <div className=" container-fluid fluid ">
            <div style={{ marginBottom: "2em" }} className="spCategories">
              <div
                className={
                  i18n.language == "ar"
                    ? "mmSideNav "
                    : "mmSideNavEn mmSideNavEn-mobile "
                }
              >
                <p className="mmsideNav-title">{t("party.categories")}</p>
                <div className="categoryScroll">
                  {category &&
                    category.map((data, index) => (
                      <div
                        onClick={() => changeCateg(data.id)}
                        key={index}
                        className={
                          activeCategory == data.id
                            ? "mmsideNav-link mmsideNav-linkActive"
                            : "mmsideNav-link"
                        }
                      >
                        <img src={data.image} alt="chicken" />
                        <p>
                          {data.name&&data.name}{" "}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6  spImage">
                <div
                  className="pImage"
                  style={{ backgroundImage: `url(${meal.image})` }}
                >
                  <div className="spDetailContainer">
                    <div className="spDetail">
                      <div>
                        <p className="sp-p2">
                          {meal.name&&meal.name}{" "}
                        </p>
                        <p className="sp-p3">
                          {i18n.language == "ar"
                            ? meal.arDescription
                            : meal.enDescription}{" "}
                        </p>
                      </div>
                      <p className="sp-p1">
                        {total * quan + " "}
                        {i18n.language == "ar"
                          ? meal.arCurrency
                          : meal.enCurrency}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 spDetails">
                <div className="spData">
                  <div>
                    {" "}
                    <p>{t("meals.sizes")}</p>{" "}
                  </div>
                  <div className="row gy-4 ">
                    {meal.sizes.map((data, index) =>
                      data.isAdditionalRequired ? (
                        <div className="col-md-3   col-sm-4 col-6" key={index}>
                          <input
                            onChange={() => handleChange(index)}
                            type="radio"
                            id="html1"
                            name="option"
                            value="HTML"
                            defaultChecked={index === 0}
                          />

                          <label htmlFor="html">
                            {data.name&&data.name}
                          </label>
                        </div>
                      ) : (
                        <div className="col-md-3   col-sm-4 col-6" key={index}>
                          <input
                            onChange={() => handleChange(index)}
                            type="radio"
                            id="html1"
                            name="option"
                            value="HTML"
                            defaultChecked={index === 0}
                          />

                          <label htmlFor="html">
                            {data.name&&data.name}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>

                {!additionals.length == 0 ? (
                  <div className="spData">
                    {" "}
                    <p>{t("meals.additions")}</p>
                    <div className="row">
                      {additionals.map((data, index) => (
                        <div className="col-12" key={index}>
                          <div className="additionals">
                            <div>
                              <input
                                onClick={
                                  additionalsRequired
                                    ? null
                                    : () => handleUncheck(index)
                                }
                                onChange={(e) =>
                                  calc(data.calories, data.price, index)
                                }
                                className="checkBox addition"
                                type="radio"
                                id="html1"
                                name="mealAdditional"
                                value={data.arName}
                                defaultChecked={
                                  additionalsRequired ? index === 0 : null
                                }
                              />

                              <label htmlFor="html">
                                {i18n.language == "ar"
                                  ? data.arName
                                  : data.enName}
                              </label>
                            </div>

                            <p>
                              {" "}
                              {data.price}{" "}
                              {i18n.language == "ar"
                                ? meal.arCurrency
                                : meal.enCurrency}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {!extras.length == 0 ? (
                  <div className="spData">
                    <div>
                      {" "}
                      <p>{t("meals.extras")}</p>{" "}
                    </div>

                    <div className="row">
                      {extras.map((data, index) => (
                        <div className="col-md-3   col-sm-4 col-6" key={index}>
                          <input
                            onChange={() => handleExtras()}
                            value={data.id}
                            className=" additions extras"
                            type="checkbox"
                            id="html1"
                          />

                          <label htmlFor="html">
                            {data.name&&data.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div className="spData  calories">
                  <div>
                    <p>{t("meals.calories1")}</p>
                  </div>
                  <div>
                    <p className="totalCal">
                      {totalCal + "  "} {t("meals.calories2")}
                    </p>
                  </div>
                  <div></div>
                </div>
                <p className="btnTitle">{t("meals.quantity")}</p>
                <div className="spButtons" style={{ position: "relative" }}>
                  <div
                    className={
                      i18n.language == "ar"
                        ? "spQuantity"
                        : "spQuantity spQuantityEn"
                    }
                  >
                    <div>
                      <button
                        onClick={() => setQuan(quan + 1)}
                        className="spQuantity-btn1"
                      >
                        +
                      </button>
                      <p>{quan}</p>
                      <button
                        onClick={() => (quan !== 1 ? setQuan(quan - 1) : null)}
                        className="spQuantity-btn2"
                      >
                        -
                      </button>
                    </div>
                  </div>

                  <div
                    className={
                      showingAlert ? "addAlert" : " addAlert alert-hidden"
                    }
                  >
                    <div className="row">
                      <div className="col">
                        <div
                          className="alertImg"
                          style={{ backgroundImage: `url(${meal.image})` }}
                        ></div>
                      </div>

                      <div className="col">
                        <div
                          className={
                            i18n.language == "ar"
                              ? "vAlignText"
                              : "vAlignText enAlert"
                          }
                        >
                          <p>
                            {t("meals.added1") + " "}
                            <span>
                              {i18n.language == "ar"
                                ? meal.arName
                                : meal.enName}
                            </span>
                          </p>
                          <p>{t("meals.added2")}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    disabled={disabled}
                    onClick={handleAdd}
                    className="spAdd"
                  >
                    {t("meals.button")}
                    <span>
                      {total * quan + " "}{" "}
                      {i18n.language == "ar"
                        ? meal.arCurrency
                        : meal.enCurrency}{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "5em" }}></div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default SingleProduct;
