   


import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { getAnalytics, logEvent } from "firebase/analytics";

import { app } from "../Firebase/firebaseConfig";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cancel_request,cancel_direct} from "../api";

//this component displays different html according to the order cancellation type (from OrderTracking Component found in /Pages)
function CancelReason(props) {

const { t, i18n } = useTranslation();
const history=useNavigate();

const [selectedOption,setSelectedOption]=useState(t("process.cancelReason1"));
const [cancelled,setCancelled]=useState(false);
const analytics = getAnalytics(app);
const [msgEn, setMsgEn]=useState();
const [msgAr, setMsgAr]=useState();

//stores current selected cancellation reason
const handleOptionChange = (changeEvent) => {
setSelectedOption( changeEvent.target.value);
console.log(changeEvent.target.value)

    }


  //depending on cancellation type
  /*if direct cancellation: takes order id and token, shows confirmation message then redirects 
  user to his orders in his profile => /profile/myOrders */

   /*if not direct cancellation: cancel_request api call takes an object (string contains order id and cancellation reason) and token, 
   shows confirmation message then redirects user to his orders in his profile => /profile/myOrders */
  
function cancel(){
  console.log(props.products)


  let arr=[]

  props.products.map((data)=>
arr.push(

{
name: data.sizeEnName,
quantity: data.quantity,


}

))

    if(props.orderData.orderCancellationType=="direct"){
    
      cancel_direct(props.orderData.id,localStorage.getItem("token") ).then((response) => {
    

        setMsgAr( "تم الغاء الطلب");
        setMsgEn( "Order has been canceled");
      
  
     logEvent(analytics, 'cancel_order', {
      phone: props.phone,
      products: JSON.stringify(arr)
    });

/*
    window.webengage.track("Cancel Order", {
      phone: props.phone,
      products: props.products
    })*/

     setCancelled(true)
     setTimeout(function() {
     
     history("/profile/myOrders");
     }, 2000)

 }).catch(function (err) {
  if(err.response.status === 401){
    //used to change cart icon's current value to 0

    props.setCartItem(0) 


  localStorage.removeItem("token");
  localStorage.removeItem("userPhone");
  localStorage.removeItem("userName");
  localStorage.removeItem("basket");
  //was used to store user's current step in cart process
  localStorage.removeItem("process");

 history("/login")

  
  
    }
});
 
 
 ;
      }

      else{
        let message = {
          msg: t("process.please")+ props.orderData.id +" "+ t("process.because") + "  " + selectedOption
      }

           cancel_request(message,localStorage.getItem("token") ).then((response) => {
    

          
           
              setMsgAr( "برجاء الإنتظار, جاري تنفيذ طلبك من قبل احد ممثلي خدمة العملاء");
              setMsgEn( "Please wait, your request is being processed by a customer service representative");
          
            
          

           logEvent(analytics, 'cancel_order', {
            phone: props.phone,
            products: JSON.stringify(arr)
          });
/*
          window.webengage.track("Cancel Order", {
            phone: props.phone,
            products: props.products
          })*/
           setCancelled(true)
           setTimeout(function() {
           
           history("/profile/myOrders");
           }, 2000)

       }).catch(function (err) {
        if(err.response.status === 401){
          //used to change cart icon's current value to 0
    
          props.setCartItem(0) 
  
    
        localStorage.removeItem("token");
        localStorage.removeItem("userPhone");
        localStorage.removeItem("userName");
        localStorage.removeItem("basket");
        //was used to store user's current step in cart process
        localStorage.removeItem("process");
    
       history("/login")
     
        
        
          }
      });}
}
 
 

    return (
 

        <div  className="foodModal">
         
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw ", maxWidth:"400px",minWidth:"337px",paddingTop:"2em"}}>
          

          {cancelled?<p className='orderCancelled'>{i18n.language=="ar"?msgAr:msgEn}</p>:


props.orderData.orderCancellationType=="direct"?

<>
<p style={{textAlign:"center"}} className='orderCancelled'>{t("process.cancel")}</p>


  
<div className='modalBtns' style={{textAlign:"center"}}>
<button className='confirm' onClick={cancel}>     {t("Login.confirm")}</button>
<button  onClick={()=>props.setModal(false)}  className='cancel'>     {t("profile.cancel")}</button></div>
</>
:

            <div >
 
              
<p className='countryTitle'>{t("process.cancelReason")}</p>
             <div className='countryList'>
  
  
<div >
    <input type="radio"  value={t("process.cancelReason1")} name="cancelReason" onChange={(e)=>handleOptionChange(e)} defaultChecked/>
<label>{t("process.cancelReason1")}</label>
</div>
<div>
<input type="radio"  value={t("process.cancelReason2")} name="cancelReason" onChange={(e)=>handleOptionChange(e)}/>
<label>{t("process.cancelReason2")}</label>
</div>
<div>
<input type="radio"  value={t("process.cancelReason3")} name="cancelReason" onChange={(e)=>handleOptionChange(e)}/>
<label>{t("process.cancelReason3")}</label>


</div>
   
     
 
            </div>

            
             <div className='modalBtns' style={{textAlign:"center",marginTop:"2em"}}>
           <button className='confirm' onClick={cancel}>     {t("Login.confirm")}</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     {t("profile.cancel")}</button></div>
          
     
            </div>
            
            
            
            
            }



            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default CancelReason;
 