

import '../Styles/Login.css';
import key from "../Images/Login/KeyIcon.svg"
import laptop from "../Images/Login/Laptop.svg"
import changePass from "../Images/Login/changePass.svg"
import React, {useState,useEffect,useRef} from 'react';
import OtpInput from 'react-otp-input';
import { useTranslation } from 'react-i18next';
import mobile from "../Images/Login/mobile.svg"
import Footer from "../Components/Footer";
import * as yup from "yup"
import {useFormik} from "formik";
import {checkPhone, resendCode,forgetOTP,CustomerInfo} from "../api";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from 'react-spinners';
function ForgetPassword() {
  
     let history = useNavigate();
     const { t, i18n } = useTranslation();
     const [msgAr, setMsgAr]=useState();
     const [msgEn, setMsgEn]=useState();
     const [seconds,setSeconds]=useState(59);
     const [showSec,setShowSec]=useState(false);
     const [loading,setLoading]=useState(false);
 /*user is first shown a screen to enter his phone number and when he's sent an otp,
 otp screen is set to true and is displayed instead
*/
  const [otp, setOtp]=useState(false);
  const [errorMsgModel, setErrorMsgModel]=useState(false); //error shows under input field
  const [errorMsgModel2, setErrorMsgModel2]=useState(false); //error shows under resend div

const [userPhone,setUserPhone]=useState("");

     const [otpVal,setOtpVal]=useState("");

    
     let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      
     const Schema= yup.object().shape({
     phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
     
     })
 
     const formik = useFormik({ 
          initialValues:{
        
           phone: '',
         
          },
          validationSchema: Schema,
          onSubmit
          });
          
          const phoneRef=useRef();
          const otpRef =useRef();

          useEffect(() => {
            localStorage.setItem("prevScreen","redirect");  //for use in loggin (if prevScreen = redirect logging in redirects to home screen instead of previous screen)
          
          
          
          },[])

          //when submitting user phone number(takes phone)
          //the checkPhone api call sends an otp to the user then otp screen is shown
          async function onSubmit(event){
            setLoading(true)
               let params = {
                 phone: phoneRef.current.value,
             };



             checkPhone(params).then((response) => {
           
               
               if(response.data.data.check === true){

                resendCode(params).then((response) => {
                  localStorage.setItem("phone",JSON.stringify(params.phone));

                  setUserPhone(JSON.stringify(params.phone))
                  setOtp(true);
                
                  setLoading(false)
                 
                
          }).catch(function (error) {
              // console.error(error);
          });
               }
               
               else {
            
           
                     setMsgAr("رقم الهاتف غير صحيح");
                     setMsgEn("Wrong phone number");
                       setErrorMsgModel(true);
                       setLoading(false)
               }
              
       
           }).catch(function (error) {
               console.error(error);
               setLoading(false)
           }); 
           
       /*    resendCode(params).then((response) => {
       
          
             console.log(response.data);
         }).catch(function (error) {
              console.error(error);
         });*/
       
             }
         


             //for resending otp code
             function resend(){
       
              setLoading(true)
               let params = {
                   phone: phoneRef.current.value
               };
               resendCode(params).then((response) => {
              
                       setMsgAr("تم إرسال الكود");
                       setMsgEn("Your code has been send");
                       setErrorMsgModel(false); 
                       setErrorMsgModel2(true);
                       setShowSec(true)
                       setLoading(false)
                       let second= 59;
                       setInterval(()=>{ //disables resend button and shows timer
                       
                         if(second>0){
                          second=second-1
                          setSeconds(second)
                          setShowSec(true)
                      
                     
                       
                      }
                         else{
                          setErrorMsgModel2(false);
                           setShowSec(false)
                        
                         }
                    
                       },1000)
               }).catch(function (error) {
                   // console.error(error);
               });
           };
       
       
           //when submitting otp code (takes phone value and otp code value)
           /*if code is valid customer information is fetched with response token and user is 
           redirected to /changePassword screen to enter their new password*/
       function submitCode(e){
        setLoading(true)
         e.preventDefault();
        const phone= JSON.parse(localStorage.getItem("phone"));
        console.log(phone);
        
       let data = {
           phone: phone,
           OTP:  otpVal
       };
         forgetOTP(data).then((response) => {
           if (response.data.message === "success") {
               localStorage.setItem("token", response.data.data.access_token)
               CustomerInfo(response.data.data.access_token).then((response) => {
                   localStorage.setItem("userName", response.data.data.name);
                   history("/changePassword");
           })
             }
           
            
             else {
       
                        
               setMsgAr("الكود غير صحيح")
               setMsgEn("Invalid code");
               setErrorMsgModel(true);
               setErrorMsgModel2(false);
               setLoading(false)
         }
       
       
       }).catch(function (error) {
         setMsgAr("الكود غير صحيح")
                 setMsgEn("Invalid code");
                 setErrorMsgModel(true);
                 setErrorMsgModel2(false);
                 setLoading(false)
             
       
           })
       
       }
//stores otp input value
       const handleChange = (otpVal) =>{ setOtpVal( otpVal );
          console.log(otpVal);
  
       }
   return (
        <div>
        
    


<div  style={!otp?{display:"none"}:null}      className='loginContainer container-fluid fluid'>
            
<div style={{padding:"3.5em 2em",position:"relative"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}

<div  style={loading?{display:"none"}:null}  className='row'>
<div className='col-md-6 passAlign'>
<img className='otpImage' src={mobile}/>
<p className='forgot'>      {t("Login.checkPhone")}</p>

<p className='otpPhone'>{userPhone}</p>
<form className='otpForm'  onSubmit={(e)=>submitCode(e)}>
    <div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
  <label>  {t("Login.EnterVerify")} </label>
  <div id="otp" style={i18n.language=="ar"?{position:"relative",left:"0.5em",direction:"ltr"}:{position:"relative",right:"0.5em"}}>
  
    <OtpInput
    
     value={otpVal}
     onChange={(otpVal)=>handleChange(otpVal)}
        numInputs={6}
       
      />
  </div></div>
  <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
 {i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr:null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}

 </div>


<button type="submit">{t("Login.confirm")}</button>
</form>
<div style={{textAlign:"left"}}>
<p className='resendChangePass'> {t("Login.notRecive")}  <span className='resendSpan' style={showSec?{cursor:"default",color:"#4F4F4F"}:null} onClick={ !showSec? resend:null}> {t("Login.resend")}</span> <span style={{fontSize:"16px"}}>{showSec? `00:${seconds}`:null} </span> </p>

 
</div>
{i18n.language=="ar"?
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgAr:null}</span>:
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgEn:null}</span>}

</div>

<div className={i18n.language=="ar"?'col-md-6 passPhoneImage':"col-md-6 passPhoneImageEn"}>

<img src={changePass}/>

</div>
</div>
</div>
</div>
        
        
        
        
        
        
        
        
        
        <div  style={otp?{display:"none"}:null} className='loginContainer container-fluid fluid'> 
            
<div style={{padding:"6em 2em",paddingBottom:"10em"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}

<div  style={loading?{display:"none"}:null}  className='row'>
<div className='col-md-6 passAlign'>
<img src={key}/>
<p className='forgot'>  {t("Login.forgetYour")}</p>
<p className='dontWorry'>{t("Login.dontWorry")}</p>
<form className='passForm' onSubmit={formik.handleSubmit} >
<label>  {t("Login.enterPhone")}</label>

<input style={{width:"100%"}} type="text"  placeholder= {t("Login.yourPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModel?"invalid":null}/>
<span style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>

     <div >
 <div  className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"left"}:{textAlign:"right"}}>
 {i18n.language=="ar"?
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgAr:null}</span></div>:
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgEn:null}</span></div>}
</div>
 </div>
<div className='formBTn'>
      
<button type="submit"> {t("Login.confirm")}</button></div>
</form>
</div>

<div className='col-md-6 passAlignImg'>
  
<img src={laptop}/>

</div>
</div>
</div>
</div>
<Footer/>
</div>
    );
}

export default ForgetPassword;