import "../Styles/Address.css";

import { HiOutlineLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { near_branches } from "../api";
function MyAddress(props) {
  const { t, i18n } = useTranslation();
  const history = useNavigate();

  //on choosing address , near_branches api call takes address lat and lng and returns nearbranches
  //and will store near branches (along with address data *to be used later in payment process*), if there are no near branches user is alerted else user is redirect to /branches screen (FromBranch component found in /Pages)
  function nearBranches() {
    near_branches(props.latitude, props.longitude, 'delivery').then((response) => {
      let data = {
        arAddress: props.arAddress,
        enAddress: props.enAddress,
        latitude: props.latitude,
        longitude: props.longitude,
        title: props.title,
      };
      localStorage.setItem("addressEn", data.enAddress);

      localStorage.setItem("addressData", JSON.stringify(data));

      localStorage.setItem("nearBranches", JSON.stringify(response.data.data));
      localStorage.setItem("addressId", props.id);
      localStorage.setItem("typeId", 2);
      console.log(response);

      if (response.data.data.length == 0) {
        props.setModal(true);
      } else {
        history("/branch");
      }
    });
  }

  return (
    <div
      className="col-12"
      style={i18n.language == "ar" ? null : { textAlign: "left" }}
    >
      <div className="myAddress">
        <div>
          <p className="addressName"> {props.title}</p>
          <div
            className="addressLoc"
            style={i18n.language == "ar" ? { direction: "rtl" } : null}
          >
            <HiOutlineLocationMarker size={30} style={{ color: "#E47100" }} />
            <p className="location"> {props.address} </p>
          </div>
        </div>
        <div className="chooseAddress">
          <button onClick={() => nearBranches()}> {t("address.choose")}</button>
        </div>
      </div>
    </div>
  );
}

export default MyAddress;
