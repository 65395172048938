import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from "react-i18next";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { payAgain_checkout } from "../api";
import { BeatLoader } from "react-spinners";
function CheckoutCard(props) {
  
  const { t, i18n } = useTranslation();
  const [cardValid, setCardValid] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const token = localStorage.getItem("token");
  const [errorMsgModel, setErrorMsgModel] = useState(false);

  function payAgain(paymentToken) {
    payAgain_checkout(token, paymentToken, props.id)
      .then((response) => {
        if (response.data.message == "warning") {
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setSubmitLoading(false);
          Frames.init();
        } else if (response.data.url !== "" && response.data.url !== null) {
          localStorage.setItem("transactionType", "order");
          localStorage.setItem("currencyCode", props.countryCode);
          localStorage.setItem("checkPayment", "order");
          localStorage.setItem("orderId", props.id);
          window.location.href = response.data.url;
        } else {
          props.setUpdate(!props.update);

          props.setModal(false);
        }
      })
      .catch(function (err) {
        setErrorMsgModel(true);
        setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
        setMsgEn("Transaction has been failed,please try again");
        setSubmitLoading(false);
        Frames.init();
      });
  }

  return (
    <div className="foodModal">
      <div onClick={() => props.setModal(false)} className="modalOverlay"></div>
      <div
        className="modal-content"
        style={{
          height: "auto",
          width: "50vw ",
          maxWidth: "500px",
          minWidth: "337px",
        }}
      >
        <div>
          <div
            style={{
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "space-around",
            }}
            className="modalBody"
          >
            <div className="creditCard" style={{ marginTop: "1.5em" }}>
              <Frames
                cardValidationChanged={(e) => {
                  setCardValid(e.isValid);
                }}
                config={{
                  publicKey: `${process.env.REACT_APP_PAYMENT_METHOD_KEY}`,
                  schemeChoice: true,
                }}
                cardTokenized={(e) => {
                  payAgain(e.token);
                }}
              >
                <CardNumber />
                <div className="date-and-code">
                  <ExpiryDate />
                  <Cvv />
                </div>

                {/* Or if you want to use single frames: */}
                {/* <CardFrame /> */}

                {submitLoading ? (
                  <div
                    style={{
                      height: "6vh",
                      marginTop: "2em",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    className="loader"
                  >
                    <BeatLoader
                      color={"#E47100"}
                      loading={submitLoading}
                      size={30}
                    />
                    <p className="paymentLoad">{t("payment.processing")}</p>
                  </div>
                ) : (
                  <button
                    disabled={!cardValid}
                    id="pay-button"
                    onClick={() => {
                      setSubmitLoading(true);
                      Frames.submitCard();
                    }}
                  >
                    {t("process.pay_again")}
                  </button>
                )}
              </Frames>
              {i18n.language == "ar" ? (
                <span className="errMsg" style={{ fontSize: "16px" }}>
                  {errorMsgModel ? msgAr : null}
                </span>
              ) : (
                <span className="errMsg" style={{ fontSize: "16px" }}>
                  {errorMsgModel ? msgEn : null}
                </span>
              )}
            </div>
          </div>
        </div>
        <button className="close-modal" onClick={() => props.setModal(false)}>
          <IoCloseSharp size={40} />
        </button>
      </div>
    </div>
  );
}

export default CheckoutCard;
