import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import p1 from '../Images/Footer/payment1.svg'
import p2 from '../Images/Footer/payment2.svg'
import p3 from '../Images/Footer/payment3.svg'
import p4 from '../Images/Footer/payment4.svg'
import vat from '../Images/Footer/vat.svg'
import logoAr from "../Images/logoAR3.svg"
import logoEn from "../Images/logoEn.svg"
import maroof from "../Images/Maroof.svg"
import { useTranslation } from 'react-i18next';
function Footer() {
    const { t, i18n } = useTranslation();
    var dt = new Date();
   const year= dt.getFullYear()
    return (
        <div id="footer" className='footer container-fluid'>
            <div className="container">
        <div className='row gy-4'>
        <div className='col-sm-4 footerVat'>
        <img className='footerLogo' src={i18n.language=="ar"?logoAr:logoEn}/>
           
            </div>
            <div className='col-sm-4 paymentMethods'>
            <img  src={p1}/>
            <img  src={p2}/>
            <img  src={p3}/>
            <img  src={p4}/>
            </div>
            <div className={i18n.language=="ar"? 'col-sm-4 footerEndAr': 'col-sm-4 footerEnd'}>
                <div className='footerImgs'>
                <img src={maroof}/>
                <Link to="/vat" style={i18n.language=="ar"? {marginRight:"2em"}:{marginLeft:"2em"}}>  <img src={vat}/></Link>
             
                </div>
        
            </div>
        </div>
        <div className='copyRights'>
        <p >{t("footer.copy_write_parg")} © {year}</p>

                    <p style={{marginTop:"5px"}}>{t("footer.powered")} <a target="_blank" className="footerLink" style={i18n.language=="ar"? {marginRight:"5px"}:{marginLeft:"5px"}} href="https://www.bdaiat.com/"> {t("footer.bdaiat")}</a></p>




        </div>
          </div></div>
            );
  }
  
  export default Footer;