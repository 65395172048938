
import React, {useState} from 'react';
 
import vat from "../Images/vat.jpg"
import Footer from '../Components/Footer';
import { useTranslation } from 'react-i18next';
function Vat() {
  const { t, i18n } = useTranslation();
    return (
      <div>
        <div className='container-fluid fluid'>
          <p className='vatTitle'>{t("vat.title")}</p>
<div className='vat'style={{marginBottom:"5em"}}>
<img src={vat}/>
  </div>

  </div>  <Footer/></div>
    );
}

export default Vat;
