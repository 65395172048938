import { useEffect, useState } from "react";
import { calories } from "../api.js";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { BeatLoader } from "react-spinners";

const Calories = () => {
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    calories().then((response) => {
      if (response.data.data.toString() === []) {
        setMenu([]);
        setLoading(false);
      } else {
        const result = Object.values(response.data.data);

        let mealArr = [];

        for (let i = 0; i < result.length; i++) {
          let obj = {};
          let marginTop = result[i].allergens.length > 1 ? result[i].allergens.length * 6 : 1;

          obj.nameAr = (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: `${marginTop}px`,
              }}
            >
              {result[i].nameAr}
            </p>
          );

          obj.allergens = result[i].allergens.map((e) => (
            <div key={e.name}>{e.name}</div>
          ));
          mealArr.push(obj);
        }

        setMenu(mealArr);
        setLoading(false);
      }
    });
  }, []);

  const data = {
    columns: [
      {
        label: "الوجبة",
        field: "nameAr",
        sort: "asc",
      },
      {
        label: "مسببات الحساسية",
        field: "allergens",
        sort: "asc",
      },
    ],
    rows: menu,
  };

  return (
    <div className="responsive-container">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="table-wrapper">
          <MDBDataTable
            searchLabel="بحث"
            paginationLabel={["السابق", "التالي"]}
            entriesLabel="عرض"
            striped
            hover
            noBottomColumns={true}
            data={data}
            paging={false}
          />
        </div>
      )}
      <style jsx>{`
        .responsive-container {
          padding: 40px;
        }

        .table-wrapper {
          overflow-x: auto; /* Enable horizontal scroll */
        }

        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default Calories;
