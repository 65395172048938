import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

export const CreditCardPayment = ({ setCardValid }) => (
  <Frames
    cardValidationChanged={(e) => {
      setCardValid(e.isValid);
    }}
    config={{
      publicKey: process.env.REACT_APP_PAYMENT_METHOD_KEY,
      schemeChoice: true,
    }}
    cardTokenized={(e) => {
      localStorage.setItem("package_payment_token", e.token);
    }}
  >
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <CardNumber style={{ width: "100%" }} />
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ExpiryDate />
        <Cvv />
      </div>
    </div>
  </Frames>
);
