import "../Styles/Address.css";

import { HiOutlineLocationMarker } from "react-icons/hi";
import MyAddress from "../Components/MyAddress";
import address from "../Images/Address.svg";
import { useTranslation } from "react-i18next";
function AddressList(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="row addressInput">
      <div className="col-lg-6">
        <p className="addressTitle" style={{ marginBottom: "1em" }}>
          {" "}
          {t("address.saved_address")}
        </p>

        {props.address.length === 0 ? (
          <p className="noAddress"> {t("address.no_address")}</p>
        ) : null}
        <div
          style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="row gy-4">
            {props.address &&
              props.address.map((data, index) => (
                <MyAddress
                  key={index}
                  setModal={props.setModal}
                  arAddress={data.arAddress}
                  enAddress={data.enAddress}
                  address={
                    i18n.language == "ar" ? data.arAddress : data.enAddress
                  }
                  id={data.id}
                  title={data.title}
                  isCreatorByUser={data.isCreatorByUser}
                  latitude={data.latitude}
                  longitude={data.longitude}
                />
              ))}
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: "1em" }}
        className="col-lg-6 order-first order-lg-last"
      >
        <div className="vAlign">
          <img style={{ maxWidth: "400px" }} src={address} />
        </div>
      </div>
    </div>
  );
}

export default AddressList;
