import "../Styles/Home.css"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Data } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
function TypeList(props){
    const { t, i18n } = useTranslation();
const history= useNavigate();

function removeAddress(){
 localStorage.removeItem("nearBranches");
 localStorage.setItem("carOption",false); 
    history("/branch")
}

    return ( 

        <div className="col-md-4 col-sm-6">
        <div className="menu">
        <div  className='menueItem' style={{ backgroundImage: `url(${props.image})` }}>

</div>
      
          <div className="menubottom">
              {console.log(props.index)}
{props.index!==0?
              <Link to={props.link}>
              <button className={i18n.language=="en"?"enArrow-button2 arrow-button2":"arrow-button2"}>{props.name}<span className="arrow2"></span>
  </button></Link>
  :

  <button onClick={removeAddress} className={i18n.language=="en"?"enArrow-button2 arrow-button2":"arrow-button2"}>{props.name}<span className="arrow2"></span>
  </button>}
  
  </div></div>
      </div> 
    );
} 

export default TypeList;