 

import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from 'react-i18next';


function ContactSuccess(props) {
const [quantity,setQuantity]=useState(1);
const { t, i18n } = useTranslation();
const [cartItem,setCartItem]=useState([]);
const [product,setProduct]=useState([]);


 




    return (


        <div  className="foodModal">
          { console.log(cartItem)}
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className=" contactSuccess" style={{height:"150px"}}>
          
            <div >
             <div className='modalBody '>
         <p className='cancelOrder'>
          {t("contact.success")}</p>
  
            </div>  
          
     
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default ContactSuccess;
