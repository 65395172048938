import React, {useEffect, useState} from 'react';

import success from "../Images/success-web.svg";
import failed from"../Images/faild-web.svg";

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import wallet from"../Images/wallet.svg";
import { app } from "../Firebase/firebaseConfig";
import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import {payment ,pay_again,order_track} from "../api";


import { Link } from 'react-router-dom';
function WalletCharged(){
    const analytics = getAnalytics(app);
 
 const  [loading,setLoading]=useState(true);
 const { t, i18n } = useTranslation();
 const [phone,setPhone]=useState("");
 
  const [state,setState]=useState(false);

   const [paymentLoad,setPaymentLoad]=useState(false);

   const search = window.location.search;
   const params = new URLSearchParams(search);

   const TranId = params.get('TranId');
   let history = useNavigate();
   const [orderData,setOrderData]=useState();

    useEffect(() => {


        window.scrollTo(0, 0);

        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id');
 
        if(tapId !== ""){
            payment(tapId ,localStorage.getItem("token")).then((response) => {
     
                setLoading(false);
               
              
    
        
            }).catch(function (error) {
                

              
                history("/charge-wallet")
             
            });
            
        } else {
         
            setLoading(false);
           
        }
      
      
      } ,[ ]);

      
   

     
        return (
          <div>{loading?<div className='loader'>
          <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"3em",marginBottom:"5em"}} className='container orderSuccess'>
  
 <div className='shareForm '>
   <img style={{marginBottom:"1em"}} src={wallet}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.success_payment")}
  </p>
 <Link to="/profile/myWallet">
<button style={{display:"inline-block"}} > {t("profile.backWallet")}</button></Link> 
   </div>

    
    
   
    </div>
                


                
                }
                <Footer/>
                </div>
           
        )
    }


    export default WalletCharged;
