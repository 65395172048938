  

 
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function MealNotAvail(props) {

const { t, i18n } = useTranslation();

 


    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content logoutModal" style={{height:"auto",width:"50vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
             <div style={{height:"20vh",flexDirection:"column",textAlign:"center",justifyContent:"space-around"}} className='modalBody'>
         <p className='cancelOrder'>
          {props.noSizesMsg}</p>
                 </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default MealNotAvail;
 