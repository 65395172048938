import "../Styles/UserProfile.css";

import { useTranslation } from "react-i18next";

import { orders } from "../api";
import { useState, useEffect } from "react";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
function MyOrders(props) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState([]);

  const [modal, setModal] = useState(false);
  let history = useNavigate();

  //on clicking on an order's details button user is redirected to /order-track (OrderTracking component in /Pages) with order id as params
  const handleProceed = (id) => {
    history(generatePath("/order-track/:id", { id }));
  };

  //fetch user orders
  useEffect(() => {
    const token = window.localStorage.getItem("token");

    orders(token).then((response) => {
      setOrder(response.data.data);

      setLoading(false);
    });
  }, []);

  return (
    <div>
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={0} />
        {loading ? (
          <div className="loader profileLoader">
            <BeatLoader color={"#E47100"} loading={loading} size={30} />
          </div>
        ) : (
          <div className="aboutusBody profileBody container-fluid fluid">
            <p className="upTable-title">{t("profile.orders")}</p>
            <div className="upTable table-responsive">
              <table className="table align-middle table-striped table-hover table-borderless ">
                <thead>
                  <tr>
                    <th scope="col">{t("profile.orderId")}</th>
                    <th scope="col">{t("profile.date")}</th>
                    <th scope="col">{t("profile.type")}</th>
                    <th scope="col">{t("profile.order-total")}</th>
                    <th scope="col">{t("profile.branch")}</th>
                    <th scope="col"></th>
                    
                  </tr>
                </thead>
                <tbody>
                  {order.done &&
                    order.done.map((data, index) => (
                      <tr>
                        <td>{data.id}</td>
                        <td>{data.createdAt}</td>
                        <td>
                          {data.typeId == 1
                            ? t("home.option-branch")
                            : data.typeId == 2
                            ? t("home.option-home")
                            : data.typeId == 3
                            ? t("home.option-donation")
                            : t("home.option-car")}
                        </td>
                        <td>
                          {data.totalPrice}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}
                        </td>
                        <td>
                          {i18n.language == "ar"
                            ? data.branchArName
                            : data.branchEnName}
                        </td>

                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {t("profile.show-details")}
                          </button>
                        </th>
                      </tr>
                    ))}
                  {order.inProgress &&
                    order.inProgress.map((data, index) => (
                      <tr>
                        <td>{data.id}</td>
                        <td>{data.createdAt}</td>
                        <td>
                          {data.typeId == 1
                            ? t("home.option-branch")
                            : data.typeId == 2
                            ? t("home.option-home")
                            : t("home.option-car")}
                        </td>
                        <td>
                          {data.totalPrice}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}
                        </td>
                        <td>
                          {i18n.language == "ar"
                            ? data.branchArName
                            : data.branchEnName}
                        </td>

                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {t("profile.show-details")}
                          </button>
                        </th>
                      </tr>
                    ))}
                  {order.waitingPayment &&
                    order.waitingPayment.map((data, index) => (
                      <tr>
                        <td>{data.id}</td>
                        <td>{data.createdAt}</td>
                        <td>
                          {data.typeId == 1
                            ? t("home.option-branch")
                            : data.typeId == 2
                            ? t("home.option-home")
                            : t("home.option-car")}
                        </td>
                        <td>
                          {data.totalPrice}{" "}
                          {i18n.language == "ar"
                            ? data.arCurrency
                            : data.enCurrency}
                        </td>
                        <td>
                          {i18n.language == "ar"
                            ? data.branchArName
                            : data.branchEnName}
                        </td>

                        <th scope="row">
                          <button onClick={() => handleProceed(data.id)}>
                            {t("profile.show-details")}
                          </button>
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default MyOrders;
