import notFound from"../Images/404.png";
import { useTranslation } from 'react-i18next';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom'; 

function NotFound(){
 const { t, i18n } = useTranslation();

        return (
          <div>
   

<div style={{marginTop:"2em",marginBottom:"2em"}} className='container-fluid fluid orderSuccess'>
  

  <div className='shareForm foundUrl'  style={{ backgroundImage: `url(${notFound})`}}>
<p>{t("home.notFound")}</p>

</div></div>

    
   

                 

   
                
                <Footer/>
                </div>
           
        )
    }


    export default NotFound;
