 
import Navbar from "../Components/Navbar";
import { useRef,useState,useEffect } from "react";
import Footer from "../Components/Footer";
import auafi  from "../Images/auafi.svg"
import { useNavigate } from "react-router-dom";
import "../Styles/Awafi.css";
import { useTranslation } from 'react-i18next';
import AwafiTerms from "../Components/Awafi-terms";
import {checkLoyalty,points} from "../api"; 
import AwafiSignUp from "../Components/Awafi-signUp";
import AwafiMembership from "../Components/AwafiMembership";
import AwafiPoints from "../Components/AwafiPoints";
import AwafiQuestions from "../Components/AwafiQuestions";
import {terms} from "../api";
import TermsModal from '../Components/TermsModal';
import { BeatLoader } from "react-spinners";
import { BackgroundImage } from 'react-image-and-background-image-fade'

function Awafi() {
    const { t, i18n } = useTranslation();
    let history=useNavigate()
const [page,setPage]=useState(5);

 const [pointPrice,setPointPrice]=useState();


 const [loading,setLoading]=useState(true);

   const [loyalty,setLoyalty]=useState(false);


//checkLoyalty api call checks if current user is registered in awafi
//if registered, registration screen is hidden
//points api call is used to get current awafi point price
useEffect(() => {
 

    /*
      terms().then(response => {
        setArDesc(response.data.data.arDescription);
        SetenDesc(response.data.data.enDescription);
        console.log(response.data.data);})
      */


  const token= localStorage.getItem("token");
  if(token){
  checkLoyalty(token).then(response => {
    points(token).then((response) => {
  
        
                setPointPrice( response.data.data.pointPrice)
                
                setLoading(false)
    
         // this.checkloyalty()
    
          }).catch(function (error) {
              if (error.response.status === 403) {
                history("/code")
              } else {
                setLoading(false)
                history("/login")            
              }
    
          });
    if (response.data.data.check === 1) {
      setLoyalty(true)
      if(page==0){
    setPage(5)
     
      }
  } else {

     setLoading(false)
    
  
  }
}).catch(function (error) {
  if (error.response.status === 403) {
    history("/code")
  } else {
    setLoading(false)
    history("/login")
  }
});
}else{

setLoading(false)

  
}

if(!loading){
  window.scrollTo(0,650);
}
 },[page]);      







    return (
    <div>
    

{/*modal? (
        <TermsModal setModal={setModal} describe={i18n.language=="ar"?arDesc: enDesc}/>
):null*/} 
  {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

 
  <div className="aboutusBody container-fluid fluid">
 
<div className="awafiHeader">
<p>{t("awafi.title")}</p>
<BackgroundImage
        src={auafi}
       width="100%"
   
       className="awafiImg"
      />
      {/*
<div className="awafiImg"  style={{ backgroundImage: `url(${auafi})` }}>

  </div>*/}
</div>
 
 

<div style={{marginTop:"5em"}} className="corporateNav cstmr">
<p onClick={()=>setPage(5)} className={page==5?"Customeractive":null}>  {t("awafi.nav6")} </p>

{!loyalty?


<p onClick={()=>setPage(0)} className={page==0?"Customeractive":null}> {t("awafi.nav1")}</p>
:null}


<p onClick={()=>setPage(1)}  className={page==1?"Customeractive":null}> {t("awafi.nav2")}</p>


<p  onClick={()=>setPage(2)}  className={page==2?"Customeractive":null}>{t("awafi.nav3")}</p>


<p onClick={()=>setPage(3)} className={page==3?"Customeractive":null}> {t("awafi.nav4")} </p>


<p onClick={()=>setPage(4)} className={page==4?"Customeractive":null}>  {t("awafi.nav5")} </p>


</div>


{ 
page==0 && !loyalty?
<AwafiSignUp  setLoyalty={setLoyalty} setPage={setPage}/>
:null

}
{
page==1 ?
<AwafiMembership/>
:null
 
}
{
page==2?
<AwafiPoints pointPrice={pointPrice}/>
:null

}
{
page==3 ?
<AwafiTerms/>
:null

}

{
page==4?
<AwafiQuestions/>
:null

}

{
page==5 ?
<div className="awafiMembership">
<p className="membership-title">
{t("aboutAuafi.about1")}</p>

    <p className="membership-text">
  {t("aboutAuafi.about2")}</p>
       
       
      

</div>
:null
 
}
</div>}

<Footer/> 
</div>

  
    );
  }
  
  export default Awafi;