
import React,{useState,useEffect} from "react";
import "../Styles/Customers.css"
import Footer from "../Components/Footer";
import cca from "../Images/cstmrIcons/call-center-agent.svg"
import cc from "../Images/cstmrIcons/credit-card.svg"
import ft from "../Images/cstmrIcons/food-tray.svg"
import gb from "../Images/cstmrIcons/gift-box.svg"
import mc from "../Images/cstmrIcons/Group.svg"
import hp from "../Images/cstmrIcons/headphones.svg"
import rs from "../Images/cstmrIcons/room-service.svg"
import vd from "../Images/video.svg"
import { useTranslation } from 'react-i18next';
import { BeatLoader } from "react-spinners";
import poster from "../Images/cstmrIcons/poster.png"
import video from "../Video/topCustomers.mp4"
import Slider from "../Components/Slider";
import { Banners } from "../api";

 
function TopCustomers(props) {

   const [loading,setLoading]=useState(true)
    const [page1,setPage1]=useState(true);
    const [banners,setBanners]=useState();
    const { t, i18n } = useTranslation();




useEffect(() => {
   setLoading(true)
   let language= i18n.language=="ar"? "ar":"en"
   let countryId= props.country==0? 1:2;
   Banners("topCustomers",language,countryId).then(response => {
       const data=response.data.data;
      setBanners(data);
      setLoading(false)
   })

  
},[i18n.language,props.country])


    return (
      <div>
       {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

      <>
            <Slider banners={banners}/>
       <div className="container-fluid fluid">
     
           <div className="cstmr">
           <p onClick={()=>setPage1(true)} className={page1? "Customeractive":null}> {t("nav.topCustomers")}</p>
            <p onClick={()=>setPage1(false)} className={!page1? "Customeractive p1":"p1"}>  {t("customers.terms_conditions")}</p>
</div>
        
<hr className="cstmhr"/>
{page1?
<div style={{marginTop:"40px"}} className="row ">
     
     
    <div className={i18n.language=="en"?"topCstmIcn col-lg-6 perks" :"col-lg-6 perks"}>
    <p className="top">{t("customers.tc1")}</p>
    <div>
     <img src={gb} alt="icon" /><p className="pnt">{t("customers.tc2")}</p></div>
     <div>
     <img src={ft} alt="icon"/><p className="pnt"> {t("customers.tc3")}</p></div>
     <div>  <img src={mc} alt="icon"/><p className="pnt">{t("customers.tc4")}</p></div>
     <div> <img src={hp} alt="icon"/><p className="pnt">{t("customers.tc5")}</p></div>
     <div> <img src={rs} alt="icon"/><p className="pnt"> {t("customers.tc6")}</p></div>
     <div> <img src={cca} alt="icon"/><p className="pnt">{t("customers.tc7")}</p></div>
     <div> <img src={cc} alt="icon"/><p style={{marginBottom:"2rem"}} className="pnt">{t("customers.tc8")}</p></div>
       <p className="bottom">{t("customers.tc9")}</p>
    </div>
    <div className="col-lg-6 videoAlign order-lg-last order-first">
    <video width="100%" controls poster={poster} >
                                     <source src={video} type="video/mp4"/>
                                </video>
    </div>
  </div>
  : <div className="tandc"   >
      <p className="tandc1">{t("customers.terms1")}</p>
      <p className="tandc2"> {t("customers.terms2")}</p>
      <p className="tandc3"> {t("customers.terms3")}</p>
      <ul>
<li>{t("customers.terms5")}</li>
<li>{t("customers.terms6")}
<ul >
<li className="first">{t("customers.terms7")}</li>
<li>{t("customers.terms8")}</li>
</ul></li>
<li>{t("customers.terms9")}</li>
   <li>{t("customers.terms10")}</li>
   <li> {t("customers.terms11")}</li>
<li>{t("customers.terms12")}</li>
<li>{t("customers.terms13")}</li>
<li>{t("customers.terms14")}</li>
<li>{t("customers.terms15")}</li>
<li>{t("customers.terms16")}</li>
<li>{t("customers.terms17")}
<ul>
   <li className="first">{t("customers.terms18")}</li> 
   <li>{t("customers.terms19")}</li> 
   <li>{t("customers.terms20")}</li> 
   <li>{t("customers.terms21")}</li> 
   <li>{t("customers.terms22")}</li> 
   <li>{t("customers.terms23")}</li> 
   <li>{t("customers.terms24")}</li> 
   <li>{t("customers.terms25")}</li> 
</ul>

</li>
<li>{t("customers.terms26")}</li>
<li>{t("customers.terms27")}</li>
<li>{t("customers.terms28")}</li>
<li>{t("customers.terms29")}</li>
<li>{t("customers.terms30")}</li>



   </ul>
      </div>}
 
       </div> </>}
       
    <Footer/>

</div>  
    );
  }
  
  export default TopCustomers;