    

import { useRef,useState,useEffect } from "react";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import { inquireMenu  } from '../api'
import gift from "../Images/gift.svg"
import party from "../Images/Party.svg"
import { useNavigate } from "react-router-dom";
import { salesSingleMenu } from "../api";
import { useParams } from "react-router-dom";
import * as yup from "yup"
import {useFormik} from "formik";
function CompanyInquire(props) {
    const { t, i18n } = useTranslation();
    let history = useNavigate();
   const free= JSON.parse(window.localStorage.getItem('FREEMEALS'));
   const [msgEn, setMsgEn]=useState();
   const [msgAr, setMsgAr]=useState();
   const [errorMsgModel, setErrorMsgModel]=useState(false);
   const[menu,setMenu]=useState([]);
   const [loading,setLoading]=useState(true)
   const [submittLoading,setSubmittLoading]=useState(false)
   const [success,setSuccess]=useState(false)
   const { id } = useParams();

 

   const Schema= yup.object().shape({
 name: yup.string()
    .required(("companySales.company_required")),
    note: yup.string()
    .required(("validation.notes_required")),
   
    })
    const formik = useFormik({
      initialValues:{
        name: '',
        note: '',
      },
      validationSchema: Schema,
      onSubmit
      });
  
          const nameRef=useRef();
          const noteRef=useRef();


          //fetch chosen menu data with id from url
   useEffect(()=>{

    window.scrollTo(0, 0);

    salesSingleMenu(id).then(response => {
     
       setMenu(response.data.data)
       setLoading(false)
       
       })
  
  },[ ])

 

  //takes notes,company_name and menu_id
  //on success success text is displayed instead of the form
  //if user is not logged in user is redirected to login screen
  async function onSubmit(){
    const token= localStorage.getItem("token");
    setSubmittLoading(true)
    if (!token) {
     history("/login")
  }
  
  else {
 
      const values = {
          notes: noteRef.current.value,
          company_name: nameRef.current.value,
          menu_id: menu.id
      };

     inquireMenu(values,token).then((response) => {
       // localStorage.setItem("salesInquireSuccessMsg", "true");
        setSuccess(true)
      setSubmittLoading(false)
    }).catch(error => {
        if (error.response.status === 401) {
          history("/login")
        } else {
        setMsgAr("حدث خطأغير متوقع");
        setMsgEn("something went wrong")
       setErrorMsgModel(true)
       setSubmittLoading(false)
        }
    }).catch((err)=>{
      if(err.response.status === 401){
        //used to change cart icon's current value to 0
    
        props.setCartItem(0) 
    
    
      localStorage.removeItem("token");
      localStorage.removeItem("userPhone");
      localStorage.removeItem("userName");
      localStorage.removeItem("basket");
      //was used to store user's current step in cart process
      localStorage.removeItem("process");
    
     history("/login")
    
      
      
        }
    });
    

  }
 
}

    

    return (

        <div>
        <div className='container-fluid fluid ' >
      
        {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

 
 
      <div style={{marginTop:"5em"}} className='giftContainer '>
        {success?  <div className="container-fluid inquireSuucess">
    <p>  {t("companySales.success")}</p> 
          </div>
        
        
        :
        <div className="container-fluid">
        <div className="bookMenu row">
            <div style={{padding:"0"}} className="col alignImg">
            <div className="bookImg" style={{ backgroundImage: `url(${menu.image})`} }>
</div>
        <p  className="bookName">{i18n.language=="ar"?menu.arName:menu.enName}</p>
</div>  

<div  className="col alignImg pricePerson">
        <p className="bookPrice" style={{whiteSpace:"nowrap"}}>  <span style={{fontSize:"24px",color:"#633235",margin:"auto"}}>{t("companySales.price")}: </span> {menu.pricePersonSAR + " "} {t("meals.sar")}</p>
</div>
            </div>

<form className="inquireForm" onSubmit={formik.handleSubmit}>
  <label>{t("companySales.company")}</label>
<input    type="text" value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name?"invalid":null}/>
<span className="errMsg">{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>

 
<label>{t("mainMenu.enter_your_notes")}</label>
<input   ref={noteRef} value={formik.values.note} id="note" name="note"   required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.note && formik.touched.note?"invalid":null}/>
<span className="errMsg">{formik.errors.note && formik.touched.note?t(`${formik.errors.note}`):null}</span>

<div  style={{marginTop:"4em",textAlign:"center"}} >

{submittLoading?
      <div style={{height:"10vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submittLoading}  size={30} /></div>:
  <>
  
{i18n.language=="ar"?
<span className="errMsg">{errorMsgModel? msgAr:null}</span>:
<span className="errMsg">{!errorMsgModel? msgEn:null}</span>}


      <button type="submit" className="inquireBtn">{t("clientCare.send")}</button>
      </>}
      </div>
</form>
 
    </div>}
       
   
    
      
  
 
          </div>}
        </div>
        
   

<Footer/> 
</div>

  
    );
  } 
  
  
  export default CompanyInquire;