 
import "../Styles/CompanySales.css";

import { useTranslation } from 'react-i18next';

import etaam from "../Images/etaam.svg"
import marai from "../Images/marai.svg"
function SalesCustomers(props) {
    const { t, i18n } = useTranslation();

 


    return (

        <div className="row gy-4 sales-customersAlign">
        
        {props.client && props.client.map((data,index)=>


<div className=" col-md-3 col-sm-6 sales-customers">

<div key={index}  style={{backgroundImage:`url(${data.image})`}}></div>
 


</div>
   
     
     )
}
      

       



        </div>
        
        
    
  
    );
  }
  
  export default SalesCustomers;