import "../Styles/Address.css";
import { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import AddressCard from "../Components/AddressCard";
import { Link } from "react-router-dom";
import AddAddress from "./AddAddress";
import { IoIosArrowBack } from "react-icons/io";
import { GoPlusSmall } from "react-icons/go";
import AddressList from "./AddressList";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward } from "react-icons/io";
import { saved_address } from "../api";
import { BeatLoader } from "react-spinners";
import useGeoLocation from "../Components/useGeoLocation";
import { countries } from "../api";
import AddressElgible from "../Components/AddressElgible";
import { Modal } from "bootstrap";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function AddressPage(props) {
  const [page1, setPage1] = useState(true);
  const { t, i18n } = useTranslation();
  const [address, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState(false);
  const location = useGeoLocation();
  let history = useNavigate();
  const [loc, setLoc] = useState({ lat: 0, lng: 0 });

  //fetch user addresses
  useEffect(() => {
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);
    let countryId = props.country == 0 ? 1 : 2;

    setLoading(true);

    saved_address(token, countryId).then((response) => {
      const data = response.data.data;
      setAddresses(data);

      setLoading(false);
    });
  }, [page1, props.country]);

  //on changing tab (Choose address / add new address)
  /*checks if the custom hoook useGeoLocation is given permission to get user lat and lng
and stores the values for use in AddAddress component (for setting map position) */
  /*if not given permission loc variable is set to the center lat and lng of current chosen country (props.country) */

  function onChangeNav() {
    let loc = {};
    if (location.loaded && location.coordinates) {
      loc = {
        lat: location.coordinates.lat,
        lng: location.coordinates.lng,
      };
    } else {
      if (props.country == 0) {
        loc = {
          lat: 24.774265,
          lng: 46.738586,
        };
      } else {
        loc = {
          lat: 26.129999,
          lng: 50.555,
        };
      }
    }

    console.log(loc);
    setLoc(loc);
  }

  return (
    <div>
      {modal ? <AddressElgible setModal={setModal} /> : null}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          <div className="Address container-fluid fluid">
            <div className="addressNav backBtn">
              <div>
                <p>
                  <Link to="/"> {t("address.home")}</Link>

                  {i18n.language == "ar" ? (
                    <IoIosArrowBack style={{ color: "#4F4F4F" }} />
                  ) : (
                    <IoIosArrowForward style={{ color: "#4F4F4F" }} />
                  )}

                  <span> {t("home.option-home")}</span>
                </p>
              </div>
              <div id="back" className={i18n.language == "ar" ? "" : "backEn"}>
                <button onClick={() => history(-1)}>
                  {i18n.language == "ar" ? (
                    <BsArrowRightShort size={30} />
                  ) : (
                    <BsArrowLeftShort size={30} />
                  )}
                  {t("address.back")}
                </button>
              </div>
            </div>
            <div>
              <div className="addressBody">
                <div>
                  <div className="cstmr">
                    <p
                      onClick={() => {
                        setPage1(true);

                        onChangeNav();
                      }}
                      className={page1 ? "Customeractive" : null}
                    >
                      {t("address.choose_address")}{" "}
                    </p>

                    <p
                      onClick={() => {
                        setPage1(false);

                        onChangeNav();
                      }}
                      className={!page1 ? "Customeractive p1" : "p1"}
                    >
                      <span>
                        <GoPlusSmall size={30} /> {t("address.add_address")}{" "}
                      </span>{" "}
                    </p>
                  </div>
                  <hr className="cstmhr" />
                </div>
                {page1 ? (
                  <AddressList
                    setModal={setModal}
                    address={address}
                    setCartItem={props.setCartItem}
                  />
                ) : (
                  <AddAddress
                    setCartItem={props.setCartItem}
                    setModal={setModal}
                    countryCode={props.country == 0 ? "SA" : "BA"}
                    loc={loc}
                    country={props.country}
                  />
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default AddressPage;
