 
import "../Styles/CompanySales.css";

import { useTranslation } from 'react-i18next';
import SalesComp from "../Components/SalesComp";

function AwafiTerms() {
    const { t, i18n } = useTranslation();

 


    return (

<div className={i18n.language=="en"?"awafiTerms awafiTermsEn":"awafiTerms"}>
<p> {t("awafiTerms.header")}</p>

<ul><div className="liItem">
    <div className="bullet">
    <p >1</p></div>
<li>
{t("awafiTerms.t1")}

</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >2</p></div>
<li>{t("awafiTerms.t2")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >3</p></div>
<li>{t("awafiTerms.t3")}

</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >4</p></div>
<li> 
{t("awafiTerms.t4")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >5</p></div>
<li>
{t("awafiTerms.t5")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >6</p></div>
<li>
{t("awafiTerms.t6")}
    </li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >7</p></div>
<li>
{t("awafiTerms.t7")}

</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >8</p></div>
<li>
{t("awafiTerms.t8")}

</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >9</p></div>
<li>
{t("awafiTerms.t9")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >10</p></div>
<li>
{t("awafiTerms.t10")}
</li>
</div>

<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>   {t("awafiTerms.t10-num1")} </span>
    {t("awafiTerms.t10-data1")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num2")} </span>
    {t("awafiTerms.t10-data2")}

</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num3")} </span>
    {t("awafiTerms.t10-data3")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num4")} </span>
    {t("awafiTerms.t10-data4")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div> 
<li>
    <span>{t("awafiTerms.t10-num5")} </span>
    {t("awafiTerms.t10-data5")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num6")}</span>
    {t("awafiTerms.t10-data6")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num7")} </span>
    {t("awafiTerms.t10-data1")}
</li>
</div>
<div  className="liItem li-nested">
<div className="bullet">
    <p >10</p></div>
<li>
    <span>{t("awafiTerms.t10-num8")} </span>
    {t("awafiTerms.t10-data8")}
</li>
</div>
<div style={{marginTop:"2em"}}  className="liItem">
<div className="bullet">
    <p >11</p></div>
<li>{t("awafiTerms.t11")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >12</p></div>
<li>{t("awafiTerms.t12")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >13</p></div>
<li>{t("awafiTerms.t13")}
</li>
</div>
<div  className="liItem">
<div className="bullet">
    <p >14</p></div>
<li>{t("awafiTerms.t14")}
</li>
</div>
<p className="liAlert">
{t("awafiTerms.t15-note")}
</p>
<div style={{marginBottom:"1em"}}  className="liItem">
<div className="bullet">
    <p >15</p></div>
<li>
{t("awafiTerms.t15")}


</li>
</div>
<p >
{t("awafiTerms.t16-title")}

</p>


<div className="membership-list">
<p><span>1-</span>  {t("awafiTerms.t16-1")}</p>
<p><span>2-</span>  {t("awafiTerms.t16-2")}</p>

<p style={{marginBottom:"0"}}><span>3-</span>  {t("awafiTerms.t16-3")}</p>


<ul><div  style={{marginBottom:"0"}}  className="liItem">
    <li  style={{marginBottom:"0"}} >{i18n.language=="ar"? t("awafiTerms.t16-31") +  " -" : "- " + t("awafiTerms.t16-31") }</li></div>
    <div    className="liItem">
    <li style={{marginBottom:"0"}} >{i18n.language=="ar"? t("awafiTerms.t16-32") +  " -" : "- " + t("awafiTerms.t16-32") }</li></div>
</ul>




<p><span>4-</span>  {t("awafiTerms.t16-4")}</p>

</div>
{/*<div    className="liItem">
<li>{t("awafiTerms.t16-1")} - </li> </div>
<div    className="liItem">

<li>{t("awafiTerms.t16-2")} - </li> </div>
<div    className="liItem">
 
<li>{t("awafiTerms.t16-3")} - </li> </div>
<div    className="liItem">

    <li>{t("awafiTerms.t16-4")} - </li> </div>*/}

<p > {t("awafiTerms.t17-title")}

</p>
<div  className="liItem">

<li>
{t("awafiTerms.t17")}
</li>
</div>
</ul>

</div>
    
  
    );
  }
  
  export default AwafiTerms;