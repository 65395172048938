 
import React, {useState,useEffect} from 'react';
import { BeatLoader } from 'react-spinners';
import vat from "../Images/vat.jpeg"
import Footer from '../Components/Footer';
import { useTranslation } from 'react-i18next';
import { Banners } from '../api';
import { BackgroundImage } from 'react-image-and-background-image-fade'

function PromotionsBH(props) {

 const { t, i18n } = useTranslation();
 const[banners,setBanners]=useState([]);

 const[loading,setLoading]=useState(true);



useEffect(() => {
   setLoading(true)
   let language= i18n.language=="ar"? "ar":"en"
   let countryId= props.country==0? 1:2;
 
   
   Banners("promotionsBH",language,countryId).then(response => {
       const data=response.data.data;
      setBanners(data);
   setLoading(false)
   })


   },[])

   return (
     <div>
          {loading?
     <div className='loader'>
     <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
       <div style={{minHeight:"68vh"}} className='container-fluid fluid promotion'>
<div className='vat '>
<BackgroundImage
        src={banners[0].image}
       width="100%"
   className="bckgSize"
      
      >
          <img src={banners[0].image} style={{visibility: "hidden"}} />
 
      </BackgroundImage>
<a href={banners[0].link} target="_blank">
<button >{t("vat.download")}</button></a>
 </div>

 </div> } <Footer/></div>
   );
}

export default PromotionsBH;
