  
import "../Styles/AboutUs.css";

import { useRef,useState,useEffect } from "react";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import Slider from "../Components/Slider";
import {mediaPost,medias} from "../api";
import { useParams } from "react-router-dom";
import branches from "../Images/branches.svg"
import { generatePath } from 'react-router-dom';
import { useNavigate,Link } from "react-router-dom";
import {useLocation} from 'react-router-dom'
function MediaCenter() {
    const { t, i18n } = useTranslation();
const [media,setMedia]=useState([]);
const [loading,setLoading]=useState(true)
const { postId } = useParams();
const [allMedia,setAllMedia]=useState([]);
const location= useLocation();
let history = useNavigate();
      
//takes postId from url and fetch post's data
//fetch all other media posts to be shown in recommendations
//rerenders on changing url
useEffect(() => {
   
    window.scrollTo(0, 0);

    mediaPost(postId).then(response => {
        setMedia(response.data.data);
  
    })

    medias().then(response => {
        setAllMedia(response.data.data);
    setLoading(false)
   })
},[location])
      

//on clicking on a media div from recommendations postId in url is changed and screen is rerenderd
const handleProceed = (postId) => {
    history(generatePath("/news/:postId", { postId }));
    
  };
    return (
        <div>
        {loading?
         <div className='loader'>
         <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
   
   <>
   

   <Slider type="website"/>
 <div className="container-fluid fluid">
<div className="backBtn">
    <Link to="/about-us">
<button >{t("aboutUS.backNews")}</button>
</Link>
 </div>
 <div className="row newsData">
 <div className="col-md-8 ">
     <p className="newsTitle">{i18n.language=="ar"?media.titleAr:media.titleEn}</p>
     <div  className='newsImg' style={{ backgroundImage: `url(${media.image})` }}></div>
     <p className="newsDate">{media.createdAt}</p> 

     <div className="newsDescr" dangerouslySetInnerHTML={{ __html: i18n.language=="ar"?media.descriptionAr: media.descriptionEn}} />
          
 </div>
<div className={i18n.language=="ar"?"col-md-4 scrollNews":"col-md-4 scrollNews scrollNewsEn"}>
    
    <p className="scrollNewsTitle">{t("aboutUS.other")}</p>
    <div className="scroller">
{allMedia && allMedia.map((data,index)=>

data.id!==parseInt(postId)?
<>
<div onClick={()=>handleProceed(data.id)} className="col ">

<div  className="aboutusImg" style={{ backgroundImage: `url(${data.image})` }}>
 </div> 
 <div className="scrollTitle">
<p>{i18n.language=="ar"? data.titleAr:data.titleEn}</p>

</div>
</div>


</>


:null
)}</div>
    
    </div>

 

 </div>
 </div>
<Footer/> </>}
</div>

  
    );
  }
  
  export default MediaCenter;