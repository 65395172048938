
 
import "../Styles/CompanySales.css";
import { useTranslation } from 'react-i18next';
function AwafiMembership() {
    const { t, i18n } = useTranslation();
    return (
<div className="awafiMembership">
    <p className="membership-title">
  {t("awafiMembership.ms-title")}</p>
        <p className="membership-text">
        {t("awafiMembership.ms-p1")}
       
        </p>
        <p className="membership-title">
        {t("awafiMembership.ms-title2")}
         </p>
        <p className="membership-text">
        {t("awafiMembership.ms-p2")} 
        </p>
        <div className="membership-list">
<p><span>1-</span>   {t("awafiMembership.t1")} 
</p>
<p><span>2-</span>{t("awafiMembership.t2")} 
 
</p>
<p><span>3-</span>
{t("awafiMembership.t3")} 

</p>
<p><span>4-</span> 
{t("awafiMembership.t4")} 


</p>
<p><span>5-</span> 
{t("awafiMembership.t5")} </p>
        </div>

</div>
  
    );
  }
  
  export default AwafiMembership;