import React, {Suspense}  from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "bootstrap/dist/css/bootstrap.css"
import  './ i18next';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <Suspense fallback={<div>Loading...</div>}>
    <App />
    </Suspense>

);


