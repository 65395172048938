import React from 'react';
import '../Styles/HTMLContent.css'

const HTMLContent = ({ htmlContent }) => {
  const containerStyle = {
    maxHeight: '400px',
    overflowY: 'auto'
  };

  return (
    <div style={containerStyle} className="html-content">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default HTMLContent;
