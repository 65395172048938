  
import "../Styles/CompanySales.css";
import Navbar from "../Components/Navbar";
import { useEffect,useState } from "react";
import Footer from "../Components/Footer";
import branches from "../Images/branches.svg"
import { BeatLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import { salesSingleMenu,Banners,clients,salesMenu,salesService } from '../api'
import CompanyServices from "../Components/CompanyServices";
import SalesCustomers from "../Components/SalesCustomers";
import ContactUs from "../Components/ContactUs";
import Slider from "../Components/Slider";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {useLocation} from 'react-router-dom'
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
//Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

function CompaniesMenu() {
    const { t, i18n } = useTranslation();
const [page,setPage]=useState(0);
const location= useLocation();
const[client,setClient]=useState([]);
let history = useNavigate();
const[saleMenu,setSalesMenu]=useState([]);
const[menu,setMenu]=useState([]);
const [loading,setLoading]=useState(true);
 
const[banners,setBanners]=useState([]);
const[salesServices,setSalesServices]=useState([]);
const { id } = useParams();


//fetch all company menus to be displayed in reccomendations
//fetch slider images
//fetch company clients
//fetch company services
//fetch single company menu with id from url
//rerenders on url change
useEffect(() => {

    window.scrollTo(0, 400);



salesMenu().then(response => {
  const data=response.data.data.salesMenus;

  Banners("sales").then(response => {
    const data=response.data.data;
   setBanners(data);
 
 
 }) 
 
 clients().then(response => {
   const data=response.data.data.salesClients;
   setClient(data);
 
 })
 

 
 salesService().then(response => {
   const data=response.data.data.salesServices;
 
   setSalesServices(data);

 
 })
 
 

  setSalesMenu(data);

 
})

salesSingleMenu(id).then(response => {
 // const data=response.data.data.salesServices;

setMenu(response.data.data)
setLoading(false)

})


 },[page,location]);
   


   //on clicking on a menu from recommendations id in url is changes and screen is rendered 

 function changePath(id){


    history(generatePath("/companies-menu/:id", { id }));
  }

   //on clicking on book menu user is redirected to /companies-inquire screen (CompanyInquire component found in /Pages) with current menu id (from current url) as params

 
  function handleBook(){


    history(generatePath("/companies-inquire/:id", { id }));
  }

    return (
    <div>
   
 
   <Slider type="website"/> 


   {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

  <div className="aboutusBody container-fluid fluid">
 

 
<div className={"bodyTitle"}>
<p>{t("companySales.sales")} </p>
<div>
<p>{t("companySales.mainText")}</p>

</div>

</div>

<div className="corporateNav cstmr" >
  
<Link to="/company-sales">
<p onClick={()=>setPage(0)} className={page==0?"Customeractive":null}> {t("companySales.menus")}</p>
</Link>

<p onClick={()=>setPage(1)}  className={page==1?"Customeractive":null}> {t("companySales.services")}</p>


<p  onClick={()=>setPage(2)}  className={page==2?"Customeractive":null}>{t("companySales.clients")}</p>

<p onClick={()=>setPage(3)} className={page==3?"Customeractive":null}> {t("companySales.contact_us")}</p>


</div> 

 
{
page==0?
<div className="container-fluid fluid">
 
<div className="row gy-4">
 
 <p  className="menuName">{i18n.language=="ar"?menu.arName:menu.enName}</p>
 <div className="menuDetail">
<p>{i18n.language=="ar"?menu.arDescription:menu.enDescription} </p>

<div style={{textAlign:"start"}}> 
 
<p style={{whiteSpace:"nowrap"}} className="minPer">  <span style={{fontSize:"24px",color:"#633235"}}>{t("companySales.min_persons")}: </span> {menu.minPersonsNumber} {t("companySales.person")}</p>
<div className="menuMin"><p style={{whiteSpace:"nowrap"}}>  <span style={{fontSize:"24px",color:"#633235"}}>{t("companySales.price")}: </span> {menu.pricePersonSAR + " "} {t("meals.sar")}</p>
<button onClick={handleBook} style={i18n.language=="ar"?{marginRight:"1em"}:{marginLeft:"1em"}}>{t("mainMenu.book_menu")}</button></div>
</div> </div>

<div className="branchesBanner" style={{ backgroundImage: `url(${menu.image})` }}>
</div>
{menu.meals.length!==0?
<div className="menuMeals">
<p className="menuName">{t("party.meals")}:</p>

<div className="row">
{menu.meals && menu.meals.map((data,index)=>
<div style={i18n.language=="ar"?{marginLeft:"1em"}:{marginRight:"1em"}} className="col-md-2 col-sm-3 col-6"> 
<p className="mealQuan">{t("mainMenu.no")+ " "} ( {data.pivotSalesMenuSalesMeal.quantity} )</p>
<div className="mealImage" style={{ backgroundImage: `url(${data.image})`} }>
</div>

<p className="mealName">{i18n.language=="ar"?data.arName:data.enName}</p>
</div>

)}
 
    </div>
    </div>:null}


<div  className="spRecomend">
<p> {t("mainMenu.anotherMenus")}</p>
<Swiper 
navigation={true} 
dir={i18n.language=="ar"?"rtl":"ltr"}
spaceBetween={10}
key={i18n.language}
breakpoints={{
  530: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  930: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
}}  

modules={[Navigation]} 

  className={i18n.language=="ar"?"recommendSlider recommmendAr":"recommendSlider recommmendEn"}>
    

    
    
      
      {saleMenu && saleMenu.map((data,index)=>


        <SwiperSlide key={index}>  
           
           <div style={{margin:"0.5em 0",paddingBottom:"1em"}}>
 
 <div className=" medyafMenu reccomendation">
 <div className='bckg' style={{backgroundImage:`url(${data.image})`}}></div>
 <div className='p-3'>
 <p className="recommend-name"> {i18n.language=="ar"? data.arName:data.enName}</p>
 <p className="recommend-price"><span style={{color:"#633235"}}>{t("companySales.price")+ "  "+":"+" "} </span> {menu.pricePersonSAR + " "} {t("meals.sar")}</p>
<button onClick={()=>changePath(data.id)}>{t("mainMenu.openMenu")}</button>
 </div>
 </div>
 </div> 
         
             </SwiperSlide>
        
        
           
             
             )
        }
      
      
      
       
       
         
      </Swiper>
{/*
<div className="spRecomend-scroller">
<Recommendations/>
<Recommendations/>
<Recommendations/>
<Recommendations/> 
<Recommendations/>
    </div>*/}
    </div>

     </div> </div>
             
:null
 
}

{
page==1  ?
<CompanyServices salesServices={salesServices}/>  
:null

}
{
page==2  ?
<SalesCustomers client={client}/>
:null

}  
{
page==3  ?
<ContactUs />
:null

}




</div>}

<Footer/> 
</div>

  
    );
  }
  
  export default CompaniesMenu;