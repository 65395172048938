import "../Styles/MainMenu.css";
import Footer from "../Components/Footer";
import appetizer from "../Images/MainMenu_Icons/appetizer.svg";
import beef from "../Images/MainMenu_Icons/beef.svg";
import chicken from "../Images/MainMenu_Icons/chicken-leg.svg";
import fried from "../Images/MainMenu_Icons/fried-chicken.svg";
import meat from "../Images/MainMenu_Icons/meat.svg";
import milkshake from "../Images/MainMenu_Icons/milkshake.svg";
import pancake from "../Images/MainMenu_Icons/pancake.svg";
import sausage from "../Images/MainMenu_Icons/sausage.svg";
import spaghetti from "../Images/MainMenu_Icons/spaghetti.svg";
import steak from "../Images/MainMenu_Icons/steak.svg";
import { useEffect, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import MenuItem from "../Components/MenuItem";
import { categories, products } from "../api";
import { CategoryContext } from "../Context/CategoryContext";
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../Firebase/firebaseConfig";
import { CustomerInfo } from "../api";
import { BeatLoader } from "react-spinners";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
function MainMenu() {
  const { categ, setCateg } = useContext(CategoryContext);
  const [category, setCategory] = useState([]);
  const [categIndex, setCategIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState([]);
  const [activeCategory, setActiveCategory] = useState(
    localStorage.getItem("activeCateg")
      ? localStorage.getItem("activeCateg")
      : null
  );
  const [searchTerm, SetSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [categLoading, setCategLoading] = useState(false);
  const [overflow, setOverflow] = useState(false);

  const [noProduct, setNoProduct] = useState(false);
  const analytics = getAnalytics(app);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  //fetch categories
  //fetch customer data for analytics and webengage use
  //fetch meals depending on current active category
  //rerenders everytime the category changes
  var language = localStorage.getItem("i18nextLng");
  useEffect(() => {
    window.scrollTo(0, 0);

    setCateg(1);
    let category_name = "";
    const data = {
      branchId: 35,
    };
    var language = localStorage.getItem("i18nextLng");

    let activeCategoryId = null;

    categories(data,language).then((response) => {
      const data = response.data.data;
      category_name = response.data.data[categIndex].enName;
      setCategory(data);
      if (localStorage.getItem("typeId") === "3") {
        activeCategoryId = response.data.data[0].id;
        setActiveCategory(response.data.data[0].id);
      } else {
        activeCategoryId =
          activeCategory == null ? response.data.data[0].id : activeCategory;
      }
      setLoading(true);
      products(
        activeCategoryId,
        localStorage.getItem("branchId"),
        language
      ).then((response) => {
        if (response.status == 200) {
          const data = response.data.data.products;
          setProduct(data);
          setLoading(false);
          setCategLoading(false);
        }
      });
    });

    const token = localStorage.getItem("token");

    let type = "";
    if (localStorage.getItem("typeId") == 1) {
      //for analytics and webengage use
      type = "branch pick up";
    } else if (localStorage.getItem("typeId") == 2) {
      type = "home delivery";
    } else {
      type = "receive from car";
    }

    if (token) {
      CustomerInfo(token).then((response) => {
        //for analytics and webengage use

        logEvent(analytics, "browse_menu", {
          phone: response.data.data.phone.toString(),
          branch: localStorage.getItem("branchId").toString(),
          type: type,
        });
        /*
  window.webengage.track("Browse Menu", {
    phone: response.data.data.phone.toString(),
    branch: localStorage.getItem("branchId").toString(),
    type: type
  })*/

        logEvent(analytics, "category_viewed", {
          phone: response.data.data.phone.toString(),
          name: category_name,
        });
        /*
  window.webengage.track("Category Viewed", {
    phone: response.data.data.phone.toString(),
    name: category_name
  })*/
      });
    }
  }, [activeCategory, language]);

  //when searching for product ,checks if search text matches and of product's data
  //if not ()=> //shows no products text
  function handleSearch(e) {
    SetSearchTerm(e.target.value);

    const products =
      product &&
      product.filter((data) => {
        if (e.target.value === "") {
          return data;
        } else if (
          data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.arDescription
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.enDescription
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return data;
        }
      });
    if (products.length == 0) {
      setNoProduct(true);
    } else {
      setNoProduct(false);
    }
  }

  /*
function isEllipsisActive(){
 let e= document.getElementsByClassName("categoryScroll");

 if(e[0]){
if(e[0].offsetWidth < e[0].scrollWidth){

  document.getElementById("overF").style.display="block";
  console.log(e[0].offsetWidth < e[0].scrollWidth)
} 

else{

  document.getElementById("overF").style.display="none";
   console.log(e[0].offsetWidth < e[0].scrollWidth)
}

}}
  */

  //isEllipsisActive();

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  //checks if categoryScroll div overflows to display an indecator (arrow)
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    let e = document.getElementsByClassName("categoryScroll");

    if (e[0]) {
      if (e[0].offsetWidth < e[0].scrollWidth) {
        document.getElementById("overF").style.display = "block";
        console.log(e[0].offsetWidth < e[0].scrollWidth);
      } else {
        document.getElementById("overF").style.display = "none";
        console.log(e[0].offsetWidth < e[0].scrollWidth);
      }
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="mainMenu container-fluid fluid">
          <div
            className={
              i18n.language == "ar"
                ? "mmSideNav "
                : "mmSideNavEn mmSideNavEn-mobile"
            }
          >
            <p className="mmsideNav-title">{t("party.categories")}</p>
            <div className="categoryScroll">
              {category &&
                category.map((data, index) => (
                  <div
                    onClick={() => {
                      if (data.id !== activeCategory) {
                        setActiveCategory(data.id);
                        localStorage.setItem("activeCateg", data.id);
                        setCategLoading(true);
                        setCategIndex(index);
                        setCateg(data.id);
                      }
                    }}
                    key={index}
                    className={
                      activeCategory == data.id ||
                      (activeCategory == null && index == 0)
                        ? "mmsideNav-link mmsideNav-linkActive"
                        : "mmsideNav-link"
                    }
                  >
                    <img src={data.image} alt="chicken" />
                    <p>{data.name && data.name} </p>
                  </div>
                ))}
            </div>
          </div>

          <div id="overF">
            {i18n.language == "en" ? (
              <IoIosArrowForward size={25} />
            ) : (
              <IoIosArrowBack size={25} />
            )}
          </div>

          {/*
  <div className="mmsideNav-link">
    <img src={beef} alt="chicken"/>
    <p>اللحوم</p>
  </div>
  <div className="mmsideNav-link">
    <img src={sausage} alt="chicken"/>
    <p>المشويات</p>
  </div>
  <div className="mmsideNav-link">
    <img src={appetizer} alt="chicken"/>
    <p>المقبلات</p>
  </div>
  <div className="mmsideNav-link">
    <img src={spaghetti} alt="chicken"/>
    <p>الاطباق الجانبية</p>
  </div>
  <div className="mmsideNav-link">
    <img src={pancake} alt="chicken"/>
    <p>الحلويات</p>
  </div>
  <div className="mmsideNav-link">
    <img src={milkshake} alt="chicken"/>
    <p>المشروبات و العصائر الطازجة</p>
  </div>
  <div className="mmsideNav-link">
    <img src={sausage} alt="chicken"/>
    <p>بوكس باربيكو ني</p>
  </div>
  <div className="mmsideNav-link">
    <img src={fried} alt="chicken"/>
    <p>بوكسبرز</p>
  </div>
  <div className="mmsideNav-link">
    <img src={steak} alt="chicken"/>
    <p>ذبائح الرومانسية</p>
  </div>
  <div className="mmsideNav-link">
    <img src={meat} alt="chicken"/>
    <p>لحم ني</p>
  </div>*/}

          <div
            style={
              i18n.language == "ar"
                ? null
                : { paddingLeft: "20px", paddingRight: "0" }
            }
            className="mmItem"
          >
            <div
              className={i18n.language == "ar" ? "mmFlex" : "mmFlex mmFlexEn"}
            >
              <p className="mmItem-title">{t("party.meals")}</p>
              <div>
                <input
                  type="text"
                  placeholder={t("party.search")}
                  onChange={(e) => handleSearch(e)}
                />
                <BsSearch
                  size={27}
                  className={
                    i18n.language == "ar" ? "mmIcon" : " mmIcon mmIconEn"
                  }
                />
              </div>
            </div>
            <div>
              {categLoading ? (
                <div style={{ height: "50vh" }} className="loader">
                  <BeatLoader
                    color={"#E47100"}
                    loading={categLoading}
                    size={30}
                  />
                </div>
              ) : (
                <div style={{ marginBottom: "100px" }} className="row g-2 ">
                  {product &&
                    product
                      .filter((data) => {
                        if (searchTerm === "") {
                          //for filtering products on search
                          return data;
                        } else if (
                          data.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          data.arDescription
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          data.enDescription
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => (
                        <>
                          <MenuItem
                            key={index}
                            name={data.name && data.name}
                            describe={
                              i18n.language == "ar"
                                ? data.arDescription
                                : data.enDescription
                            }
                            currency={
                              i18n.language == "ar"
                                ? data.arCurrency
                                : data.enCurrency
                            }
                            id={data.id}
                            price={data.price}
                            active={data.active}
                            image={data.image}
                          />
                        </>
                      ))}

                  {noProduct == true || product.length == 0 ? (
                    <p className="noProduct">{t("party.no_product")}</p>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default MainMenu;
