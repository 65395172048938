
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import * as firebase from "firebase";
import { getMessaging,getToken,onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAk4L-uHt1dH8MZIo7TcwpqmpBTXXVPqkM",
  authDomain: "romansia-771f1.firebaseapp.com",
  databaseURL: "https://romansia-771f1.firebaseio.com",
  projectId: "romansia-771f1",
  storageBucket: "romansia-771f1.appspot.com",
  messagingSenderId: "372452233487",
  appId: "1:372452233487:web:088e8142839540107fee7a",
  measurementId: "G-H5KB28LJKK"
};
  
  // Initialize Firebase
  export const app = initializeApp(firebaseConfig);
  export const messaging = getMessaging(app);



  function isSupported (){
    if(
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window){
    
    return true
  }
  else{
    return false
  }
  }


if(isSupported()){
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
     console.log("Notification permission granted.");

     
      getToken(messaging, {
        vapidKey:
        "BG_JuHHIwWQlo5WuwikM-YPAHi8ekJ-aIEplLDe6IorctpxFc6cvSwWIq8hMeEapM_N7Kcwv9IYKIrY3Bj6s_yc",
      }).then((currentToken) => {
        if (currentToken) {
           localStorage.setItem("pushToken",currentToken)
         // console.log("currentToken: ", currentToken);
        } else {
         // console.log("Can not get token");
        }
      });
    } else {
    //  console.log("Do not have permission!");
    }
  });
  
}

requestPermission();

//handleMessage();

}