import React, { useEffect, useRef, useState } from "react";
import "../Styles/mayser.css";
import { check_offer } from "../api";
import { useTranslation } from 'react-i18next';

const MoyasarPaymentForm = ({
  amount,
  onSubmit,
  codeRef,
  payagain,
  wallet,
}) => {
  const formRef = useRef(null);
  const resolveRef = useRef(null);

  const [totalPriceAfterDiscount, setTotalPriceAferDiscount] = useState();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const detail = JSON.parse(localStorage.getItem("order_details"));

    const data = {
      paymentMethod: localStorage.getItem("paymentMethod"),
      code: codeRef,
      notes: "",
      date:detail ==null ? " ":  detail.date,
      now:  detail ==null ? " ": detail.now.toString(),
      time:  detail ==null ? " ": detail.time,
    };
    if (payagain) {
      setTotalPriceAferDiscount(amount);
    } else {
      check_offer(data, localStorage.getItem("token")).then((res) => {
        setTotalPriceAferDiscount(parseFloat(res.data.data.totalPrice));
        console.log(parseFloat(res.data.data.totalPrice));
      });
    }
  }, []);

  useEffect(() => {
    // Load Moyasar script
    const moyasarScript = document.createElement("script");
    moyasarScript.src = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.js";
    moyasarScript.async = true;
    document.body.appendChild(moyasarScript);

    // Load Moyasar CSS
    const moyasarCss = document.createElement("link");
    moyasarCss.href = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.css";
    moyasarCss.rel = "stylesheet";
    document.head.appendChild(moyasarCss);

    moyasarScript.onload = () => {
      console.log(process.env.REACT_APP_MOYSER)
      console.log("2");

      if (window.Moyasar && totalPriceAfterDiscount) {
        window.Moyasar.init({
          element: ".mysr-form",
          amount: totalPriceAfterDiscount * 100,
          currency: "SAR",
          description: "test",
          language:i18n.language=="ar"? "ar" : "en",

          publishable_api_key: `${process.env.REACT_APP_MOYSER}`,
          callback_url:
            wallet == true
              ? "https://alromansiah-new.bdaiat.com/payment-check-mayser?wallet=true"
              : `https://alromansiah-new.bdaiat.com/payment-check-mayser`,
          methods: ["creditcard"],
          fixed_width: false,
          on_initiating: () => {
            return new Promise(async (resolve, reject) => {
              resolveRef.current = resolve;
              try {
                await onSubmit().then((res) => {
                  console.log(res);
                  resolve(true);
                });
              } catch (error) {
                reject(error);
              }
            });
          },
        });

        // Attach any other event listeners after the form is initialized
        if (formRef.current) {
          formRef.current.addEventListener("submit", (event) => {
            event.preventDefault(); // Prevent default form submission
          });
        }
      }
    };

    return () => {
      document.body.removeChild(moyasarScript);
      document.head.removeChild(moyasarCss);
    };
  }, [amount, onSubmit, totalPriceAfterDiscount]);

  return (
    <section
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="mysr-form" ref={formRef}></div>
    </section>
  );
};

export default MoyasarPaymentForm;
