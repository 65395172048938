
import "../Styles/CartProcess.css";
import { useTranslation } from 'react-i18next';
import { useState,useEffect,useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaxCard from "../Components/TaxCard";
import { useNavigate } from "react-router-dom";
import Logout from "../Components/Logout";
import UserNav from "../Components/UserNav";
function AccountType(props) {
  const { t, i18n } = useTranslation(); 
let history=useNavigate()
const [modal,setModal]=useState(false)

const [invoiceType,setInvoiceType]=useState(localStorage.getItem("accountType")?localStorage.getItem("accountType") :false)

    return (
        <div className="userProfile">

{modal?
      <Logout setModal={setModal} setCartItem={props.setCartItem}/>
      :null
     }

        <UserNav setModal={setModal} nav={8} /> 
     
   
  <div className="aboutusBody profileBody container-fluid fluid">
  
      <div className="upAddress"> 
      <p  className="upTable-title"> {t("profile.invoices")}</p>

      </div>
   <div className="cpMiddle-options" style={{padding:"2em"}}>


                                        
<div className="cpRadio" style={{marginTop:"0"}}> 
<p className="cpForm" > {t("profile.customerType")}:</p>

<div className="row">
<div  className="col-md-4">
<input checked={JSON.parse(invoiceType)==false} value="regular"  name="invoice" type="radio"  onChange={(e)=>{setInvoiceType(false); localStorage.setItem("accountType",false)}} />
<label>{t("profile.regular")}</label>
</div>
<div className="col">
<input checked={JSON.parse(invoiceType)} value="tax" name="invoice" type="radio"  onChange={(e)=>{setInvoiceType(true); localStorage.setItem("accountType",true); history("/profile/tax-invoice");}} />
<label>{t("profile.tax")}</label></div></div>

</div>




   </div> 

</div>

      </div>
    
        
    );
  }
  
  export default AccountType;