 
import "../Styles/FromBranch.css";
import no from "../Images/noBranch.svg"
import sp from "../Images/SingleProduct.svg"
import Footer from "../Components/Footer";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../Images/Login/logo.svg"
// Import Swiper styles
 import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation ,Autoplay} from "swiper";
import { GoogleMap,useLoadScript,Marker} from "@react-google-maps/api"
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import { useState,useEffect } from "react";
import { branches } from '../api'
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { near_branches,countries,branchesByCity,carBranches_city,Clear_cart } from '../api'
import ChangeType from "../Components/ChangeType";

 
function FromBranch(props) {
    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",
  libraries:['places']
    })
     const { t, i18n } = useTranslation();
    const [map,setMap]=useState(null);
    const [city,setCity]=useState([]);
    const[branch,setBranch]=useState([]);
    const[changeCity,setChangeCity]=useState([]);
    const[change,setChange]=useState(false);
    const[cityVlaue,setCityValue]=useState();
    const[noNear,setNoNear]=useState(false);
    const [changeModal,setChangeModal]=useState(false);
    const [title,setTitle]=useState();
let history = useNavigate();
const [loading,setLoading]=useState(true);



    const [position,setPostion]=useState({
    lat:"",
    lng:""
    
    })
 

  // if order type is car pick up ()=>
  /*the branches are filtered according to carOption if true,
  if there are near branches map position is then set to lng and lat of first branch
  else if no near branches and no chosen city text is displayed instead of the map 
  */
   // if order type is not car pick up ()=>
  /*if there are near branches map position is then set to lng and lat of first branch
  else if no near branches and no chosen city text is displayed instead of the map 
  */
//countries api call fetch cities and sets them according to current chosen country index (props.country)
    useEffect(() => { 
 

      if(localStorage.getItem("typeId")==4 ){   //sets page top navigation text
        setTitle("home.option-car")
      }
else if(localStorage.getItem("typeId")==2 ){

  setTitle("home.option-home")
}
else{

  setTitle("home.option-branch")

} 


      window.scrollTo(0, 0);
const nearBranches= JSON.parse(localStorage.getItem("nearBranches"))
console.log(nearBranches)
if(nearBranches && localStorage.getItem("typeId")==4 ){
  let near = nearBranches.filter(x => x.carOption == true);

  setBranch(near);
  if(near.length!==0){
  setPostion({

    lat:parseInt(near[0].latitude),
  lng:parseInt(near[0].longitude)
  })}
else{
  if(!cityVlaue){    setNoNear(true)}
}


}

else if(nearBranches){

  setBranch(nearBranches);
  if(nearBranches.length!==0){
  setPostion({

    lat:parseInt(nearBranches[0].latitude),
  lng:parseInt(nearBranches[0].longitude)
  })}
  else{
    if(!cityVlaue){    setNoNear(true)}
  }
}

    

  if(nearBranches && localStorage.getItem("typeId")==4 ||nearBranches && localStorage.getItem("typeId")==1){ //displays <select> for user to select city

    setChangeCity(true);

  }


  countries().then(response => {
  
  if(props.country){
    setCity(response.data.data[props.country].cities)
    setLoading(false)}
  })

  if(  !JSON.parse(localStorage.getItem("nearBranches")).length>0){
setNoNear(true)
  }
 
     },[change,props.country]);

     //loading shown if map in not yet loaded
     if(!isLoaded){
         return(
          <div className='loader'>
          <BeatLoader color={'#E47100'} loading={!isLoaded}  size={30} /></div>
         )
     }
     



/*when choosing a branch if user has already chosen another branch before and cart is not empty 
ChangeType modal is displayed for user to confirm clearing the cart */
//else chosen branch id is stored and user is redirected to /menu (MainMenu component found in /Pages)
function chooseBranch(id){
 localStorage.removeItem("activeCateg")

  const token= localStorage.getItem("token");
  let branchId=localStorage.getItem("branchId")
 
if(token && props.cartItems!==0 && branchId && branchId!==id){
setChangeModal(true);
}
else{
  localStorage.setItem("branchId",id)
  history("/menu")

}




 
}

//on confirming clearing cart (from ChangeType modal)
//user cart is cleared
function handleClear(){
  setLoading(true)
 
       const token= window.localStorage.getItem('token');
   
       Clear_cart(props.cartId,token).then(response => {
        localStorage.removeItem("promo") //was used to store data that user entered in cart
        localStorage.removeItem("paymentMethod") //was used to store data that user entered in cart
        localStorage.removeItem("cardToken")

         //data stored in cartProcess2
         localStorage.removeItem("timeNow");
         localStorage.removeItem("cartMessage");
         localStorage.removeItem("orderDate");
         localStorage.removeItem("deliveryType")
         
        localStorage.setItem("process",0); //was used to store user's current step in cart, set to 0 to begin steps from the start
        props.setCartChange(!props.cartChange) //triggers api call in (App.js) to rerender cart
        history("/menu") 
      }).catch(error => {})


      }

   
//on changing city depending on order type different apis are called
//each take the selected city id and return a set of branches 
function handleChange(e){
  setCityValue(e.target.value)
  localStorage.getItem("typeId"); 

if(  localStorage.getItem("typeId")==1 || localStorage.getItem("typeId")==2){
  branchesByCity(e.target.value).then(response => {
    localStorage.setItem("nearBranches", JSON.stringify(response.data.data));

   
  setChange(!change) //to rerender useEffect

    })}
  
  else{ 

      carBranches_city(e.target.value).then(response => {
        localStorage.setItem("nearBranches", JSON.stringify(response.data.data));
console.log(response.data.data)
        setChange(!change) //to rerender useEffect

     
        })
      
  }
  setNoNear(false)
  }

    return (
    <div>
      {loading?
        <div className='loader'>
        <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
        
<div >
{changeModal?
<ChangeType text="change_branch" handleClear={handleClear} setModal={setChangeModal}/>:null 
}

    <div className="Address">
  <div className="addressNav branchesPick container-fluid fluid">
<p>
  <Link to="/">  {t("address.home")}</Link>


{i18n.language=="ar"?
<IoIosArrowBack style={{color:"#4F4F4F"}}/>:<IoIosArrowForward style={{color:"#4F4F4F"}}/>}

<span>{t(title)}</span>

</p> 
{changeCity?

<select defaultValue={""} onChange={(e)=>handleChange(e)}>
<option value="" disabled> {t("branches.city")}</option>

{city && city.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 
</select>

:null
}

  </div>
{noNear?
<div className="container-fluid fluid chooseCity">
<div className="noCity">
 <div>
<p className="noNear">{t("branches.no_near")}</p>
<p className="choose">{t("branches.chooseCity")}</p></div>
</div></div>

:
<div  className="mapMargin" >

  <GoogleMap zoom={9} center={{lat:position.lat,lng:position.lng}} mapContainerClassName="branchMap"
        onLoad={map=>setMap(map)}
        options={{
            mapTypeControl: false,
            streetViewControl:false
        }}
        >





{branch && branch.map((data,index)=>


<Marker key={index}   
icon={logo}
position={{lat:parseInt(data.latitude ),lng: parseInt(data.longitude)}} />


   
     
     )
} 

 
        </GoogleMap>
        <div className="branchesSlider">
<div className="verticalAlign">

     {branch.length!==0?    <Swiper
navigation={true} 
dir={i18n.language=="ar"?"rtl":"ltr"}
spaceBetween={10}

key={i18n.language}
breakpoints={{
  530: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  730: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1160: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
}}   

modules={[Navigation]} 
className={i18n.language=="ar"?"myCarousel branchesCrsl btnAr":"myCarousel branchesCrsl btnEn"}>
     
   
        



     {branch && branch.map((data,index)=>

        <SwiperSlide key={index} style={data.deliveryOption!==1 && localStorage.getItem("typeId")==2?{display:"none"}:null}>
          
        <div  style={{margin:"0.5em 0",paddingBottom:"1em"}}>
 
 <div className=" branches">

   <div style={{padding:"1em"}}> 
     <div style={{display:"flex",alignItems:"center"}}>
 <div className='branchImg' style={{backgroundImage:`url(${data.image})`}}></div>

<div className="branchDetails" style={i18n.language=="ar"?{marginRight:"1em",marginLeft:"0"}:{marginLeft:"1em",marginRight:"0"}}>
<p className="branchName">{i18n.language=="ar"?data.arName:data.enName} </p>

<p className={data.open?"branchState":" branchState branchStateClosed"}>{data.open? t("branches.open"):t("branches.Closed")}</p>
<p className="branchTime">{data.workingFrom}- {data.workingTo}</p>
</div></div>
<p className="branchPosition"><span style={i18n.language=="ar"?{marginLeft:"0.5em"}:{marginRight:"0.5em"}}><HiOutlineLocationMarker size={30}/></span>
{i18n.language=="ar"?data.arAddress:data.enAddress}
</p>
</div>


 <div style={{textAlign:"center"}}>
 

      <div className="menubottom">
         

          
         <button onClick={()=>chooseBranch(data.id)} style={{height:"65px"}}  className={i18n.language=="en"?"enArrow-button2 arrow-button2":"arrow-button2"} >{t("branches.choose")}<span className="arrow2"></span>
</button>


</div>
 </div>

 </div>
 </div>
 

        </SwiperSlide>)}
       
       
       
      </Swiper> :null }

 
{/*branch.length==0?
<div className="noBranch">
<div style={{backgroundImage:`url(${no})`}}></div>
<p>{t("branches.no_near")}</p>

 
     </div> :null */}

        </div>

  </div></div>}
</div>
   <Footer/>
</div>}
</div>
  
    );
  }
  
  export default FromBranch;