import "../Styles/InvoicePdf.css";
import QRCode from "react-qr-code";
import invoiceImg from "../Images/fullLogo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef, useEffect, useState } from "react";
import { invoiceData } from "../api";
import { BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
import { FaDownload } from "react-icons/fa";

function InvoicePdf() {
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(false);

  const orderId = window.location.href.split("/")[4].split("-")[0];
  const orderCode = window.location.href.split("/")[4].split("-")[1];

  useEffect(() => {
    invoiceData(orderId, orderCode)
      .then((response) => {
        setInvoice(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const contentRef = useRef(null);

  const handleDownloadPDF = () => {
    const input = contentRef.current;

    html2canvas(input, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imageWidth = canvas.width;
      const imageHeight = canvas.height;

      const scaleFactor = Math.min(
        pdfWidth / imageWidth,
        pdfHeight / imageHeight
      );

      const scaledWidth = imageWidth * scaleFactor;
      const scaledHeight = imageHeight * scaleFactor;

      pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <>
      <div className="container-fluid fluid" style={{ direction: "ltr" }}>
        <div className="pdfBtn">
          <button onClick={handleDownloadPDF}>
            تنزيل الفاتورة <FaDownload id="downloadIcn" />
          </button>
        </div>
        <div className="App" ref={contentRef}>
          {loading ? (
            <div className="loader">
              <BeatLoader color={"#E47100"} loading={loading} size={30} />
            </div>
          ) : (
            <>
              <div className="header">
                <div
                  className="invoiveLogo"
                  style={{ backgroundImage: `url(${invoiceImg})` }}
                ></div>
                <p>
                  Tax Invoice
                  <br />
                  فاتورة ضريبية
                </p>
                {invoice.invoiceQR ? (
                  <QRCode
                    style={{ height: "15vw", width: "15vw" }}
                    value={invoice.invoiceQR}
                    viewBox={`0 0 256 256`}
                  />
                ) : null}
              </div>
              <div className="body">
                <div className="dataDiv">
                  <div>
                    <p>
                      Invoice Date :<span>{invoice.createdAt}</span>
                    </p>
                  </div>

                  <div>
                    <p style={{ direction: "rtl" }}>
                      {" "}
                      تاريخ الفاتورة :<span>{invoice.createdAt}</span>
                    </p>
                  </div>
                </div>
                <div className="dataDiv">
                  <div>
                    <p>
                      Invoice No :<span>{invoice.id}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      رقم الفاتورة :<span>{invoice.id}</span>
                    </p>
                  </div>
                </div>
                <div className="dataDiv">
                  <div>
                    <p>
                      Supply Date :<span>{invoice.date}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      تاريخ التوريد :<span>{invoice.date}</span>
                    </p>
                  </div>
                </div>

                <div className="dataDiv">
                  <div>
                    <p>
                      Issue Time :<span>{invoice.time}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      وقت الاصدار :<span>{invoice.time}</span>
                    </p>
                  </div>
                </div>

                <div className="dataDiv">
                  <div>
                    <p>
                      Cuurency :<span>{invoice.currencyEn}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      {" "}
                      العملة :<span>{invoice.currencyAr}</span>
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    marginBottom: "1.5em",
                    borderBottom: "1px solid #4747471b",
                  }}
                  className="dataDiv"
                >
                  <div>
                    <p>
                      Payment Type :<span>{invoice.paymentMethod}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      {" "}
                      نوع الدفع :
                      <span>
                        {" "}
                        {invoice.paymentMethod == "cash"
                          ? "كاش"
                          : invoice.paymentMethod == "wallet"
                          ? "المحفظة"
                          : " بطاقة ائتمانية"}{" "}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="dataDiv">
                  <div>
                    <p>
                      Seller Name :<span> شركة الرومانسية مساهمة مقفلة</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      اسم البائع :<span> شركة الرومانسية مساهمة مقفلة</span>
                    </p>
                  </div>
                </div>
                <div className="dataDiv">
                  <div>
                    <p>
                      Seller Address :<span>{invoice.invoiceAddress}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      {" "}
                      عنوان البائع :<span> {invoice.invoiceAddress}</span>
                    </p>
                  </div>
                </div>
                <div className="dataDiv">
                  <div>
                    <p>
                      Branch :<span>{invoice.branchEnName}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      الفرع :<span> {invoice.branchArName} </span>
                    </p>
                  </div>
                </div>
                <div className="dataDiv">
                  <div>
                    <p>
                      VAT NO :<span>{invoice.taxNumber}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      الرقم الضريبي :<span> {invoice.taxNumber} </span>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "1.5em",
                    borderBottom: "1px solid #4747471b",
                  }}
                  className="dataDiv"
                >
                  <div>
                    <p>
                      {" "}
                      CR NO :<span>{invoice.commercialRegister}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      {" "}
                      السجل التجاري :<span> {invoice.commercialRegister} </span>
                    </p>
                  </div>
                </div>

                <div className="dataDiv">
                  <div>
                    <p>
                      Customer Name :<span>{invoice.userName}</span>
                    </p>
                  </div>
                  <div>
                    <p style={{ direction: "rtl" }}>
                      {" "}
                      اسم العميل :<span> {invoice.userName} </span>
                    </p>
                  </div>
                </div>
                {invoice.typeId == 2 ? (
                  <div className="dataDiv">
                    <div>
                      <p>
                        Customer Address :<span> {invoice.address}</span>
                      </p>
                    </div>
                    <div style={{ direction: "rtl" }}>
                      <p>
                        {" "}
                        عنوان العميل : <span> {invoice.address} </span>
                      </p>
                    </div>
                  </div>
                ) : null}

                <div className="dataDiv">
                  <div>
                    <p>
                      Mobile No :<span> {invoice.userPhone}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      جوال العميل :<span> {invoice.userPhone} </span>
                    </p>
                  </div>
                </div>
                {invoice.userVat && (
                  <div className="dataDiv">
                    <div>
                      <p>
                        VAT NO :<span>{invoice.userVat}</span>
                      </p>
                    </div>

                    <div>
                      <p>
                        الرقم الضريبي :<span> {invoice.userVat} </span>
                      </p>
                    </div>
                  </div>
                )}
                {invoice.userCr && (
                  <div className="dataDiv">
                    <div>
                      <p>
                        {" "}
                        CR NO :<span>{invoice.userCr}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        {" "}
                        السجل التجاري :<span> {invoice.userCr} </span>
                      </p>
                    </div>
                  </div>
                )}
                <div
                  className="dataDiv"
                  style={{
                    marginBottom: "1.5em",
                    borderBottom: "1px solid #4747471b",
                  }}
                ></div>
                <div className="tableBody">
                  <table className="productsTable">
                    <tr>
                      <th>
                        Total Amt <br />
                        المبلغ الاجمالي
                      </th>
                      <th>
                        Qty <br />
                        الكمية
                      </th>
                      <th>
                        Total Price <br /> اجمالي السعر
                      </th>
                      <th>
                        Unit Tax <br />
                        ضريبة الوحدة
                      </th>
                      <th>
                        Unit Price <br /> سعر الوحدة
                      </th>
                      <th>
                        Description
                        <br /> وصف الصنف
                      </th>
                      <th>
                        S.No
                        <br /> م
                      </th>
                    </tr>
                    <tbody>
                      {invoice.products &&
                        invoice.products.map((data, index) => (
                          <tr key={index}>
                            <td>{data.totalAmount}</td>
                            <td>{data.quantity}</td>

                            <td>{data.unitPriceWithTax}</td>
                            <td>{data.tax}</td>
                            <td>{data.unitPrice}</td>
                            <td>{data.productArName}</td>
                            <td>{index + 1}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <table className="totalTable">
                    <tbody>
                      <tr>
                        <td>{invoice.priceWithoutTax}</td>
                        <th>الاجمالي باستثناء ضريبة القيمة المضافة</th>
                        <th>Total (Excluding VAT)</th>
                      </tr>
                      <tr>
                        <td>{invoice.discount || 0}</td>
                        <th>اجمالي الخصم</th>
                        <th>Total Discount</th>
                      </tr>
                      <tr>
                        <td>
                          
                          {(
                            invoice.priceWithoutTax - invoice.discount
                          ).toFixed()}
                        </td>
                        <th>
                          {" "}
                          اجمالي المبلغ الخاضع للضريبة <br /> (باستثناء ضريبة
                          القيمة المضافة)
                        </th>
                        <th>
                          Total Taxable Amount <br /> (Excluding VAT)
                        </th>
                      </tr>
                      <tr>
                        <td>{invoice.taxValue}</td>
                        <th>اجمالي ضريبة القيمة المضافة</th>
                        <th>Total VAT</th>
                      </tr>
                      {invoice.paymentMethod == "wallet" ? (
                        <tr>
                          <td>{invoice.walletPay}</td>
                          <th> الجزء المدفوع من المحفظة</th>
                          <th>Portion paid with wallet</th>
                        </tr>
                      ) : null}

                      <tr>
                        <td>{invoice.totalPrice}</td>
                        <th> الاجمالي شامل ضريبة القيمة المضافة</th>
                        <th>Total (Including VAT)</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="invoiceFooter">
                  <p>
                    العميل مسؤول مسؤولية كاملة عن صحة البيانات الخاصة به
                    المذكورة في الفاتورة
                    <br />
                    The customer is fully responsible for the validity of his
                    data mentioned in the invoiceImg
                    <br />
                    For Customer Care Call 920000144 للاتصال بخدمة العملاء
                  </p>
                </div>
              </div>{" "}
            </>
          )}
        </div>{" "}
      </div>
      <Footer />{" "}
    </>
  );
}

export default InvoicePdf;
