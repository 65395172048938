import React, { useEffect, useState } from "react";
import successImage from "../Images/success-web.svg";
import failedImage from "../Images/faild-web.svg";
import { useNavigate } from "react-router-dom";
import { DotLoader, BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
import { makeAccept, charge_wallet } from "../api"; // Import the charge_wallet API function
import MoyasarPaymentForm from "../Components/mayser";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PaymentCheckMayser() {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const navigate = useNavigate();
  const [currentOrderID, setOrderId] = useState(
    localStorage.getItem("tempOrderId")
  );
  const [isWallet, setIsWallet] = useState(false);
  const [payMoyserForm, setPayMoyserForm] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const amountParam = params.get("amount");
  const wallet = params.get("wallet");

  useEffect(() => {
    const status = params.get("status");
    const transaction_id = params.get("id");

    const message = params.get("message");

    const amount = amountParam ? parseFloat(amountParam) / 100 : null;

    if (status === "paid") {
      const data = {
        transactionId: transaction_id,
      };

      if (wallet === "true" && amount) {
        const countryId = localStorage.getItem("countryId");
        const token = localStorage.getItem("token");
        setIsWallet(true);

        // Prepare wallet charging data
        let walletData = {
          transactionId: transaction_id, // Payment token (ID) from the URL
          amount: amount, // Amount divided by 100
          paymentMethod: "moyasar", // Payment method
          platform: "web", // Platform is web
        };

        charge_wallet(token, walletData, countryId)
          .then((response) => {
            console.log("Wallet charged successfully:", response);
            setLoading(false);
            localStorage.removeItem("process");
            setIsSuccess(true);
          })
          .catch((error) => {
            console.error("Error charging wallet:", error);
            setLoading(false);
            localStorage.removeItem("process");
            setIsSuccess(false);
          });
      } else {
        makeAccept(currentOrderID, localStorage.getItem("token"), data)
          .then((res) => {
            console.log("Order accepted successfully");
            setLoading(false);
            localStorage.removeItem("process");
            setIsSuccess(true);
          })
          .catch((error) => {
            console.error("Error in makeAccept:", error);
            setLoading(false);
            setIsSuccess(false);
          });
      }
    } else {
      setLoading(false);
      localStorage.removeItem("process");
      setIsSuccess(false);
    }
    localStorage.removeItem("process");
  }, []);

  const payAgain = () => {
    setPayMoyserForm(true);
  };

  function handleProceed() {
    navigate(`/order-track/${currentOrderID}`);
  }

  return (
    <div>
      {payMoyserForm && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content"
              style={{
                maxWidth: "600px",
                width: "600px !important",
                margin: "auto",
              }}
            >
              <div className="modal-header">
                {/* <h5 className="modal-title">Payment</h5> */}
                {/* <button type="button" className="btn-close" onClick={() => setPay(false)}></button> */}
              </div>
              <div className="modal-body">
                <MoyasarPaymentForm
                  payagain={true}
                  amount={parseFloat(amountParam) / 100}
                  onSubmit={async () => {
                    try {
                      await new Promise(async (resolve, reject) => {
                        try {
                          // Call createOrder and wait for it to finish
                          resolve();
                        } catch (error) {
                          // If createOrder fails, reject the promise
                          reject(error);
                        }
                      });
                    } catch (error) {
                      console.error("Order creation failed:", error);
                    }
                  }}
                />
              </div>
              <div style={{ marginTop: "50px" }} className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setPayMoyserForm(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div
          style={{ marginTop: "5em", marginBottom: "5em" }}
          className="container orderSuccess"
        >
          <div className="shareForm">
            <img
              src={isSuccess ? successImage : failedImage}
              alt={isSuccess ? "Success" : "Failed"}
              style={{ marginBottom: "1em" }}
            />
            <p style={{ color: "rgb(99, 50, 53)" }}>
              {isSuccess
                ? wallet === "true"
                  ? t("payment.success_charge")
                  : t("payment.success_payment")
                : wallet === "true"
                ? t("payment.notSuccess_charge")
                : t("payment.notSuccess_payment")}
            </p>

            {isSuccess ? (
              wallet === "true" ? (
                <Link to="/charge-wallet">
                  <button style={{ display: "inline-block" }}>
                    {t("profile.backCharge")}
                  </button>
                </Link>
              ) : (
                <button
                  style={{ display: "inline-block" }}
                  onClick={handleProceed}
                >
                  {t("payment.track_order")}
                </button>
              )
            ) : wallet === "true" ? (
              <Link to="/charge-wallet">
                <button style={{ display: "inline-block" }} >
                {t("profile.backCharge")}
              </button>
              </Link>
              
            ) : (
              <button style={{ display: "inline-block" }} onClick={payAgain}>
                {t("payment.pay_again")}
              </button>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default PaymentCheckMayser;
