
import "../Styles/CartProcess.css";
import { useState,useEffect } from 'react';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import food from "../Images/MainMenu_Icons/FoodImage.svg"
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {  Edit_Quantity  } from '../api'

function CartItem(props) {
  const { t, i18n } = useTranslation();
  const [quan,setQuan]=useState(props.quantity);
 


  useEffect(()=>{

    setQuan(props.quantity)
  },[props.quantity])

const history= useNavigate()
 
//on adding or subtracting quantity
//Edit_Quantity api call takes cart id, meal id and new quantity
  function handleQuantity(cartId,mealId,quantity){
    const token= window.localStorage.getItem('token');

    Edit_Quantity(cartId,mealId,quantity,token).then(response => {
      setQuan(quantity);
      localStorage.removeItem("paymentMethod")
      localStorage.removeItem("offer");
      localStorage.removeItem("promo") //remove value of promo code

      props.setCalcTotal(!props.calcTotal); //triggers api call in (App.js) to rerender cart
    }).catch(err => {

      if(err.response.status === 401){
        //used to change cart icon's current value to 0
  
        props.setCartItem(0) 

  
      localStorage.removeItem("token");
      localStorage.removeItem("userPhone");
      localStorage.removeItem("userName");
      localStorage.removeItem("basket");
      //was used to store user's current step in cart process
      localStorage.removeItem("process");
  
     history("/login")
   
      
      
        }
    })
    
  }

 
    
 
    return (
     
   <>

   
  <div className="row cpMiddle-item">


    <div className="col-6">
   <div className="row">
   
    <div className="col alignImg">
  
<div  className='itemImg' style={{ backgroundImage: `url(${props.image})` }}>

</div>  
 
    </div>  
    <div style={{textAlign:"initial"}} className="col">
    <p className="cpMiddle-p1"> {props.name}</p>
    <p className="cpMiddle-p2">{props.description}</p>

    {
     JSON.stringify(props.additional)!=="{}"?
    <>
    <p className="cpMiddle-p3">{t("meals.additions")}</p>
  

 
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? props.additional.arName:props.additional.enName}</p>
<span></span>
</>


</>  
  :null
    }



    {
    props.extras && props.extras.length>0?
    <>
    <p className="cpMiddle-p3">{t("meals.extras")}</p>
  

{props.extras &&  props.extras.map((data,index)=>

  
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.arName:data.enName}</p>
{index !== props.extras.length-1?
<span>	&#160; - 	&#160; </span>: null}
</>

     )
}
</>  
  :null
    }


    
    </div>
   </div>
    </div>
    <div className="col cpMiddle-center">
    <p >{props.price} {props.currency}</p> 
    </div>
    <div className="col-2 cpMiddle-center">
        <button  onClick={()=>handleQuantity(props.cartId,props.mealId,quan+1)} >+</button>
    <p className="cpMiddle-quantity">{quan}</p>
    <button onClick={()=> quan!==1? handleQuantity(props.cartId,props.mealId,quan-1):null}>-</button>
    </div>
    <div className="col cpMiddle-center">
    <p>{props.totalPrice} {props.currency}</p>
    </div> 
    
<div className="col  cpMiddle-center">
  <button onClick={()=>{
    props.setModal(true)
    props.setDelData({
     id: props.mealId,
     index: props.index,
    
     enName: props.enName,
     name: props.arName,
     quantity:quan}
      
      
      )}
    } className="cartRemove">
  
  <RiDeleteBin5Line size={30}/></button></div>
  </div>

  <div style={{display:"none"}} className='col-12 mobileItem'>
        <div className='bItem'>
        <div className="mobileCartItem row">
        <div className='col-4 alignImg'> 
               
               <div  className='basketItemImg' style={{ backgroundImage: `url(${props.image})` }}>
         
        </div>
               </div>
                <div className='col-8'>
                    <div >
                   {/*<GrClose onClick={props.handleCls(props.id)} className='pointer' size={25}/>*/}
                    <RiDeleteBin5Line onClick={()=>{
    props.setModal(true)
    props.setDelData({
      id: props.mealId,
     index: props.index,
    
     enName: props.enName,
     name: props.arName,
     quantity:quan}
      
      
      )}}  className={i18n.language=="ar"? 'pointer delBtn cartRemove':'pointer delBtn delBtnEn cartRemove' } size={25}/>
                    <div>
                 <p  className="cpMiddle-p1">  {props.name}</p>
                 <p  className="cpMiddle-p2">{props.description}</p>

           
                 {
                  
                  JSON.stringify(props.additional)!=="{}"?
            <> 
            <p className="cpMiddle-p3">{t("meals.additions")}</p>
        
       
        <>
        <p className="cpMiddle-p4">{i18n.language=="ar"?   props.additional.arName:  props.additional.enName}</p>
        <span style={{margin:"0 0.5em"}}></span>
        </>
        
       
        </>  
          :null
            }


            
                 {
  props.extras && props.extras.length>0?
            <>
            <p className="cpMiddle-p3">{t("meals.extras")}</p>
        
            {props.extras &&  props.extras.map((data,index)=>
         
        <>
        <p className="cpMiddle-p4">{i18n.language=="ar"? data.arName:data.enName}</p>
        {index !== props.extras.length-1?
<span>	&#160; - 	&#160; </span>: null}
        </>
        
             )
        }
        </>  
          :null
            }
                 </div>
                 </div>
                 <div className='bBottom bFlex'>
                 <p   className='bPrice'>{props.price+ " "}   {props.currency}</p>
             
                     <div className="cpMiddle-center">
        
                    <button  onClick={()=> quan!==1? handleQuantity(props.cartId,props.mealId,quan-1):null} className='bBtn'>-</button> 
                    <p className="quantityNum">{quan}</p> 
                 
                    <button  onClick={()=>handleQuantity(props.cartId,props.mealId,quan+1)}  className='bBtn'>+</button> 
                    </div></div>
                </div> 
               
                </div>
        
        </div>
        </div>
       </>
    );
  }
  
  export default CartItem;