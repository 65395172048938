  

import Footer from "../Components/Footer";
import certified from "../Images/certified.png"
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import certified2 from "../Images/certified2.png"
import certifiedN from "../Images/certified1.png"

function Iso() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
    
        window.scrollTo(0, 0);},[])

    return (
    <div>
    

 

  <div className="isoBody container-fluid fluid">
 
<div className="isoHeader">


<p>{t("iso.iso")}</p>
<div className="row">
<div className="col-md-8">
   
   
    <div className="isoDiv" style={{marginTop:"0"}}>
    <p className="isoP">{t("iso.iso2")}</p>
    <div className="membership-list">
<p><span>1-</span>   {t("iso.iso-p1")} 
</p>
<p><span>2-</span> {t("iso.iso-p2")} 
 
</p>
<p><span>3-</span>
{t("iso.iso-p3")} 

</p>
<p><span>4-</span> 
{t("iso.iso-p4")} 


</p>
<p><span>5-</span> 
{t("iso.iso-p5")} </p>

<p><span>6-</span> 
{t("iso.iso-p6")} </p>
        </div>
    </div>
    <div className="isoDiv" style={{marginTop:"0"}}>
    <p className="isoP">{t("iso.iso4")}</p></div>
    </div>
    <div className="col-md-4 isoImg">
    <div  className='div5img' style={{ backgroundImage: `url(${certifiedN})`,height:"237px" ,marginBottom:"2em"}}>
</div>
<div  className='div5img' style={{ backgroundImage: `url(${certified2})`,height:"237px"  }}>
</div>
    </div>

    </div>

</div>
 


</div>

<Footer/> 
</div>

  
    );
  }
  
  export default Iso;