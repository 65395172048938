
import "../Styles/Branches.css";
import Navbar from "../Components/Navbar";

import Footer from "../Components/Footer";
import branches from "../Images/branches.svg"
import branch from "../Images/BranchImage.svg"
import { FaPhone } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BackgroundImage } from 'react-image-and-background-image-fade'
 

function Branch(props) {
  const { t, i18n } = useTranslation();



  
    return (
  

    <div class="col-lg-4 col-sm-6 ">
      <div class="branch">
      {/*  <div className="branchImage" loading=" lazy" style={{ backgroundImage: `url(${props.image})` }}>

    </div>*/}
        
        <BackgroundImage
        src={props.image}
       width="100%"
   
       className="branchImage"
      />

        <div >
      <p className="brnchName">{props.name}</p>
      <p className="brnchLocation">{props.address}</p>
        <div className="openHours">
        <p className={props.open?"brnchState":" brnchState brnchStateClosed"}> {props.open? t("branches.open"):t("branches.Closed")}</p>
        <p className="brnchTime">{props.workingFrom} - {props.workingTo}</p>
   </div>
   <div className="brnchBtns"> 
   <a  rel="noopener noreferrer" href={`tel:${props.phone}`} >
   <button className="brnchBtn2"><span style={{margin:"0 0.5em"}}><FaPhone size={20}/></span></button>
</a>
<span className="spacing" ></span>
   <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${props.latitude},${props.longitude}`}>
   <button className="brnchBtn1"><span style={{margin:"0 0.2em"}}><HiOutlineLocationMarker size={20}/></span></button>
 </a>
   </div>
        </div> 
        </div>
    </div>
  
 

  
    );
  }
  
  export default Branch;