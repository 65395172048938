import { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { create_order } from "../api";
import visa from "../Images/visa.png";
import master from "../Images/masterc.png";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";
import { Get_Wallet, charge_wallet } from "../api";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import MoyasarPaymentForm from "../Components/mayser";

function ChargeWallet(props) {
  const { t, i18n } = useTranslation();
  let history = useNavigate();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [minChargeWallet, setMinChargeWallet] = useState();
  const [showMoyserForm, setShowMoyserForm] = useState(false);

  const [maxChargeWallet, setMaxChargeWallet] = useState();
  const [onlineType, setOnlineType] = useState();

  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();

  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [errorMsgModel2, setErrorMsgModel2] = useState(false);
  const [cardValid, setCardValid] = useState(false);

  const numRef = useRef();

  //Get_Wallet api call to get maximum amount allowed to be charged by the user
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const token = window.localStorage.getItem("token");
    localStorage.removeItem("walletToken");
    let countryId = props.country == 0 ? 1 : 2;
    Get_Wallet(token, countryId)
      .then((response) => {
        setMinChargeWallet(response.data.data.minChargeWallet);

        setMaxChargeWallet(response.data.data.maxChargeWallet);
        setOnlineType(response.data.data.paymentOnlineType);

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          window.location.pathname = "/verify";
        } else {
          window.location.pathname = "/login";
        }
      });
  }, [props.country]);

  //takes amount to be charged
  // checks if that amount is more than the maximum allowed (allowed amount is known from Get_Wallet api call above)
  //user is then redirected to third party website
  //third party website redirects user to payment-success screen (PaymnetSuccess component)
  function handleSubmit(e) {
    if (!e.token) {
      e.preventDefault();
    } else {
      localStorage.setItem("walletToken", JSON.stringify(e));
    }
    setLoading(true);
    const token = window.localStorage.getItem("token");

    if (parseInt(numRef.current.value) < minChargeWallet) {
      setMsgAr("الحد الادنى لشحن المحفظة " + minChargeWallet);
      setMsgEn("Minimum  Amount for charging wallet " + minChargeWallet);
      setErrorMsgModel2(true);
      setLoading(false);
      setSubmitLoading(false);
    } else if (parseInt(numRef.current.value) > maxChargeWallet) {
      setMsgAr("الحد الاقصي لشحن المحفظة " + maxChargeWallet);
      setMsgEn("Max Amount for charging wallet " + maxChargeWallet);
      setErrorMsgModel2(true);
      setLoading(false);
      setSubmitLoading(false);
    } else {
      let data = {
        token: onlineType == "checkout" ? e.token : "",
        amount: numRef.current.value,
        paymentMethod: onlineType == "checkout" ? "checkout" : "tap",
        platform: "web",
      };
      let countryId = props.country == 0 ? 1 : 2;

      charge_wallet(token, data, countryId)
        .then((response) => {
          localStorage.setItem("transactionType", "wallet");
          if (onlineType == "checkout") {
            if (
              response.data.data.url !== "" &&
              response.data.data.url !== null
            ) {
              localStorage.setItem(
                "currencyCode",
                props.country == 0 ? "SAR" : "BH"
              );
              window.location.href = response.data.data.url;
              localStorage.removeItem("walletToken");
              localStorage.setItem("checkPayment", "wallet");
              history("/profile/myWallet");
            } else {
              localStorage.removeItem("walletToken");

              history("/profile/myWallet");
            }
          } else if (response.data.message === "success") {
            localStorage.setItem("payment", "wallet");
            localStorage.setItem("checkPayment", "wallet");

            window.location.href = response.data.data.url;
            history("/profile/myWallet");
          } else {
            setMsgAr("لا يمكن إتمام العملية");
            setMsgEn("The operation could not be completed");
            setErrorMsgModel(true);
            setLoading(false);
            setTimeout(() => {
              setErrorMsgModel(false);
              history("/profile/myWallet");
            }, 10000);
          }
        })
        .catch(function (err) {
          setSubmitLoading(false);
          if (err.response.status === 401) {
            //used to change cart icon's current value to 0

            props.setCartItem(0);

            localStorage.removeItem("token");
            localStorage.removeItem("userPhone");
            localStorage.removeItem("userName");
            localStorage.removeItem("basket");
            //was used to store user's current step in cart process
            localStorage.removeItem("process");

            history("/login");
          } else if (
            err.response.data.error ===
            "Transaction has been failed,please try again"
          ) {
            localStorage.setItem("failType", "wallet");
            setErrorMsgModel(true);
            setMsgAr("حدث فشل في عملية الدفع، برجاء المحاولة مرة أخرى");
            setMsgEn("Transaction has been failed, please try again");
            setLoading(false);
            setTimeout(() => {
              setErrorMsgModel(false);
              history("/profile/myWallet");
            }, 10000);

            Frames.init();
          } else {
            setMsgAr("حدث خطأ، لا يمكن إتمام العملية");
            setMsgEn("Something wrong, The operation could not be completed");
            setErrorMsgModel(true);
            setLoading(false);
            setTimeout(() => {
              setErrorMsgModel(false);
            }, 10000);
          }
        });
    }
  }

  return (
    <div>
      <div className="container-fluid fluid ">
        <div style={{ marginTop: "2em" }} className="cpMiddle-title2">
          <div
            style={{ textAlign: "center" }}
            className="cpPayment chargeWallet"
          >
            {loading ? (
              <div style={{ height: "45vh" }} className="loader">
                <BeatLoader color={"#E47100"} loading={loading} size={30} />
              </div>
            ) : null}
            <div style={loading ? { display: "none" } : null}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <p>{t("profile.charge")}</p>

                <input
                  ref={numRef}
                  type="number"
                  placeholder={t("profile.enterCredit")}
                  required={true}
                  onFocus={() => setShowMoyserForm(false)}
                  onBlur={() => setShowMoyserForm(true)}
                  className={errorMsgModel2 ? "invalid" : null}
                />
                {i18n.language == "ar" ? (
                  <span className="errMsg">
                    {errorMsgModel2 ? msgAr : null}
                  </span>
                ) : (
                  <span className="errMsg">
                    {errorMsgModel2 ? msgEn : null}
                  </span>
                )}

                <div style={{ marginTop: "1em" }} className="chrgCenter">
                  <input type="radio" id="payment" defaultChecked />
                  <label htmlFor="payment">{t("process.credit")}</label>
                </div>
                <div className="chrgCenter">
                  <img src={visa} width="50px" />
                  <span></span>
                  <img src={master} width="50px" />
                </div>

                {i18n.language == "ar" ? (
                  <span className="errMsg">{errorMsgModel ? msgAr : null}</span>
                ) : (
                  <span className="errMsg">{errorMsgModel ? msgEn : null}</span>
                )}

                {onlineType == "moyasar" && showMoyserForm ? (
                  <MoyasarPaymentForm
                    wallet={true}
                    payagain={true}
                    amount={parseInt(numRef.current.value)}
                    onSubmit={async () => {
                      try {
                        await new Promise(async (resolve, reject) => {
                          try {
                            // Call createOrder and wait for it to finish
                            // localStorage.setItem("tempOrderId", orderData.id);
                            resolve();
                          } catch (error) {
                            // If createOrder fails, reject the promise
                            reject(error);
                          }
                        });
                      } catch (error) {
                        console.error("Order creation failed:", error);
                      }
                    }}
                  />
                ) : null}

                {onlineType == "checkout" ? (
                  <div
                    className="creditCard"
                    style={{
                      margin: "auto",
                      marginTop: "1.5em",
                      maxWidth: "600px",
                    }}
                  >
                    <Frames
                      cardValidationChanged={(e) => {
                        setCardValid(e.isValid);
                      }}
                      config={{
                        publicKey: `${process.env.REACT_APP_PAYMENT_METHOD_KEY}`,
                        schemeChoice: true,
                      }}
                      cardTokenized={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <CardNumber />
                      <div className="date-and-code">
                        <ExpiryDate />
                        <Cvv />
                      </div>

                      {submitLoading ? (
                        <div
                          style={{
                            height: "6vh",
                            marginTop: "2em",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                          className="loader"
                        >
                          <BeatLoader
                            color={"#E47100"}
                            loading={submitLoading}
                            size={30}
                          />
                          <p className="paymentLoad">
                            {t("payment.processing")}
                          </p>
                        </div>
                      ) : (
                        <button
                          disabled={!cardValid}
                          id="pay-button"
                          onClick={() => {
                            setSubmitLoading(true);

                            if (localStorage.getItem("walletToken")) {
                              handleSubmit(
                                JSON.parse(localStorage.getItem("walletToken"))
                              );
                            } else {
                              Frames.submitCard();
                            }
                          }}
                        >
                          {t("process.continue")}
                        </button>
                      )}
                    </Frames>
                  </div>
                ) : onlineType === "moyasar" ? null : (
                  <div className="chargeBtn">
                    <button type="submit">{t("process.continue")}</button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ChargeWallet;
