import React, {useEffect, useState} from 'react';

import success from "../Images/success-web.svg";
import failed from"../Images/faild-web.svg";

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import wallet from"../Images/wallet.svg";
import { app } from "../Firebase/firebaseConfig";
import Footer from '../Components/Footer';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import {invoice, tamaraConfirmPayment} from "../api";
import { Link } from 'react-router-dom'; 
function InvoiceSuccess(props){
 const  [loading,setLoading]=useState(true);
 const { t, i18n } = useTranslation();
  const [state,setState]=useState(false);

    useEffect(() => {

      window.scrollTo(0, 0);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id') || params.get('invoice_id') ;
        let tamaraOrderId = params.get("orderId");
        console.log(tapId)


        

        if(tapId){
          alert(tapId)
            invoice(tapId).then((response) => {

                setState(true)
                setLoading(false);
     
            }).catch(function (error) {
                    setState(false)
                    setLoading(false);
                     });

        } else if(tamaraOrderId !== "") {
          const data = {
            transactionId:tamaraOrderId
          }
          tamaraConfirmPayment(data).then((response)=> {
            localStorage.setItem("process", 0);
    
    
            
            setState(true);
            setLoading(false);
          }).catch((e)=> {
            console.log(e)
            setLoading(false);
            setState(false)

    
          })
        }
        
        else {
            setState(false)
            setLoading(false);
           
        }
        
      } ,[ ]);


     
        return (
          <div>{loading?<div className='loader'>
          <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <>

<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
  
{state? <div className='shareForm '>
   <img style={{marginBottom:"1em"}} src={success}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.success_payment")}
  </p>
  
   </div>:


<div className='shareForm '>
   <img style={{marginBottom:"1em"}}  src={failed}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.notSuccess_payment")}
  </p>

   </div>
    
    
   }
    </div>
                

     </>   
                }
                <Footer/>
                </div>
           
        )
    }


    export default InvoiceSuccess;
