import "../Styles/AboutUs.css";
import Navbar from "../Components/Navbar";
import { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer";
import branches from "../Images/branches.svg";
import medal from "../Images/medal-star.svg";
import { useTranslation } from "react-i18next";
import Slider from "../Components/Slider";
import { publications, medias } from "../api";
import { Link } from "react-router-dom";
import { useParams, generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Banners, terms_conditions } from "../api";
import { BackgroundImage } from "react-image-and-background-image-fade";

function Terms(props) {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [publication, setPublication] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const [term, setTerms] = useState();
  let history = useNavigate();

  //fetch slider images with Banners api call
  //fetch publication data
  //fetch media data
  useEffect(() => {
    setLoading(true);
    let language = i18n.language == "ar" ? "ar" : "en";
    let countryId = props.country == 0 ? 1 : 2;

    Banners("website", language, countryId).then((response) => {
      const data = response.data.data;
      setBanners(data);
    });

    publications().then((response) => {
      setPublication(response.data.data);
    });

    terms_conditions().then((res) => {
      const data = res.data.data;
      setTerms(data);
      console.log(data);
    });

    medias().then((response) => {
      setMedia(response.data.data);

      console.log(response.data.data);

      setLoading(false);
    });
  }, [i18n.language, props.country]);

  //on clicking on a media div in media center user is redirected to /news (MediaCenter component found in /Pages) screen with chosen media div id as params
  const handleProceed = (postId) => {
    history(generatePath("/news/:postId", { postId }));
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <Slider banners={banners} />
          <div className="aboutusBody container-fluid fluid">
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>

            <div className={"bodyTitle"}>
              <p>{t("nav.terms")} </p>
              <div>
                {term && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "ar"
                          ? term.arDescription
                          : term.enDescription,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default Terms;
