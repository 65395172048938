
import "../Styles/CarDelivery.css";
import { useState,useRef,useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import AddressCard from "../Components/AddressCard";
import { Link } from "react-router-dom";
import AddAddress from "./AddAddress";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { GoPlusSmall } from "react-icons/go";
import AddressList from "./AddressList";
import car from "../Images/cardelivery.svg"
import * as yup from "yup"
import {useFormik} from "formik";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { countries,carBranches} from '../api'
import CountriesModal from "../Components/CountriesModal";
import useGeoLocation from "../Components/useGeoLocation";
function CarDelivery() { 
    const [page1,setPage1]=useState(true);
    const { t, i18n } = useTranslation();
    const [country,setCountry]=useState([]);
    const location = useGeoLocation();
    const [countryModal,setCountryModal]=useState(false);
const history=useNavigate();
    const Schema= yup.object().shape({
      color: yup.string().required(("validation.color_required")),
      model: yup.string().required(("validation.model_required")),
     
      number: yup.string().required(("validation.number_required")),
    
       
      })

      const formik = useFormik({
        initialValues:{
          color:"",
          model:"",
          number:"",
        },
        validationSchema: Schema,
        onSubmit,
        
        });
        const colorRef=useRef();
    const modelRef=useRef();
    const notesRef=useRef();
    const numberRef=useRef();

 

 /* Car details are stored to be later used in payment, then checks if the custom hoook useGeoLocation is given permission to get user lat and lng
if yes it will search for near branches using them and will store near branches and redirect to /branches screen (FromBranch component found in /Pages)  */

/* else user is also redirected to /branch and nearbranches are set to an empty array*/ 
    function onSubmit(){
  localStorage.setItem("carColor",colorRef.current.value);
  localStorage.setItem("carmodel",modelRef.current.value);
  localStorage.setItem("carNumber",numberRef.current.value);
  localStorage.setItem("carNotes",notesRef.current.value);
  localStorage.setItem("typeId", 4); 

if(location.loaded && location.coordinates){


  carBranches(location.coordinates.lat,location.coordinates.lng).then(response => {
    localStorage.setItem("nearBranches", JSON.stringify(response.data.data));
    localStorage.setItem("addressLat", location.coordinates.lat);
    localStorage.setItem("addressLong", location.coordinates.lng);
    
  history("/branch")
  })}
  
  else{
    localStorage.setItem("nearBranches", JSON.stringify([]));
    history("/branch")
  }
    }  
    useEffect(() => {
    
      window.scrollTo(0, 0);},[])
    return (
    
   
<div > 
{countryModal?
<CountriesModal country={country} setModal={setCountryModal}/>:null 
}
    <div className="Address container-fluid fluid">
  <div  className="addressNav ">
<p> <Link to="/">  {t("address.home")}</Link>
{i18n.language=="ar"?
<IoIosArrowBack style={{color:"#4F4F4F"}}/>:<IoIosArrowForward style={{color:"#4F4F4F"}}/>}

<span>   {t("home.option-car")}</span></p>

  </div>
<div >
  <div className="addressBody">

    <div className="row addressInput vAlign">
      
     
        <div className="col-md-6 ">
        <form  onSubmit={formik.handleSubmit}>
            <div style={{textAlign:"center"}}>
        <p className="carTitle">   {t("car.carDetails")}</p></div>
        <div className="row gy-4">
        <div className="col-12">
        <label>  {t("car.carColorEnter")}</label>
        <input placeholder={t("car.carColor")} value={formik.values.color} id="color" name="color"  ref={colorRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.color && formik.touched.color ?"invalid":null}/>
        <span >{formik.errors.color && formik.touched.color?t(`${formik.errors.color}`):null}</span>
        </div>
        <div className="col-12">
        <label>  {t("car.carModelEnter")}  </label>
        <input placeholder={t("car.carModel")} value={formik.values.model} id="model" name="model"  ref={modelRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.model && formik.touched.model ?"invalid":null}/>
        <span>{formik.errors.model && formik.touched.model?t(`${formik.errors.model}`):null}</span>
        </div>
        <div className="col-12">
        <label>  {t("car.carNumberEnter")} </label>
        <input placeholder= {t("car.carNumber")} value={formik.values.number} id="number" name="number"  ref={numberRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.number && formik.touched.number?"invalid":null}/>
        <span>{formik.errors.number && formik.touched.number?t(`${formik.errors.number}`):null}</span>  
        </div>

        <div className="col-12">
        <label>  {t("basket.enter_your_notes")} </label>
        <textarea placeholder= {t("basket.notes")} id="notes" name="notes"  ref={notesRef} required={false}   />
        </div>
        
        <div className="col-12">
        <button type="submit">   {t("car.confirm")} </button></div>
        </div>
        </form>
            </div>
            <div className="col-md-6 vAlign order-first order-md-last">
        <img src={car}/>
        </div>
        </div>
 
  </div>
  </div>
</div>
   <Footer/>
</div>

  
    );
  }
  
  export default CarDelivery;