import React, { useEffect, useState } from "react";

import success from "../Images/success-web.svg";
import failed from "../Images/faild-web.svg";

import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import wallet from "../Images/wallet.svg";
import { app } from "../Firebase/firebaseConfig";
import Footer from "../Components/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { payment, pay_again, order_track } from "../api";
import { Link } from "react-router-dom";
function PaymentSuccess(props) {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [type, setType] = useState();
  const analytics = getAnalytics(app);

  const [state, setState] = useState(false);

  const [paymentLoad, setPaymentLoad] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const TranId = params.get("TranId");
  let history = useNavigate();
  const [orderData, setOrderData] = useState();
  const transactionType = localStorage.getItem("transactionType");

  //CustomerInfo api call to get customer info for webengage and analytics
  //payment api call checks if operation was succeccfull and which type was it (order / wallet)
  //depending on type of operation different html is rendered
  //if operation failed if user was previously charging their wallet, user is redirected to  /charge-wallet (ChargeWallet component in /Pages)
  //else display is changed showing failure of operation
  useEffect(() => {
    window.scrollTo(0, 0);
    let phone = "";
    CustomerInfo(localStorage.getItem("token")).then((response) => {
      setPhone(response.data.data.phone.toString());
      phone = response.data.data.phone.toString();
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    let tapId = params.get("tap_id");

    const transactionType = localStorage.getItem("transactionType");
    if (tapId !== "") {
      logEvent(analytics, "purchase", {
        transaction_id: tapId,
      });
      payment(tapId, localStorage.getItem("token"))
        .then((response) => {
          if (transactionType == "subscription") {
            history("/profile/alromansiah-plus");
          } else {
            localStorage.setItem("process", 0);

            setType(response.data.data);

            if (response.data.data == "order") {
              logEvent(analytics, "checkout_completed", {
                phone: phone,
              });
              /*
                  window.webengage.track("Checkout Completed", {
                    phone: phone,
                  })*/
            }
            setState(true);
            setLoading(false);
          }
        })

        .catch(function (error) {
          if (localStorage.getItem("payment") == "tap") {
            setType("order");
            logEvent(analytics, "payment_failure", {
              phone: phone,
            });
            /*
                  window.webengage.track("Payment Failure", {
                    phone: phone,
                  })*/

            order_track(
              localStorage.getItem("orderId"),
              localStorage.getItem("token")
            ).then((response) => {
              //get order data for use in analytics and webengage
              setOrderData(response.data.data);

              setState(false);
              setLoading(false);
            });
          } else {
            setLoading(false);
          }
        });
    }
    
    else {
      setState(false);
      setLoading(false);
    }
  }, []);

  //if operation was successful and user clicks on track order
  //user is redirected to /order-track (OrderTracking component in /Pages) with order id as params

  function handleProceed() {
    var id = localStorage.getItem("orderId");
    history(generatePath("/order-track/:id", { id }));
  }

  //if operation failed and user clicks on pay again
  //pay_again api call takes order id and redirects user to third party service
  function payAgain() {
    setPaymentLoad(true);
    let values = {
      transactionId: TranId,
    };
    pay_again(localStorage.getItem("orderId"), localStorage.getItem("token"))
      .then((response) => {
        logEvent(analytics, "checkout_started", {
          phone: phone,
          amount: orderData.totalPrice,
          currency: orderData.enCurrency,
        });
        logEvent(analytics, "begin_checkout", null);
        /*
              window.webengage.track("Checkout Started", {
                phone: phone,
                amount: orderData.totalPrice,
                currency: orderData.enCurrency
              })
*/

        window.location.href = response.data.data.url;
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");
          history("/login");
        }
        setPaymentLoad(false);
      });
  }

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          {type == "order" ? (
            <div
              style={{ marginTop: "5em", marginBottom: "5em" }}
              className="container orderSuccess"
            >
              {state ? (
                <div className="shareForm ">
                  <img style={{ marginBottom: "1em" }} src={success} />

                  {localStorage.getItem("typeId") === "3" ? (
                    <>
                      <p>{t("home.thanksForDonation")}</p>
                      <button
                        style={{ display: "inline-block" }}
                        onClick={handleProceed}
                      >
                        {" "}
                        {t("payment.track_order")}
                      </button>
                    </>
                  ) : (
                    <>
                      <p style={{ color: "rgb(99, 50, 53)" }}>
                        {t("payment.success_payment")}
                      </p>

                      <button
                        style={{ display: "inline-block" }}
                        onClick={handleProceed}
                      >
                        {" "}
                        {t("payment.track_order")}
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <div className="shareForm ">
                  <img style={{ marginBottom: "1em" }} src={failed} />

                  <p style={{ color: "rgb(99, 50, 53)" }}>
                    {t("payment.notSuccess_payment")}
                  </p>

                  {paymentLoad ? (
                    <div style={{ marginTop: "50px" }} className="loaderDiv">
                      <BeatLoader
                        color={"#E47100"}
                        loading={paymentLoad}
                        size={30}
                      />
                    </div>
                  ) : (
                    <button
                      style={{ display: "inline-block" }}
                      onClick={payAgain}
                    >
                      {" "}
                      {t("payment.pay_again")}
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{ marginTop: "3em", marginBottom: "5em" }}
              className="container orderSuccess"
            >
              {state ? (
                <div className="shareForm ">
                  <img style={{ marginBottom: "1em" }} src={wallet} />

                  <p style={{ color: "rgb(99, 50, 53)" }}>
                    {t("payment.success_charge")}
                  </p>
                  <Link to="/profile/myWallet">
                    <button style={{ display: "inline-block" }}>
                      {" "}
                      {t("profile.backWallet")}
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="shareForm ">
                  <img style={{ marginBottom: "1em" }} src={failed} />

                  <p style={{ color: "rgb(99, 50, 53)" }}>
                    {t("payment.notSuccess_payment")}
                  </p>
                  {transactionType == "subscription" ? (
                    <Link to="/profile/alromansiah-plus">
                      <button style={{ display: "inline-block" }}>
                        {" "}
                        {t("profile.backPlus")}
                      </button>
                    </Link>
                  ) : (
                    <Link to="/charge-wallet">
                      <button style={{ display: "inline-block" }}>
                        {" "}
                        {t("profile.backCharge")}
                      </button>
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}{" "}
        </>
      )}
      <Footer />
    </div>
  );
}

export default PaymentSuccess;
