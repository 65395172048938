 
import "../Styles/UserProfile.css";
import { useTranslation } from 'react-i18next';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BeatLoader } from "react-spinners";
import { useRef,useState } from "react";
import { setDefault } from "../api";

function TaxCard(props) {
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(false)
const inputRef=useRef()

function makeDefault(){
  const token= localStorage.getItem("token");
  setLoading(true)
  setDefault(token,{userOrganizationId:props.data.id}).then((response) => {

    localStorage.setItem("organizationId",props.data.id)
    localStorage.setItem("defaultOrganizationId",props.data.id)
    props.setActiveTax(props.data.id)
    props.setDefaultTax(props.data.id)
    setLoading(false)

   }).catch((err)=>{
       })

}

    return (
      
  

    <div style={{cursor:"pointer"}} onClick={()=> inputRef.current.click()} className="col-sm-6">
      <div style={{height:"100%"}} className={props.activeTax == props.data.id?"p-3 taxCard taxCardActice": "p-3 taxCard"}>
       
      <div>
     
      <div className='taxTitle'>




 <input type="radio" ref={inputRef}  name="organization" checked={props.data.id==props.activeTax} value={props.data.id} onChange={(e)=>{localStorage.setItem("organizationId",e.target.value); props.setActiveTax(props.data.id)}}/>
    
    <p style={i18n.language=="ar"?{marginRight:"1em"}:{marginLeft:"1em"}}>
    {props.data.organizationName}    </p> 
      
      


    </div>


      <div className='row taxData'>
    <div className='col-12'>
    <p className='address-st'>{t("profile.name")} :<span>
    {props.data.fullName}
      </span>  </p>

    </div>
    <div className='col-12'>
    <p className='address-st'>{t("profile.phone")} : 
    
    <span>
    {props.data.phone}
      </span> 
      
 </p>

    </div>
    <div className='col-12'>
    <p className='address-st'>{t("profile.email")} :

    <span>
    {props.data.email}
      </span> 
       </p>

    </div>

    <div className='col-12'>
    <p className='address-st'>{t("profile.taxNumber")} : 
    
    <span>
    {props.data.taxNumber}
      </span> 
      
 </p>

    </div>

    <div className='col-12'>
    <p className='address-st'>{t("profile.commercial")} : 
    
    <span>
    {props.data.commericalRegisterNo}
      </span> 
      
</p>

    </div>
    <div className='col-12'>
    <p className='address-st'>{t("profile.city")} :
    
    <span>
    {i18n.language=="ar"?props.data.city.arName:props.data.city.enName} 
      </span> 
      
      
   </p>

    </div>
  
   
    <div className='col-12'>
    <p className='address-st'>{t("profile.street")} :
    <span>
    {props.data.streetName}  </span> 
      
      
      
     </p>

    </div>
    {props.data.postalCode!==null && props.data.postalCode!==""?
    <div className='col-12'>
    <p className='address-st'>{t("profile.postal")} :
    <span>
    {props.data.postalCode } </span> 
      
      
   </p>

    </div>:null}
    <div className='col-12'>
    <p style={{marginBottom:"0"}} className='address-st'>{t("profile.organizationAddress")} : 
    <span>
    {props.data.address}  </span> 
      
      
       </p>

    </div>
</div>
</div>
{props.data.id== props.defaultTax?null:


loading?
  <div style={{height:"60px"}} className='loader'>
  <BeatLoader color={'#E47100'}   size={20} /></div>:
<button onClick={makeDefault}>{t("profile.default")}</button>

}
      
          </div>
    </div>
   
 
    );
  }
  
  export default TaxCard;