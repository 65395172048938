

import "../Styles/CartProcess.css";
import { useTranslation } from 'react-i18next';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import food from "../Images/MainMenu_Icons/FoodImage.svg"

function OrderSummary(props) {
  const { t, i18n } = useTranslation();
    return (
     
    
  
  <div className="row osItem">


    <div className="col-md-6">
   <div className="row">
    
    <div className="col ">
      <div className="alignV">
    <div  className='itemImg' style={{ backgroundImage: `url(${props.image})` }}>
    </div>
</div> 
    </div>  
    <div style={{textAlign:"initial"}} className="col">
    <p className="cpMiddle-p1"> {props.name}</p>
    <p className="cpMiddle-p2">{props.description}</p>

    {
    props.additionals && props.additionals.length>0?
    <> 
    <p className="cpMiddle-p3">{t("meals.additions")}</p>
  
  
{props.additionals &&  props.additionals.map((data,index)=>

   
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.additionalArName:data.additionalEnName}</p>
{index !== props.additionals.length-1?
<span>	&#160; - 	&#160; </span>: null}
</>

     )
}
</>  
  :null
    }

    {
    props.extras && props.extras.length>0?
    <>
    <p className="cpMiddle-p3">{t("process.extras")}</p>
  
  
{props.extras &&  props.extras.map((data,index)=>

   
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.arName:data.enName}</p>
{index !== props.extras.length-1?
<span>	&#160; - 	&#160; </span>: null}
</>

     )
}
</>  
  :null
    }
    </div>
   </div>
    </div>
    <div className="col cpMiddle-center">
    <p className="cpMiddle-p3">{t("process.price")}</p>
    <p > {  props.price/props.quantity}  {props.currency}</p>
    </div>
    <div className="col cpMiddle-center">
    <p className="cpMiddle-p3">{t("process.quantity")}</p>
    <p style={{lineHeight:"20px"}} className="cpMiddle-quantity">{props.quantity}</p>
   
    </div>
    <div className="col cpMiddle-center">
    <p className="cpMiddle-p3">{t("process.total")}</p>
    <p >{ props.price}  {props.currency}</p>
    </div>
    

  </div>

    
       
    );
  }
  
  export default OrderSummary;