  

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import { BeatLoader } from 'react-spinners';
import { app } from "../Firebase/firebaseConfig";
function Logout(props) {

const { t, i18n } = useTranslation();
const [phone,setPhone]=useState("");
const [loading,setLoading]=useState(true);
const history= useNavigate();
const analytics = getAnalytics(app);
 

//CustomerInfo api call takes user token => used to get user phone  (for analytics and webengage)
useEffect(() => {
  const token= localStorage.getItem("token");

CustomerInfo(token).then((response) => {

setPhone((response.data.data.phone).toString())

})},[])


//logout removes certain items from local storage 
   //user is then directed to home screen
function handleLogout(){
  //used to change cart icon's current value to 0
  props.setCartItem(0) 

  logEvent(analytics, 'logout', {
    phone: phone
  });
  /*
  window.webengage.track("Logout", {
    phone: phone
  })*/

    localStorage.removeItem("token");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("userName");
    localStorage.removeItem("basket");
    //was used to store user's current step in cart process
    localStorage.removeItem("process");
    localStorage.removeItem("accountType");
    localStorage.removeItem("organizationId");

    localStorage.removeItem("defaultOrganizationId")

    history("/");

    
    } 

    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content logoutModal" style={{height:"auto",width:"50vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
             <div style={{height:"20vh",flexDirection:"column",textAlign:"center",justifyContent:"space-around"}} className='modalBody'>
         <p className='cancelOrder'>
          {t("profile.logout-modal")}</p>
           <div className='modalBtns'>
           <button className='confirm' onClick={handleLogout}>     {t("profile.logout")}</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     {t("profile.cancel")}</button></div>
            </div>
           
     
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default Logout;
 