import React, { useEffect, useState } from "react";

import success from "../Images/success-web.svg";
import failed from "../Images/faild-web.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { CustomerInfo, payAgain } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import wallet from "../Images/wallet.svg";
import { app } from "../Firebase/firebaseConfig";
import Footer from "../Components/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { payment, pay_again, order_track } from "../api";
import { Link } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { payAgain_checkout } from "../api";
import { checkPayment, checkPaymentToken } from "../api";
import { DotLoader } from "react-spinners";
function InvoiceCheck(props) {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [cardValid, setCardValid] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [warning, setWaringing] = useState(false);
  const [arErr, setArrErr] = useState("");
  const [enErr, setEnrErr] = useState("");

  const [state, setState] = useState(false);
  const paymentType = "order";

  const [paymentLoad, setPaymentLoad] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let orderId = window.location.href.split("/")[4].split("-")[0];
  const token = localStorage.getItem("token");
  const [authorized, setAuthorized] = useState(false);

  let history = useNavigate();

  useEffect(() => {
    setLoading(true);
  
    const checkResponseAndRetry = (attempt = 0) => {
      checkPayment(null, "SAR", "order", orderId)
        .then((response) => {
          console.log(response.data.status)
          if (response.data.status === "Authorized") {
            if (attempt < 6) {
              checkResponseAndRetry(attempt + 1);
            } else {
              setAuthorized(false);
              setMsgEn("Unable to complete the transaction after multiple attempts.");
              setLoading(false);
            }
          } else if (response.data.status === "Captured") {
            setAuthorized(false);
            history("/profile/myWallet");
            setLoading(false);
          } else {
            setState(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          setState(false);
          setLoading(false);
          if (error.response && error.response.data.message === "warning") {
            setWaringing(true);
            setArrErr("لقد تمت عملية الدفع بالفعل");
            setEnrErr(error.response.data.error);
          }
        });
    };
  
    checkResponseAndRetry();
  }, []);
  

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div
            style={{ marginTop: "5em", marginBottom: "5em" }}
            className="container orderSuccess"
          >
            {state ? (
              <div className="shareForm ">
                {authorized ? (
                  <div
                    style={{ height: "10vw", marginBottom: "1.5em" }}
                    className="loader"
                  >
                    <DotLoader color={"#E47100"} size={80} />
                  </div>
                ) : (
                  <img style={{ marginBottom: "1em" }} src={success} />
                )}

                <p style={{ color: "rgb(99, 50, 53)" }}>
                  {authorized
                    ? t("payment.waiting")
                    : paymentType == "order"
                    ? t("payment.success_payment")
                    : t("payment.success_charge")}
                </p>
              </div>
            ) : (
              <>
                {warning ? (
                  <div className="shareForm ">
                    <img style={{ marginBottom: "1em" }} src={failed} />

                    <p style={{ color: "rgb(99, 50, 53)" }}>
                      {i18n.language == "ar" ? arErr : enErr}
                    </p>
                  </div>
                ) : (
                  <div className="shareForm ">
                    <img style={{ marginBottom: "1em" }} src={failed} />

                    {/* <p style={{ color: "rgb(99, 50, 53)" }}>
                      {paymentType == "order"
                        ? t("payment.notSuccess_payment")
                        : t("payment.notSuccess_charge")}
                    </p> */}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default InvoiceCheck;
