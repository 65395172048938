
import "../Styles/UserProfile.css";

import coin from "../Images/TransactionCoin.svg"
import bg from "../Images/TransactionBg.svg"

import PointExchange from "../Components/PointExchange";
import { useTranslation } from 'react-i18next';
import {  points } from '../api'
import { useState,useEffect } from "react";
import Card from "../Components/Card";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
function UserPoints(props) {
  const { t, i18n } = useTranslation();
 
const[point,setPoints]=useState(0);

const[loading,setLoading]=useState(true);
const [modal,setModal]=useState(false)

//fetch user points balance and current point price
  useEffect(()=>{

 
    const token= window.localStorage.getItem('token');
  

    points(token).then(response => {
      console.log("false")
      setPoints(response.data.data)
    
   setLoading(false)
    })
  
  },[ ])
 
 
    return (
    
      <div> 
      {modal?
      <Logout setModal={setModal} setCartItem={props.setCartItem}/>
      :null
     }
      <div className="userProfile">
      <UserNav setModal={setModal} nav={3} /> 
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div className="aboutusBody profileBody container-fluid fluid">
 
<>

    <p  className="upTable-title"> {t("profile.awafi_member")}</p>
    
        <div className="row gy-4">
        <div className="col-12" style={{marginBottom:"1em"}}>
<Card  joinDate={point.joinDate} pointPrice={point.pointPrice} pointsBalance={point.pointsBalance} username={point.username} currency={i18n.language=="ar"?point.arCurrency:point.enCurrency}/>
            </div>
        <div className="col-12">
           
     
           
           <PointExchange setCartItem={props.setCartItem} joinDate={point.joinDate} pointPrice={point.pointPrice} pointsBalance={point.pointsBalance} username={point.username} currency={i18n.language=="ar"?point.arCurrency:point.enCurrency}/>
 
       
     </div>
          
      </div>
      
      </>
      </div>
    }

</div>
   <Footer/>
         </div>


  
    );
  }
  
  export default UserPoints;