import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { BsCreditCard } from "react-icons/bs";
import { useTranslation } from "react-i18next";


const PaymentToggle = ({ selectedPayment, setSelectedPayment, wallet }) => {
  const handlePaymentSelection = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };
  const { t, i18n } = useTranslation();
  const walletTextPosition =
    i18n.language === "en" ? { marginLeft: "25px" } : { marginRight: "25px" };
  const creditCardMargin =
    i18n.language === "en" ? { marginRight: "10px" } : { marginLeft: "10px" };

  return (
    <div className="payment-toggle">
      <div className="payment-options">
        {/* <label
          className={`payment-option ${
            selectedPayment === "wallet" ? "selected" : ""
          }`}
          onClick={() => handlePaymentSelection("wallet")}
        >
          <input
            type="radio"
            name="payment"
            value="wallet"
            checked={selectedPayment === "wallet"}
            onChange={() => {}}
          />
          <IoWalletOutline size={24} />
          <span>{t('profile.wallet')}</span>
          <span style={walletTextPosition} >({wallet})</span>

        </label> */}

        <label
          className={`payment-option ${
            selectedPayment === "credit" ? "selected" : ""
          }`}
          onClick={() => handlePaymentSelection("credit")}
        >
          <input
            type="radio"
            name="payment"
            value="credit"
            checked={selectedPayment === "credit"}
            onChange={() => {}}
          />
          <BsCreditCard size={24} style={creditCardMargin} />
          <span>{t('process.credit')}</span>
        </label>
      </div>
    </div>
  );
};

export default PaymentToggle;
