  


import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { branchesByCity,carBranches_city} from '../api'
function CountriesModal(props) {

const { t, i18n } = useTranslation();
const history=useNavigate();
const [cities,setCities]=useState({

    state:false,
    index:0
});
 
const [selectedOption,setSelectedOption]=useState();

const handleOptionChange = (changeEvent) => {
    setSelectedOption(JSON.parse( changeEvent.target.value));

    }


  /*On choosing city if chosen delivery type is 1 (from branch) we search for near branches by chosen city 
  else (it is delivery from car) we search near branches by chosen city but using a different api*/

function handleCity(){
    localStorage.getItem("typeId"); 
  
if(  localStorage.getItem("typeId")==1){
    branchesByCity(selectedOption.id).then(response => {
        localStorage.setItem("nearBranches", JSON.stringify(response.data.data));
      
      
      history("/branch")
      })}
    
    else{

        carBranches_city(selectedOption.id).then(response => {
            localStorage.setItem("nearBranches", JSON.stringify(response.data.data));
         console.log(response)
          
         history("/branch")
          })
        
    }
    }



    /*Countries are first displayed and upon choosing one, cities of chosen country (by index) are displayed instead*/
    return (
 

        <div  className="foodModal">
         
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw ", maxWidth:"400px",minWidth:"366px",paddingTop:"2em"}}>
          
            <div >
            {!cities.state?
<p className='countryTitle'>{t("Login.chooseCountry")}</p>: <p className='countryTitle'>{t("branches.chooseCity")}</p>}
                {!cities.state?

             <div className='countryList'>
  
  {props.country && props.country.map((data,index)=>

<div key={index}>
    <input type="radio" name="country" onChange={()=>setCities({  state:true,
    index:index})}/>
<label>{i18n.language=="ar"?data.arName:data.enName}</label>



</div>
   
     
     )
} 
            </div>:
              <div className='countryList'>
  
              {props.country[cities.index].cities && props.country[cities.index].cities.map((data,index)=>
            
            <div key={index}>
                <input type="radio" value={JSON.stringify({enName:data.enName,arName:data.arName,id:data.id})} name="city" onChange={(e)=>handleOptionChange(e)}/>
            <label>{i18n.language=="ar"?data.arName:data.enName}</label>
            
            
            
            </div>
               
                 
                 )
            } 
<div style={{textAlign:"center"}}>
<button onClick={handleCity} className='confirm'style={{width:"50%",marginTop:"1em"}}  >     {t("Login.confirm")}</button></div>
                        </div>
            
            }
          
     
            </div>
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
          </div>
        </div>
    

    );
}

export default CountriesModal;
 