//user is redirected here from login if their account is old and needs verification
import '../Styles/ChangPass.css';
import mobile from "../Images/Login/mobile.svg"

import changePass from "../Images/Login/changePass.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { IoIosPhonePortrait } from "react-icons/io";
import {CustomerInfo2,resendCode,usercode} from "../api";

import { BeatLoader } from 'react-spinners';
import Navbar from "../Components/Navbar";
import { useTranslation } from 'react-i18next'; 
import Footer from "../Components/Footer";

function Otp() {

let history = useNavigate();
  const { t, i18n } = useTranslation();
  const [userPhone,setUserPhone]=useState(JSON.stringify( localStorage.getItem("userPhone")));
  const [loggedIn,setLoggedIn]=useState(false);
      const [otp,setOtp]=useState("");
      const [loading,setLoading]=useState(false);
      const [seconds,setSeconds]=useState(59);
      const [showSec,setShowSec]=useState(false);

      const [msgAr, setMsgAr]=useState();
      const [msgEn, setMsgEn]=useState();
      
      const [errorMsgModel, setErrorMsgModel]=useState(false); //error shows under input field
      const [errorMsgModel2, setErrorMsgModel2]=useState(false); //error shows under resend div
  
var timer;
     const handleChange = (otp) =>{ setOtp( otp );
        console.log(otp);

     }  

     useEffect(() => {
      localStorage.setItem("prevScreen","redirect"); //for use in loggin (if prevScreen = redirect logging in redirects to home screen instead of previous screen)
    
     },[]);

 
//(takes otp code) if otp is correct user information is fetched then user is redirected to home screen

     function handleSubmit(e){
      e.preventDefault();
      setErrorMsgModel2(false);
      setLoading(true)
    const  data = {
      phone: localStorage.getItem("userPhone"),
        phoneVerificationCode: otp
    }

const token =   localStorage.getItem("token");

usercode(data).then((response) => {
      if (response.data.message === "success") {
        console.log(response);
        localStorage.setItem("verified",JSON.stringify(true))
          localStorage.setItem("token", response.data.data.access_token);
          CustomerInfo2(token).then((response) => {
            localStorage.setItem("userName",response.data.data.name)
            history("/")})
        
          }

          else {

               
              setMsgAr("الكود غير صحيح")
              setMsgEn("Invalid code");
              setErrorMsgModel(true);
              setErrorMsgModel2(false);
              setLoading(false)
              console.log(response);
        }


    }).catch(function (error) {
      setMsgAr("الكود غير صحيح")
              setMsgEn("Invalid code");
         
              setErrorMsgModel(true);
              setErrorMsgModel2(false);
              setLoading(false)
          
 
        })

    

    }


//api to resend otp
     function resend(){
      setOtp( null )
      setLoading(true)
      let params = {
          phone: localStorage.getItem("userPhone"),
      };
      resendCode(params).then((response) => {
       
              setMsgAr("تم إرسال الكود");
              setMsgEn("Your code has been send");
              setErrorMsgModel(false); 
              setErrorMsgModel2(true);
              setShowSec(true)
              setLoading(false)
              let second= 59;
              setInterval(()=>{ //disables resend button and shows timer
              
                if(second>0){
                 second=second-1
                 setSeconds(second)
                 setShowSec(true)
             
            
              
             }
                else{
                 setErrorMsgModel2(false);
                  setShowSec(false)
               
                }
           
              },1000)
      }).catch(function (error) {
          // console.error(error);
      });
  };
  
   return (
        <div>
          
        
        <div className='loginContainer container-fluid fluid'>
            
<div style={{padding:"3.5em 2em",position:"relative"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  className='row'>
<div className='col-md-6 passAlign'>
<img className='otpImage' src={mobile}/>
<p className='forgot'>      {t("Login.checkPhone")}</p>

<p className='otpPhone'>{userPhone}</p>
<form className='otpForm'  onSubmit={(e)=>handleSubmit(e)}>
    <div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
  <label>  {t("Login.EnterVerify")} </label>
  <div id="otp">
    
    <OtpInput
     
     value={otp}
     onChange={(otp)=>handleChange(otp)}
        numInputs={6} 
       
      />
  </div></div> 
  <div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
 {i18n.language=="ar"?
 <span className='invalidOtp'>{errorMsgModel? msgAr:null}</span>:
 <span  className='invalidOtp'>{errorMsgModel? msgEn:null}</span>}

 </div>
<button type="submit">{t("Login.confirm")}</button>
</form>
<div style={{textAlign:"left"}}>
<p className='resendChangePass'> {t("Login.notRecive")}  <span className='resendSpan' style={showSec?{cursor:"default",color:"#4F4F4F"}:null} onClick={ !showSec? resend:null}> {t("Login.resend")}</span> <span style={{fontSize:"16px"}}>{showSec? `00:${seconds}`:null} </span> </p>

 
</div>
{i18n.language=="ar"?
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgAr:null}</span>:
 <span style={{textAlign:"left"}} className='invalidOtp'>{errorMsgModel2? msgEn:null}</span>}
</div>

<div className={i18n.language=="ar"?'col-md-6 passPhoneImage':"col-md-6 passPhoneImageEn"}>
  
<img src={changePass}/>

</div>
</div>
</div>
</div>
<Footer/>
</div>
    );
}

export default Otp;