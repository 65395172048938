
import "../Styles/UserProfile.css";

import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import AddressCard from "../Components/AddressCard";
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect,useState } from "react";
import ProfileAddAddress from "./ProfileAddAddress";
import { saved_address,delete_address } from '../api'
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import DelAddress from "../Components/DelAddress";
import useGeoLocation from "../Components/useGeoLocation";
import { useNavigate } from "react-router-dom";
function MyAddresses(props) {
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(true);
  const [ addAddress, setAddAddress]= useState(false);
  const [address,setAddresses]=useState([]);
  const [reload,setReload]=useState(false);
  const [modal,setModal]=useState(false)
  const [delModal,setDelModal]=useState(false)
  const [delId,setDelId]=useState()
  const location = useGeoLocation();
  const history=useNavigate();

  const[loc,setLoc]=useState(
 {lat:0,
   lng:0
 }
 
  ); 
 
  
//fetch user addresses
  useEffect(() => {
    const token= localStorage.getItem("token");
    let countryId= props.country==0? 1:2;
    setLoading(true);

    saved_address(token,countryId).then(response => {
     const data=response.data.data;
     setAddresses(data);
setLoading(false);

  })
  
   
  
  
   },[reload,addAddress,props.country]);

     
   //on confirimg delete address in DelAddress modal 
   //delete_address api takes address id (this id gets set when user clicks on an AddressCard delete button)
function handleDelete(){

  const token= localStorage.getItem("token");
  setLoading(true);

  delete_address(delId,token).then(response => {
  
 setReload(!reload); //to rerender screen and update displayed addresses
 }).catch(err => {

  if(err.response.status === 401){
    //used to change cart icon's current value to 0

    props.setCartItem(0) 


  localStorage.removeItem("token");
  localStorage.removeItem("userPhone");
  localStorage.removeItem("userName");
  localStorage.removeItem("basket");
  //was used to store user's current step in cart process
  localStorage.removeItem("process");

 history("/login")

  
  
    }
}) 

}



//on clicking add new address
 /*checks if the custom hoook useGeoLocation is given permission to get user lat and lng
and stores the values for use in AddAddress component (for setting map position) */
/*if not given permission loc variable is set to the center lat and lng of current chosen country (props.country) */
// setAddAddress(true) displays screen for adding address (ProfileAddAddress component found in /pages)
function handleAdd(){

      console.log(location.loaded)
      let loc={}
      if(location.loaded && location.coordinates){
   
        loc={
            lat:location.coordinates.lat,
            lng:location.coordinates.lng
    
          }
        }
    else{
    console.log("else")
   
    if(props.country==0){
      loc={
      lat:24.774265,
      lng:	46.738586
    
    }
    
    }
    else{
      loc={
      lat:	26.129999,
      lng:	50.555000
    
    }
    
    }
    
    
    
    }
  
    setLoc(loc)
 

  setAddAddress(true)

}


    return ( 
     <>


<div> 
      {modal?
      <Logout setModal={setModal} setCartItem={props.setCartItem}/>
      :null
     }
        {delModal?
      <DelAddress handleDelete={handleDelete} setModal={setDelModal}/>
      :null
     }
      <div className="userProfile">
      <UserNav setModal={setModal} nav={1} /> 
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <>
      {console.log(addAddress)}
      {!addAddress?
<div className="aboutusBody profileBody container-fluid fluid">

    <div className="upAddress"> 
    <p  className="upTable-title"> {t("profile.address")}</p>
    <button onClick={handleAdd}   className="upTable-button"><IoAddCircleOutline/> {t("profile.add_address")}</button>


    
    </div> 
    <div >
  <div className="row g-2"> 
  
      {address && address.map((data,index)=>

  
<AddressCard setDelId={setDelId} setDelModal={setDelModal} handleDelete={handleDelete} key={index} address={i18n.language=="ar"? data.arAddress:data.enAddress } id={data.id} title={data.title} 
isCreatorByUser ={data.isCreatorByUser}
latitude ={data.latitude}
longitude ={data.longitude}
apartmentNo={data.apartmentNo}
buildingNo={data.buildingNo}
streetNo={data.streetNo}
compoundNo={data.compoundNo}
/>

   
     )
} 
 
  </div>
</div>
   
</div>:
<>

<ProfileAddAddress setCartItem={props.setCartItem}  setAddAddress={setAddAddress} addAddress={addAddress} countryCode={props.country==0?"SA":"BA"} loc={loc} country={props.country}/>


</>
}
</>
}

</div>
   <Footer/>
         </div>
</>
    );
  }
  
  export default MyAddresses;