
import "../Styles/UserProfile.css";

import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';
import AddressCard from "../Components/AddressCard";
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect,useState } from "react";
import ProfileAddAddress from "./ProfileAddAddress";
import { saved_address,delete_address } from '../api'
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import DelAddress from "../Components/DelAddress";
import useGeoLocation from "../Components/useGeoLocation";
import { useNavigate } from "react-router-dom";
import TaxCard from "../Components/TaxCard";
import ProfileAddInvoice from "./ProfileAddInvoice";
import { getOrganizations } from "../api";
function TaxInvoice(props) {
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(true);
  const [ addInvoice, setAddInvoice]= useState(false);
  const [invoice,setInvoice]=useState([]);
  const [modal,setModal]=useState(false)
  const [delModal,setDelModal]=useState(false)
  const [activeTax,setActiveTax]=useState(  localStorage.getItem("organizationId")?localStorage.getItem("organizationId"):null)
  const [defaultTax,setDefaultTax]=useState(  localStorage.getItem("defaultOrganizationId")?localStorage.getItem("defaultOrganizationId"):null)

  

//fetch user addresses
  useEffect(() => {
    const token= localStorage.getItem("token");
    setLoading(true);

    getOrganizations(token).then(response => {
     
     const data=response.data.data;
     setInvoice(data);
setLoading(false);

  })
  
   
  
  
   },[addInvoice]);




    return ( 
     <>


<div> 
      {modal?
      <Logout setModal={setModal} setCartItem={props.setCartItem}/>
      :null
     }
     
      <div className="userProfile">
      <UserNav setModal={setModal} nav={7} /> 
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <>
      {!addInvoice?
<div className="aboutusBody profileBody container-fluid fluid">

    <div className="upAddress"> 
    <p  className="upTable-title"> {t("profile.organization")}</p>
    <button onClick={()=>setAddInvoice(true)}   className="upTable-button"><IoAddCircleOutline/> {t("profile.addTax")}</button>


    
    </div> 
    <div >
  <div className="row g-2"> 
  
      {invoice && invoice.map((data,index)=>

  
<TaxCard defaultTax={defaultTax} setDefaultTax={setDefaultTax} key={index} activeTax={activeTax}  setActiveTax={setActiveTax} setDelModal={setDelModal} data={data}

/>

   
     )
} 
 
  </div>
</div>
   
</div>:
<>

<ProfileAddInvoice setAddInvoice={setAddInvoice} country={props.country}  addInvoice={addInvoice}  />


</>
}
</>
}

</div>
   <Footer/>
         </div>
</>
    );
  }
  
  export default TaxInvoice;